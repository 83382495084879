
export default function formatMoney(value) {
    if(value){
        // value = value.replace('R$ ', '').replace(',', '.')
    
        value = Number(value)
    
        const result = value?.toFixed(2) // casas decimais
        ?.replace('.', ',')
        ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        return result == "NaN" ? '' : result
    }

    return "0,00"
}


