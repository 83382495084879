import React, { useCallback, useReducer, useState, useEffect } from 'react';
import { Button, Col, DatePicker, FlexboxGrid, Icon, IconButton, Input, InputGroup, InputNumber, Loader, Message, Modal, Notification, Radio, RadioGroup, Row, SelectPicker, Tag, TagPicker, Toggle, Pagination, Avatar, Dropdown } from "rsuite"
import { useParams, useHistory, Link } from "react-router-dom";
import Card from '../../components/Card.component';
import MembersAreaService from '../../services/MembersArea.service';
import LoadingTable from '../../components/LoadingTable.component';
import GenericDrawer from "../../components/GenericDrawer.component";
import parseDateTime from '../../utils/parseDateTime'; 
import Comment from './components/Comment.component';
import MyComment from './components/MyComment.component';
import AuthService from '../../services/Authentication.service';

const InfoSchoolPage = () => {

    let { id } = useParams()
    const history = useHistory()
    const [tab, setTab] = useState(1);
    const [coursePaginatedList, setCoursePaginatedList] = useState([]);
    const [activePage, setActivePage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [school, setSchool] = useState(null)
    const [userId, setUserId] = useState(null)
    
    const defaultDrawerState = {
        filter: {open: false, data: {}},
    }
    const [drawerState, setDrawerState] = useState(defaultDrawerState)

    const getSchool = async () => {
        const response = await new MembersAreaService().get(`school/${id}`)
        setSchool(response)
    }

    const getCoursesPaginatedList = async (page = 1, filters = {}) => {
        if (school?.courses?.length) {
            const courseIds = school.courses.map(course => course._id); 
            const query = { _id: { $in: courseIds } };
            filters = query
        }
        setActivePage(page)
        const response = await new MembersAreaService().post(filters,`list/course/${page}`)
        setCoursePaginatedList(response)
    }

    const openDrawer = (drawerName, data = {}) => setDrawerState({...drawerState, [drawerName]: {open: true, data: {...drawerState[drawerName].data, ...data}}});

    const closeDrawer = (drawerName) => setDrawerState({...drawerState, [drawerName]: {open: false, data: {...drawerState[drawerName]?.data}}});

    const navigateToPage = (number) => getCoursesPaginatedList(number, {})

    const handleTabClick = useCallback((tabNumber) => {
        setTab(tabNumber)
    }, []);

    const getTabItemsMobile = () => {
        let tabItems = [
            {label: 'Configurações', value: 1}
        ]
        return tabItems
    }

    const loadAllLists = async () => {
        await getSchool();
    };

    const fetchCourses = async () => {
        if (school) { 
            await getCoursesPaginatedList();
            setLoading(false);     
        }
    };

    const [modalComment, setModalComment] = useState(false);
    const [comments, setComments] = useState([]);
    const [courseComments, setCourseComments] = useState()

    const openModalComment = async (course) => {
        setCourseComments(course)
        const response = await new MembersAreaService().get(`lesson/comments/simple/teacher/panel/${course?._id}`)
        setComments(response)
        setModalComment(true)
    }

    const closeModalComment = () => {
        setModalComment(false)
        setCommentFormState("")
        setReplyComment(null)
    }
    
    const getUserId = async () => {
        const response = await new AuthService().getUserId()
        if(response){
            setUserId(response.user_id)
        }
    }
    
    useEffect(() => {
        loadAllLists();
        
    }, [id]);

    useEffect(() => {
        fetchCourses()
    }, [school])

    useEffect(() => {
        getUserId()
    }, [])

    const [commentFormState, setCommentFormState] = useState("")

    const isCommentFormValid = () => {
        return commentFormState.trim() !== '' && replyComment !== null;
    };

    const createNewLessonComment = async (comment) => {
        const response = await new MembersAreaService().post({ text: commentFormState, comment: comment},`lesson/comment`)
        if(response){
            const response = await new MembersAreaService().get(`lesson/comments/simple/teacher/panel/${courseComments?._id}`)
            setComments(response)
            setCommentFormState("")
            setReplyComment(null)
        }
    }

     const [replyComment, setReplyComment] = useState(null)

    return <>
        <Row>
            <Col lg={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Link to={'/schools/management'}><i className="fas fa-arrow-left" style={{ fontSize: '2em', color: '#575757' }}></i></Link>
                <h3 style={{marginLeft: '15px'}}>Informações da Escola</h3>
            </Col>
        </Row>

        <Row className="only-desk">
            <div style={{margin: '20px 5px', padding: '15px 5px', background: 'rgba(255, 255, 255, 0.6)', display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '100%'}}>
                    <Button appearance={tab === 1 ? 'primary' : 'subtle'} onClick={() => handleTabClick(1)}>Comentários</Button>                      
                </div>
            </div>
        </Row>

        <Row className="only-mobile" style={{marginTop: '25px'}}>
            <Col xs={24} sm={24}>
                <SelectPicker
                    searchable={false}
                    cleanable={false}
                    value={tab}
                    onChange={(value) => handleTabClick(value)}
                    data={getTabItemsMobile()} 
                style={{ width: '100%' }} />
            </Col>
        </Row>

        {
            tab === 1 ? <>
                <Row>
                    <Col md={12}>
                        <h3>Cursos</h3>
                    </Col>
                </Row>

                <Row className="table-container">
                    <Row className="table">
                        <Row style={{display: 'flex', flexDirection: 'row'}} className="list-responsive">
                            <Row className="rs-col-md-8 rs-col-xs-8">NOME</Row>
                            <Row className="rs-col-md-6 rs-col-xs-6"></Row>
                            <Row className="rs-col-md-6 rs-col-xs-6"></Row>
                            <Row className="rs-col-md-3 rs-col-xs-3">STATUS</Row>
                            <Row className="rs-col-md-1 rs-col-xs-1"></Row>
                        </Row>

                        {
                            !coursePaginatedList?.data?.length && loading ? <LoadingTable /> : null
                        }

                        {
                            !coursePaginatedList?.data?.length && !loading ? <>
                                <Row>
                                    <Col lg={24}>
                                        <Card>
                                            <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhum curso cadastrado</h5>
                                        </Card>
                                    </Col>
                                </Row>
                            </> : null
                        }   

                        {
                            coursePaginatedList?.data?.map((course, index) => {
                                return <Row 
                                            key={index} 
                                            className="card-table-row list-responsive"  
                                            onClick={() => openModalComment(course)}
                                            style={{cursor: 'pointer'}}
                                        >
                                        <Row className="rs-col-md-8 rs-col-xs-8">{course.name}</Row>
                                        <Row className="rs-col-md-6 rs-col-xs-6">
                                            {/* {product.paymentType === "subscription" ? "Assinatura" : "Venda Direta"} */}
                                        </Row>
                                        <Row className="rs-col-md-6 rs-col-xs-6">
                                            {/* {product?.category?.categoryName} */}
                                        </Row>
                                        <Row className="rs-col-md-3 rs-col-xs-3">{
                                            course.active ? <Button className="payment-status-approved"> <span>Ativo</span></Button> : <Button className="payment-status-denied"> <span>Inativo</span></Button>
                                        } </Row>
                                        <Row className="rs-col-md-1 rs-col-xs-1"> 
                                        </Row>
                                </Row>
                            })
                        }
                    </Row>
                </Row>


            <Row style={{marginTop: '25px', display: 'flex', justifyContent: 'flex-end'}}>
                <Pagination
                    size="lg"
                    prev={true}
                    next={true}
                    first={true}
                    last={true}
                    ellipsis={true}
                    boundaryLinks={true}
                    pages={coursePaginatedList.pages}
                    maxButtons={5}
                    activePage={activePage}
                    onSelect={navigateToPage}
                />
            </Row>

            <GenericDrawer
                modalState={drawerState.filter.open}
                closeModal={() => closeDrawer('filter')}
                title="Filtros"
            >
                <Row>
                    <Col lg={24} style={{textAlign: 'right'}}>
                        <Button appearance="link" onClick={() => {
                            }}>Limpar filtros <Icon icon="close" /></Button>
                    </Col>
                </Row>
                
                <Row style={{marginTop: '15px'}}>
                    <Col lg={24}>
                        <p>Produto</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <SelectPicker
                                style={{width: '100%'}}
                                searchable={true}
                                value={drawerState.filter.data.products}
                                cleanable={false}
                                preventOverflow={true}
                                appearance="default"
                                placeholder="Selecione o tipo do campo"
                            />
                        </div>
                    </Col>
                </Row>

                <Row style={{marginTop: '15px'}}>
                    <Col lg={24}>
                        <p>Categoria</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <TagPicker 
                                value={drawerState.filter.data.category}
                                preventOverflow={true}
                                labelKey="categoryName"
                                valueKey="_id"
                            style={{ width: '100%' }}  />
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: '15px'}}>
                    <Col lg={24}>
                        <p>Tipo de Produto</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <TagPicker 
                                value={drawerState.filter.data.paymentType}
                                preventOverflow={true}
                                data={[
                                    {
                                        label: "Venda Direta",
                                        value: 'single',
                                    },
                                    {
                                        label: "Assinatura",
                                        value: 'subscription',
                                    },
                                ]} 
                            style={{ width: '100%' }}  />
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: '15px'}}>
                    <Col lg={24}>
                        <p>Status</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <TagPicker 
                                value={drawerState.filter.data.status}
                                preventOverflow={true}
                                data={[
                                    {
                                        label: "Ativo",
                                        value: true,
                                    },
                                    {
                                        label: "Inativo",
                                        value: false,
                                    },
                                ]} 
                            style={{ width: '100%' }}  />
                        </div>
                    </Col>
                </Row>
            </GenericDrawer>
            </> : null
        }

        <Modal 
            size="md"
            show={modalComment}
            onHide={closeModalComment}
        >
            <Modal.Header>
                <Modal.Title>{`Comentários do curso: ${courseComments?.name}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'hidden' }}>
                {
                    Array.isArray(comments) && comments.length > 0 ? <>
                        <Card style={{background: "#d2d6dc30", maxHeight: '60vh', overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>                           
                            {
                                comments?.map((comment, index) => {
                                    console.log('userId',userId)
                                    return <>
                                        {
                                        userId === comment?.owner?._id ? <>
                                            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <MyComment
                                                    key={index}
                                                    comment={comment}
                                                    setReplyComment={setReplyComment}
                                                />
                                            </Row>                                         
                                        </> :
                                            <Row>
                                                <Comment
                                                    key={index}
                                                    comment={comment}
                                                    setReplyComment={setReplyComment}
                                                />                                           
                                            </Row>
                                        }
                                    </>
                                })
                            } 
                        </Card>
                    </> : 

                    <Row>
                        <Col lg={24}>
                            <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhum comentario</h5>
                        </Col>
                    </Row>
                      
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    replyComment ? <>
                    <Row style={{margin: "0px 5px 10px 5px", display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        <Col lg={22}>
                            <Row style={{background: "#d2d6dc30", textAlign: "left", padding: "10px 10px 10px 0", borderRadius: "10px"}}>                               
                                <Row lg={22} style={{marginLeft: "20px"}}>
                                    <Row style={{
                                        backgroundColor: replyComment?.userColor || "#000", 
                                        width: "10px",
                                        height: "100%",
                                        borderRadius: "10px 0 0 10px",
                                        position: "absolute",
                                        top: 0,
                                        left: "5px",
                                        marginRight: "10px"
                                    }} />
                                    <Row style={{ fontWeight: "bold", color: replyComment?.userColor, margin: "10px 10px 0 0" }}>
                                        {replyComment?.comment?.owner?.name}
                                    </Row>
                                    <Row style={{ fontWeight: "500", color: "#34495e", margin: "0 10px 0 0" }}>
                                        <p>Aula: {replyComment?.comment?.module_children?.lesson?.name}</p>
                                    </Row>
                                    <Row style={{margin: "5px 10px 10px 0"}}>
                                        {replyComment?.comment?.text}
                                    </Row>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={2}>
                            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                                <Icon 
                                    icon="close" 
                                    size="2x" 
                                    style={{ marginRight: '0' }} 
                                    onClick={() => {setReplyComment(null)}}
                                />
                            </Row>
                        </Col>
                    </Row>
                    </> : null
                }
                <Row style={{margin: "0px 5px 10px 5px", display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <Col lg={22}>
                        <Row style={{}}>
                            <Input onChange={(value) => setCommentFormState(value)} value={commentFormState} componentClass="textarea" rows={1} placeholder="Digite uma mensagem" />
                        </Row>
                    </Col> 
                    <Col lg={2}>
                        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                     
                            <Button 
                                appearance="primary"
                                disabled={!isCommentFormValid()}
                                onClick={() => createNewLessonComment(replyComment?.comment?._id)} 
                                >
                                <Icon icon="page-next" style={{ marginRight: '0' }} />
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Button
                    onClick={closeModalComment}
                    appearance="subtle">
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default InfoSchoolPage;