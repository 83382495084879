import BaseService from './base/base.service'

export default class TeamMemberService extends BaseService {

    constructor(){
        super('team')
    }

    evaluateRole = (role) => {
        try{
            const impersonated = this.getImpersonated()
            //console.log("Loaded impersonated", impersonated)
            if(!impersonated?._id){
                //console.log("No impersonated")
                return true //not trying to impersonate
            }
            const splitedRole = role.split('.')
            const roles = impersonated?.roles

            const role_name = splitedRole[0]
            const role_action = splitedRole[1]

            const hasRole = roles?.find(role => role.name === role_name)?.id ? true : false
            //console.log(role, "hasRole")

            if(!hasRole){
                return false
            }

            if(role_action === 'all'){
                return true
            }

            const hasAction = roles.find(role => role.name === role_name)[role_action] ? true : false
            console.log(role, "hasAction", hasAction)

            if(!hasAction){
                return false
            }

            return true
        }catch(e){
            console.log(e)
            return false
        }
    }
}