const contract = `<html>
<head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type">
  <style type="text/css">

    .c2 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 11pt
    }

    .c13 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 18pt
    }

    .c5 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c20 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 11pt
    }

    .c12 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 11pt
    }

    .c11 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c18 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c21 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c26 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c27 {
      padding-top: 0pt;
      padding-bottom: 12pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c0 {
      font-size: 12pt;
      font-family: "Times New Roman";
      font-style: italic;
      font-weight: 400
    }

    .c19 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      text-align: left
    }

    .c4 {
      color: #000000;
      text-decoration: none;
      vertical-align: baseline;
      font-style: normal
    }

    .c3 {
      font-size: 12pt;
      font-family: "Times New Roman";
      font-weight: 400
    }

    .c9 {
      color: #000000;
      vertical-align: baseline;
      font-style: normal
    }

    .c8 {
      -webkit-text-decoration-skip: none;
      text-decoration: underline;
      text-decoration-skip-ink: none
    }

    .c1 {
      font-size: 12pt;
      font-family: "Times New Roman";
      font-weight: 700
    }

    .c14 {
      color: inherit;
      text-decoration: inherit
    }

    .c23 {
      max-width: 425.2pt;
      padding: 70.8pt 85pt 70.8pt 85pt
    }

    .c10 {
      font-weight: 400;
      font-family: "Arial"
    }

    .c7 {
      border: 1px solid black;
      margin: 5px
    }

    .c24 {
      font-weight: 700;
      font-family: "Arial"
    }

    .c6 {
    }

    .c25 {
    }

    .c16 {
      height: 18pt
    }

    .c22 {
    }

    .c15 {
      font-size: 11pt
    }

    .c17 {
    }

    .titulo-contrato {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 36pt;'
      padding-bottom: 6pt;
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .subtitulo-contrato {
      padding-top: 18pt;
      color: #666666;
      font-size: 24pt;
      padding-bottom: 4pt;
      font-family: "Georgia";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
    }

   
  </style>
</head>    
<body class="c14 doc-content">     
  <p class="c11">    
    <span class="c1 c2">TERMO DE USO DA ECOMPAY</span>     
  </p>     
  <p class="c4">     
    <span class="c0 c2">Vers&atilde;o atualizada em 12 de agosto de 2024</span>    
  </p>     
  <p class="c4">     
    <span class="c0">Obrigado por utilizar os </span>    
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>    
    <span class="c0 c2">&nbsp;!</span>     
  </p>     
  <p class="c4">
    <span class="c0">A </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp;oferece uma plataforma de meio de pagamento, doravante denominada <span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">, a ser utilizada por quem deseja vender seus </span>
    <span class="c1">PRODUTOS OU</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">SERVI&Ccedil;OS, </span>
    <span class="c0">de forma simples, pr&aacute;tica e de r&aacute;pida configura&ccedil;&atilde;o, permitindo que nossos </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0">&nbsp;aprimorem seus processos e atinjam o que a </span>
    <span class="c1">ECOM</span>
    <span class="c0 c2">&nbsp;deseja: vender sem parar.</span>
  </p>
  <p class="c4"><span class="c0">A </span>
    <span class="c1">PLATAFORMA</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">ECOMPAY</span>
    <span class="c0">&nbsp;oferece acesso a diversas funcionalidades que permitem aos </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0">&nbsp;transacionarem entre si. Ressaltamos que a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp;n&atilde;o &eacute; e n&atilde;o se torna parte, interveniente ou garantidora de qualquer rela&ccedil;&atilde;o entre os </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0">, n&atilde;o atuando como vendedor, intermediador, agente ou distribuidor dos </span>
    <span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
    <span class="c0">&nbsp;transacionados na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">.</span>
  </p>
  <p class="c4">
    <span class="c0">Este </span><span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp;&eacute; celebrado entre a E-COM COMUNICA&Ccedil;&Otilde;ES LTDA, sociedade simples de responsabilidade limitada, inscrita no CNPJ/MF sob n&ordm; 07.722.534/0001-70, com sede na Rua Senador Vergueiro, n&ordm; 70, apartamento 1001, bairro Flamengo, Rio de Janeiro/RJ, CEP 22.230-001, doravante denominada </span>
    <span class="c1">ECOM</span>
    <span class="c0">; e o </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">, pessoa f&iacute;sica ou jur&iacute;dica, identificada na ficha cadastral preenchida eletronicamente na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">.</span>
  </p>
  <p class="c4">
    <span class="c1">A PLATAFORMA</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">ECOMPAY </span>
    <span class="c0">e os </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM </span>
    <span class="c0">ser&atilde;o regidos por este </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">, em conjunto com as </span>
    <span class="c1">POL&Iacute;TICAS DA ECOM, </span>
    <span class="c0">incluindo, mas n&atilde;o se limitando a: todos os recursos, aplicativos, servi&ccedil;os, tecnologias e softwares disponibilizados pela </span>
    <span class="c1">ECOM</span>
    <span class="c0">, exceto quando esclarecemos expressamente que outros termos (e n&atilde;o estes) se aplicam. As </span>
    <span class="c1">POL&Iacute;TICAS DA ECOM</span>
    <span class="c0">&nbsp;s&atilde;o parte integrante deste </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0 c2">.</span>
  </p>
  <p class="c4">
    <span class="c0">Este </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp; constitui um contrato vinculante entre voc&ecirc;, como </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp;, e a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp;, e rege m &nbsp;seu acesso e uso: (a) do </span>
    <span class="c0 c7">site</span>
    <span class="c0">&nbsp; </span>
    <span class="c0 c5">
    <a class="c8" href="https://www.google.com/url?q=https://dashboard.ecompay.app/%23/login&amp;sa=D&amp;source=editors&amp;ust=1724090705998181&amp;usg=AOvVaw3mNs9U86fqbUMsrex6BMSj">https://dashboard.ecompay.app/#/login</a>
    </span>
    <span class="c0">&nbsp;seus subdom&iacute;nios; (b) de quaisquer outros </span>
    <span class="c0 c7">sites</span>
    <span class="c0">&nbsp;, interface, APIs, aplicativos ou outros meios nos quais a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; disponibilize seus recursos, inclusive de nossos aplicativos de celular, </span>
    <span class="c0 c7">tablet</span>
    <span class="c0">&nbsp; ou de outros dispositivos eletr&ocirc;nicos; e (c) de todos os </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp;. Portanto, ao acessar ou usar a </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">, voc&ecirc; concorda em se vincular ao </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">, </span><span class="c1">&nbsp;POL&Iacute;TICAS DA ECOM </span>
    <span class="c0">e com todas as informa&ccedil;&otilde;es e regras dispon&iacute;veis no &nbsp; site &nbsp;</span>
    <span class="c0 c5">
    <a class="c8" href="https://www.google.com/url?q=https://dashboard.ecompay.app/%23/login&amp;sa=D&amp;source=editors&amp;ust=1724090705999310&amp;usg=AOvVaw3SZw5bR3ibjeyNEJ1lQDlG">https://dashboard.ecompay.app/#/login</a>
    </span>
    <span class="c0 c2">&nbsp;e em cumprir todas as suas regras.</span>
  </p>
  <p class="c4">
    <span class="c0">Por essa raz&atilde;o, voc&ecirc; deve ler atentamente e de forma cuidadosa o </span>
    <span class="c1">TERMO DE USO E POL&Iacute;TICAS DA ECOM</span>
    <span class="c0">&nbsp;antes de iniciar o uso dos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp;pois eles cont&ecirc;m informa&ccedil;&otilde;es importantes sobre seus direitos e obriga&ccedil;&otilde;es relativos ao acesso ou uso dos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span><span class="c0 c2">.</span>
  </p>
  <p class="c4">
    <span class="c0">A celebra&ccedil;&atilde;o </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp;&eacute; comprovada pela concord&acirc;ncia do Usu&aacute;rio por via eletr&ocirc;nica e pela confirma&ccedil;&atilde;o de leitura no ato da contrata&ccedil;&atilde;o ou pelo uso da </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">.</span>
  </p>
  <p class="c4">
    <span class="c1 c5 c9">Ao utilizar a PLATAFORMA ECOMPAY, o USU&Aacute;RIO declara ter lido integralmente este TERMO DE USO E POL&Iacute;TICAS DA ECOM, anuindo com todas as suas cl&aacute;usulas, al&eacute;m de declarar ter preenchido corretamente a ficha cadastral com suas informa&ccedil;&otilde;es.</span>
  </p>
  <p class="c4">
    <span class="c0">A </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp;cobra do </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">, </span>
    <span class="c1">COPRODUTOR</span>
    <span class="c0">&nbsp;ou </span>
    <span class="c1">AFILIADO</span>
    <span class="c0">&nbsp;</span>
    <span class="c1">TAXAS</span>
    <span class="c0">&nbsp;pelo licenciamento do </span>
    <span class="c0 c7">software </span>
    <span class="c0">que constitui a </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">, aplicadas ao valor de transa&ccedil;&atilde;o conforme </span>
    <span class="c1">TAXAS</span>
    <span class="c0 c2">&nbsp;definidas e/ou pela disponibiliza&ccedil;&atilde;o e media&ccedil;&atilde;o entre usu&aacute;rios em virtude de funcionalidades financeiras, publicit&aacute;rias e/ou de armazenamento, conforme definidos na se&ccedil;&atilde;o de TAXAS na POL&Iacute;TICA DA ECOM.</span>
  </p>
  <p class="c4">
    <span class="c0">As novas ferramentas ou os novos recursos adicionados aos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp;atuais tamb&eacute;m estar&atilde;o sujeitos a este </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">. Voc&ecirc; pode consultar a vers&atilde;o atualizada do </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp;a qualquer momento em: &nbsp;</span>
    <span class="c0 c5">
    <a class="c8" href="https://www.google.com/url?q=https://dashboard.ecompay.app/%23/login&amp;sa=D&amp;source=editors&amp;ust=1724090706001239&amp;usg=AOvVaw0mdWadAd-eoo5rwRFl_F5I">https://dashboard.ecompay.app/#/login</a>
    </span>
    <span class="c0 c2">&nbsp;verifique periodicamente se h&aacute; atualiza&ccedil;&otilde;es.</span>
  </p>
  <p class="c4">
    <span class="c0">A </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp;poder&aacute;, por conveni&ecirc;ncia e a seu exclusivo crit&eacute;rio, fornecer informa&ccedil;&otilde;es e/ou orienta&ccedil;&otilde;es por meios eletr&ocirc;nicos (como por exemplo: </span>
    <span class="c0 c7">e</span>
    <span class="c0">&nbsp;</span>
    <span class="c0 c7">mail</span>
    <span class="c0">&nbsp;e WhatsApp) sobre este </span>
    <span class="c1">TERMO DE USO </span>
    <span class="c0">e/ou </span>
    <span class="c1">POL&Iacute;TICAS DA ECOM</span>
    <span class="c0 c2">. Tais informa&ccedil;&otilde;es e/ou orienta&ccedil;&otilde;es n&atilde;o s&atilde;o juridicamente vinculantes.</span>
  </p>
  <p class="c4">
    <span class="c0">Por essa raz&atilde;o, recomendamos que sempre que receba uma informa&ccedil;&atilde;o e/ou orienta&ccedil;&atilde;o sobre o </span>
    <span class="c1">TERMO DE USO </span>
    <span class="c0">e/ou </span>
    <span class="c1">POL&Iacute;TICAS DA ECOM, </span>
    <span class="c0">consulte suas vers&otilde;es atualizadas em nosso site para compreender seus direitos e obriga&ccedil;&otilde;es e interrompa imediatamente o uso dos SERVI&Ccedil;OS DA ECOM, caso n&atilde;o concorde com qualquer termo do 
    </span>
    <span class="c1">TERMO DE USO </span>
    <span class="c0">e/ou</span>
    <span class="c1">&nbsp;POL&Iacute;TICAS DA ECOM</span>
    <span class="c0 c2">.</span>
  </p>
  <p class="c12">
    <span class="c0">Qualquer aviso, requerimento, comunica&ccedil;&atilde;o ou interpela&ccedil;&atilde;o relacionada a estes termos dever&aacute; ser efetuada por escrito, por e-mail para: </span>
    <span class="c1 c2">suporte@ecompay.app</span>
  </p>
  <p class="c4">
    <span class="c0 c2">&nbsp;</span>
  </p>
  <h2 class="c10" id="h.gjdgxs">
    <span class="c1 c2">1. DEFINI&Ccedil;&Otilde;ES</span>
  </h2>
  <p class="c11">
    <span class="c1">1.1. </span>
    <span class="c0">Os seguintes termos, palavras, express&otilde;es e abrevia&ccedil;&otilde;es grafados com letras mai&uacute;sculas e negrito (ou n&atilde;o) e n&atilde;o expressamente definidos em outras partes deste </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0 c2">, independentemente de serem utilizados no singular ou no plural, ter&atilde;o o significado a eles atribu&iacute;do nesta cl&aacute;usula:</span>
  </p>
  <p class="c4">
    <span class="c1">AFILIADO: USU&Aacute;RIO </span>
    <span class="c0">cadastrado na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;como </span>
    <span class="c1">AFILIADO</span>
    <span class="c0">, que, utilizando os </span>
    <span class="c1">SERVI&Ccedil;OS </span>
    <span class="c0">da </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;, comercializa </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; &nbsp;mediante remunera&ccedil;&atilde;o resultante da multiplica&ccedil;&atilde;o do percentual do valor de cada venda que promove, indicado pelo </span>
    <span class="c1">VENDEDOR, </span>
    <span class="c0">pelo valor do </span>
    <span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O.</span>
    <span class="c0">&nbsp; Tal valor ser&aacute; depositado na conta banc&aacute;ria indicada pelo </span>
    <span class="c1">AFILIADO, </span>
    <span class="c0">mediante solicita&ccedil;&atilde;o de saque no painel financeiro da plataforma. A cada solicita&ccedil;&atilde;o de saque ser&aacute; descontada uma taxa prevista na </span>
    <span class="c1 c2">POL&Iacute;TICA DE PAGAMENTO DA ECOM;</span>
  </p>
  <p class="c4">
    <span class="c1">COLABORADOR:</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp;cadastrado por um </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp; para fazer a gest&atilde;o da conta do </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp;, sem que para isso seja necess&aacute;rio o compartilhamento das credenciais de acesso do </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp;. O </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp; assume todas as responsabilidades pelas a&ccedil;&otilde;es dos seu </span>
    <span class="c1">COLABORADOR</span>
    <span class="c0">&nbsp; na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;, isentando a </span>
    <span class="c1">ECOM</span>
    <span class="c0 c2">&nbsp; de qualquer tipo de demanda, inclusive de terceiros;</span>
  </p>
  <p class="c4">
    <span class="c1">COMPRADOR:</span>
    <span class="c0">&nbsp; qualquer </span>
    <span class="c1">USU&Aacute;RIO, </span>
    <span class="c0">sendo pessoa f&iacute;sica ou jur&iacute;dica, que compra qualquer </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; por meio da </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">CONTA ECOM: </span>
    <span class="c0">conta criada na </span>
    <span class="c1">&nbsp;PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp; por qualquer usu&aacute;rio, seja na qualidade de </span>
    <span class="c1">&nbsp;VENDEDOR, COLABORADOR, COMPRADOR, COPRODUTOR ou AFILIADO</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">CONTRATO: </span>
    <span class="c0">Trata-se do presente contrato vinculante, aqui denominado </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp;, firmado entre o </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp;, e a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp;, que rege seu acesso e uso &agrave; </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp; e aos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">&nbsp;</span>
    <span class="c0">&nbsp;incluindo as </span>
    <span class="c1">&nbsp;POL&Iacute;TICAS DA ECOM</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">COPRODUTOR:</span>
    <span class="c0">&nbsp; qualquer </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">&nbsp;</span>
    <span class="c0">&nbsp;sendo pessoa f&iacute;sica ou jur&iacute;dica respons&aacute;vel pela coprodu&ccedil;&atilde;o do </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp;, que &eacute; apontado pelo </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp; na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp; como sendo coprodutor do </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O </span>
    <span class="c0">e que receb e remunera&ccedil;&atilde;o resultante da multiplica&ccedil;&atilde;o do percentual do valor de cada venda que promove, indicado pelo </span>
    <span class="c1">VENDEDOR, </span>
    <span class="c0">pelo valor do </span>
    <span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O. </span>
    <span class="c0">Tal valor ser&aacute; depositado na conta banc&aacute;ria indicada pelo </span>
    <span class="c1">COPRODUTOR, </span>
    <span class="c0">mediante solicita&ccedil;&atilde;o de saque no painel financeiro da plataforma. A cada solicita&ccedil;&atilde;o de saque ser&aacute; descontada uma taxa prevista na </span>
    <span class="c1 c2">POL&Iacute;TICA DE PAGAMENTO DA ECOM;</span>
  </p>
  <p class="c4">
    <span class="c1">DADOS CADASTRAIS: </span>
    <span class="c0">S&atilde;o dados como por exemplo, mas n&atilde;o se limitando &agrave; filia&ccedil;&atilde;o, o endere&ccedil;o (inclusive o endere&ccedil;o eletr&ocirc;nico), telefone, CNPJ, raz&atilde;o social,e a qualifica&ccedil;&atilde;o pessoal, entendida como nome, prenome, estado civil, documentos de identifica&ccedil;&atilde;o do </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0 c2">&nbsp;;</span>
  /p>
  <p class="c4">
    <span class="c1">ECOMPAY:</span>
    <span class="c0">&nbsp; Sistema de pagamentos da </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; que processa as instru&ccedil;&otilde;es de pagamento realizadas pelos </span>
    <span class="c1">COMPRADORES</span>
    <span class="c0">&nbsp; e as l&iacute;quida para os </span>
    <span class="c1">VENDEDORES, COPRODUTORES </span>
    <span class="c0">e/ou </span>
    <span class="c1">AFILIADOS</span>
    <span class="c0">&nbsp;, conforme regras e procedimentos determinados na </span>
    <span class="c1">POL&Iacute;TICA DE PAGAMENTO </span>
    <span class="c0">e nos </span>
    <span class="c1">TERMOS DE USO da ECOM</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">MATERIAL</span>
    <span class="c0">&nbsp;: qualquer conte&uacute;do percept&iacute;vel aos sentidos em diversos formatos digitais (v&iacute;deo, &aacute;udio, texto, </span>
    <span class="c0 c7">software</span>
    <span class="c0">&nbsp;, servi&ccedil;o e eventos), incluindo </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp;, disponibilizado pelo </span>
    <span class="c1">VENDEDOR </span>
    <span class="c0">para divulga&ccedil;&atilde;o, promo&ccedil;&atilde;o e comercializa&ccedil;&atilde;o mediante o uso das ferramentas tecnol&oacute;gicas contidas na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">P&Aacute;GINA DE VENDA: </span>
    <span class="c0">&aacute;rea da </span>
    <span class="c1">PLATAFORMA</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">ECOMPAY </span>
    <span class="c0">por meio da qual os </span>
    <span class="c1">VENDEDORES</span>
    <span class="c0">&nbsp; disponibilizam seus </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
    <span class="c0">&nbsp; para serem visualizados pelos </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0">&nbsp; e a partir da qual pode-se iniciar o processo de compra e venda do </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">PLATAFORMA ECOMPAY OU PLATAFORMA: site</span>
    <span class="c0">&nbsp;</span>
    <span class="c0 c5">
      <a class="c8" href="https://www.google.com/url?q=https://dashboard.ecompay.app/%23/login&amp;sa=D&amp;source=editors&amp;ust=1724090706007773&amp;usg=AOvVaw0QDm6Wfu_1PNYOhh2bviLS">https://dashboard.ecompay.app/#/login</a>
    </span>
    <span class="c0">&nbsp;, de propriedade da </span>
    <span class="c1">ECOM </span>
    <span class="c0">&nbsp; e seus subdom&iacute;nios, como quaisquer outros sites, interfaces, aplicativos e outros meios nos quais s&atilde;o disponibilizados os </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM, </span>
    <span class="c0">dentre eles a </span>
    <span class="c1">&nbsp;</span>
    <span class="c0">&nbsp; &nbsp;reuni&atilde;o de </span>
    <span class="c1">COMPRADORES</span>
    <span class="c0">&nbsp; de </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
    <span class="c0">&nbsp; e os </span>
    <span class="c1">VENDEDORES</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">COPRODUTORES</span>
    <span class="c0">&nbsp; e </span>
    <span class="c1">AFILIADOS</span>
    <span class="c0">&nbsp;, com o fornecimento de utilidades diversas (financeira, gest&atilde;o, publicit&aacute;ria e, entre outras), disponibilizando todas as condi&ccedil;&otilde;es para propiciar as transa&ccedil;&otilde;es de compra e venda entre os </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">POL&Iacute;TICAS DA ECOM</span>
    <span class="c0">&nbsp;</span>
    <span class="c1">:</span>
    <span class="c0">&nbsp; Coletivamente, todas as Pol&iacute;ticas contidas no Contrato intitulado POL&Iacute;TICAS DA ECOM , incluindo, mas n&atilde;o se limitando a </span>
    <span class="c1">POL&Iacute;TICA DE PAGAMENTOS DA ECOM</span>
    <span class="c0">&nbsp;, A </span>
    <span class="c1">&nbsp;POL&Iacute;TICA DE REMUNERA&Ccedil;&Atilde;O</span>
    <span class="c0">&nbsp;, a </span>
    <span class="c1">&nbsp;POL&Iacute;TICA DE USO RESPONS&Aacute;VEL DA ECOM</span>
    <span class="c0">&nbsp;, a </span>
    <span class="c1">POL&Iacute;TICA DE PRIVACIDADE E A POL&Iacute;TICA DE COOKIES, </span>
    <span class="c0">entre outras e todas partes integrantes deste </span>
    <span class="c1">&nbsp;TERMO DE USO</span>
    <span class="c0 c2">&nbsp;.</span>
  </p>
  <p class="c4">
    <span class="c1">PRODUTO OU SERVI&Ccedil;O:</span>
    <span class="c0">&nbsp; objeto da transa&ccedil;&atilde;o de compra e venda entre </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp; e </span>
    <span class="c1">COMPRADOR</span>
    <span class="c0">&nbsp;, realizada por meio da </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">PROGRAMA DE AFILIADOS</span>
    <span class="c0">&nbsp;</span>
    <span class="c1">: SERVI&Ccedil;O DA</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; em que o </span>
    <span class="c1">VENDEDOR </span>
    <span class="c0">permite que um </span>
    <span class="c1">AFILIADO</span>
    <span class="c0">&nbsp; comercialize um </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; mediante remunera&ccedil;&atilde;o equivalente a um percentual do valor de cada venda que o </span>
    <span class="c1">AFILIADO </span>
    <span class="c0 c2">promover;</span>
  </p>
  <p class="c4">
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp;</span>
    <span class="c1">:</span>
    <span class="c0">&nbsp; Coletivamente, os recursos disponibilizados pela </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; que permitem que o </span>
    <span class="c1">USU&Aacute;RIO, </span>
    <span class="c0">se cadastre, consuma, crie, divulgue ou comercialize </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;OS </span>
    <span class="c0">na </span>
    <span class="c1">&nbsp;PLATAFORMA ECOMPAY, </span>
    <span class="c0">incluindo o sistema de pagamentos </span>
    <span class="c1">ECOMPAY</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">SERVI&Ccedil;OS DE TERCEIROS: </span>
    <span class="c0 c7">sites</span>
    <span class="c0">, aplicativos ou recursos de terceiros disponibilizados para os </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0">&nbsp; por meio da </span>
    <span class="c1">PLATAFORMA</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">ECOMPAY</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">TAXA DE USO: </span>
    <span class="c0">taxa que a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; cobra do </span>
    <span class="c1">VENDEDOR </span>
    <span class="c0">pelo uso do </span>
    <span class="c1">ECOMPAY</span>
    <span class="c0">&nbsp;, cujo valor varia conforme o pre&ccedil;o de venda do </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O, </span>
    <span class="c0">definida na </span>
    <span class="c1">POL&Iacute;TICA DE PAGAMENTO DA ECOM</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">TAXA DE LICEN&Ccedil;A: </span>
    <span class="c0">taxa que a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; cobra do </span>
    <span class="c1">VENDEDOR </span>
    <span class="c0">pelo uso do </span>
    <span class="c1">ECOMPAY</span>
    <span class="c0">&nbsp;, cujo valor &eacute; fixo, aplic&aacute;vel de acordo com a moeda de compra utilizada pelo </span>
    <span class="c1">COMPRADOR</span>
    <span class="c0">&nbsp; na transa&ccedil;&atilde;o, definida na </span>
    <span class="c1">POL&Iacute;TICA DE PAGAMENTO DA ECOM</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">TERCEIROS RELACIONADOS</span>
    <span class="c0">&nbsp;</span>
    <span class="c1">: </span>
    <span class="c0">quaisquer pessoas, naturais ou jur&iacute;dicas, que estejam direta ou indiretamente relacionadas a um </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; ou, detentores de direitos ao </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; ou n&atilde;o, como por exemplo, mas n&atilde;o se limitando a </span>
    <span class="c1">COPRODUTORES</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">COLABORADORES</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">COMPRADORES</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">AFILIADOS</span>
    <span class="c0">&nbsp; especialistas, autores, atores, </span>
    <span class="c0 c7">influencers</span>
    <span class="c0 c2">&nbsp;, personagens ou garotos-propaganda;</span>
  </p>
  <p class="c4">
    <span class="c1">TERMO DE USO:</span>
    <span class="c0">&nbsp; refere-se ao presente contrato que disp&otilde;e de todos os termos vinculantes que regulam a presta&ccedil;&atilde;o dos </span>
    <span class="c1">SERVI&Ccedil;OS DA</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; aos seus </span>
    <span class="c1">USU&Aacute;RIOS, </span>
    <span class="c0">assim comos os deveres e obriga&ccedil;&otilde;es dos </span>
    <span class="c1">&nbsp;USU&Aacute;RIOS, </span>
    <span class="c0">sendo as </span>
    <span class="c1">&nbsp;POL&Iacute;TICAS DA ECOM </span>
    <span class="c0">parte integrante do </span>
    <span class="c1">&nbsp;TERMO DE USO</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp;: qualquer pessoa, f&iacute;sica ou jur&iacute;dica, que se cadastre na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;, ou a utilize, independentemente do fato de ter feito ou n&atilde;o o seu cadastro como </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">AFILIADO</span>
    <span class="c0">&nbsp;, </span>
    <span class="c1">COPRODUTOR, COMPRADOR, COLABORADOR</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c1">VENDEDOR:</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp; que cadastra um </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; em seu nome e &eacute; titular exclusivo de todos os direitos de propriedade intelectual relativos ao </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp;, ou que est&aacute; regularmente autorizado pelos titulares desses direitos a criar , divulgar e comercializar o </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;, respondendo assim, &uacute;nica e exclusivamente pelas responsabilidades de natureza civil, criminal, tribut&aacute;ria, fiscal ou de qualquer outra natureza perante a qualquer demanda de terceiros relativa ao </span>
    <span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
    <span class="c0">&nbsp; transacionado na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;, isentando a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; de qualquer responsabilidade perante a terceiros, de qualquer natureza. A remunera&ccedil;&atilde;o do </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp; ser&aacute; resultante da multiplica&ccedil;&atilde;o do percentual do valor de cada venda que promove, indicado pelo pr&oacute;prio </span>
    <span class="c1">VENDEDOR, </span>
    <span class="c0">pelo valor do </span>
    <span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O. </span>
    <span class="c0">O valor recebido na conta banc&aacute;ria indicada pelo </span>
    <span class="c1">VENDEDOR</span>
    <span class="c0">&nbsp; ser&aacute; o valor da remunera&ccedil;&atilde;o descontado do valor da </span>
    <span class="c1">TAXA DE USO</span>
    <span class="c0">&nbsp; e da </span>
    <span class="c1">TAXA DE LICEN&Ccedil;A</span>
    <span class="c0">&nbsp; e outras </span>
    <span class="c1">TAXAS</span>
    <span class="c0 c2">&nbsp; por venturas existentes.</span>
  </p>
  <p class="c3">
    <span class="c0 c2"></span>
  </p>
  <h2 class="c10" id="h.30j0zll">
    <span class="c1 c2">2. ACEITA&Ccedil;&Atilde;O DO TERMO DE USO</span>
  </h2>
  <p class="c11">
    <span class="c0">2.1 Para utilizar os </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">, o </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp; dever&aacute; primeiro concordar com o presente </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">E POL&Iacute;TICAS DA ECOM.</span>
    <span class="c0">&nbsp; N&atilde;o &eacute; permitido o uso dos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp; por parte do </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp; se ele n&atilde;o aceitar as condi&ccedil;&otilde;es do presente </span>
    <span class="c1">TERMO DE USO E POL&Iacute;TICAS DA ECOM</span>
    <span class="c0 c2">&nbsp;.</span>
  </p>
  <p class="c4">
    <span class="c0">2.2 Para aceitar o presente </span>
    <span class="c1">TERMO DE USO E POL&Iacute;TICAS DA ECOM</span>
    <span class="c0">&nbsp;, o </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0 c2">&nbsp; dever&aacute;:</span>
  </p>
  <p class="c4">
    <span class="c0">(a) clicar para criar uma conta, aceitar ou concordar, no caso de essa op&ccedil;&atilde;o ser disponibilizada pela </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; na interface do </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp; na </span>
    <span class="c1">PLATAFORMA ECOMPAY</span>
    <span class="c0 c2">&nbsp;; ou</span>
  </p>
  <p class="c4">
    <span class="c0">(b) utilizar efetivamente os </span>
    <span class="c1">SERVI&Ccedil;OS DA PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp;. Nesse caso, o </span>
    <span class="c1">USU&Aacute;RIO</span>
    <span class="c0">&nbsp; compreende e aceita que a </span>
    <span class="c1">ECOM</span>
    <span class="c0">&nbsp; considerar&aacute; o seu uso dos </span>
    <span class="c1">SERVI&Ccedil;OS DA PLATAFORMA ECOMPAY</span>
    <span class="c0">&nbsp; como uma aceita&ccedil;&atilde;o do </span>
    <span class="c1">TERMO DE USO, CONTRATOS E POL&Iacute;TICAS DA ECOM</span>
    <span class="c0 c2">&nbsp; a partir desse momento.</span>
  </p>
  <p class="c4">
    <span class="c0">2.3 Ao utilizar e/ou contratar os </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp;, o </span>
    <span class="c1">USU&Aacute;RIO </span>
    <span class="c0 c2">tamb&eacute;m declara que:</span>
  </p>
  <p class="c4">
    <span class="c0">(a) se for pessoa jur&iacute;dica, &eacute; validamente existente, est&aacute; devidamente constitu&iacute;da e registrada no registro comercial competente, possuindo plena capacidade para celebrar este </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c0">(b) se pessoa f&iacute;sica, &eacute; maior de 18 (dezoito) anos e possui capacidade jur&iacute;dica para celebrar os </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c0">(c) possui capacidade financeira para arcar com os custos e despesas inerentes aos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0 c2">&nbsp;;</span>
  </p>
  <p class="c4">
    <span class="c0">(d) as informa&ccedil;&otilde;es fornecidas para preenchimento do cadastro para cria&ccedil;&atilde;o da </span>
    <span class="c1">CONTA ECOM</span>
    <span class="c0">&nbsp; s&atilde;o completas, corretas, verdadeiras e atualizadas. Ao criar e cadastrar a </span>
    <span class="c1">CONTA ECOM</span>
    <span class="c0 c2">&nbsp; voc&ecirc; dever&aacute; criar uma senha para uso pr&oacute;prio, sendo de sua integral e exclusiva responsabilidade manter sigilo de sua senha e evitar que terceiros tenham acesso a esta; e</span>
  </p>
  <p class="c4">
    <span class="c0">(e) manter&aacute; as informa&ccedil;&otilde;es fornecidas para o cadastro para cria&ccedil;&atilde;o da </span>
    <span class="c1">CONTA ECOM</span>
    <span class="c0 c2">&nbsp; sempre atualizadas.</span>
  </p>
  <p class="c4">
    <span class="c0">2.4. O </span>
    <span class="c1">TERMO DE USO</span>
    <span class="c0">&nbsp; &nbsp;</span>
    <span class="c1">E POL&Iacute;TICAS DA ECOM</span>
    <span class="c0">&nbsp; regulam o acesso ou uso dos </span>
    <span class="c1">SERVI&Ccedil;OS DA ECOM</span>
    <span class="c0">&nbsp; para todos os </span>
    <span class="c1">USU&Aacute;RIOS</span>
    <span class="c0 c2">&nbsp;, em qualquer lugar do mundo.</span>
  </p>
  <p class="c4">
<span class="c0">2.5. Ao aceitar o </span>
<span class="c1">TERMO USO e POL&Iacute;TICAS DA ECOM</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; entende, aceita e autoriza a subcontra&ccedil;&atilde;o, em seu nome, da empresa IUGU INSTITUI&Ccedil;&Atilde;O DE PAGAMENTO S.A. , sociedade an&ocirc;nima fechada com sede na cidade de S&atilde;o Paulo, estado de S&atilde;o Paulo, na Avenida Engenheiro Luiz Carlos Berrini, 1.376, 16&ordm;, 17&ordm; e TA andares, Brooklin Paulista, CEP 04571-936, com endere&ccedil;o para correspond&ecirc;ncias na Avenida das Na&ccedil;&otilde;es Unidas, 12.495, 16&ordm; e 17&ordm; andares, Cidade Mon&ccedil;&otilde;es, CEP 04578-000, inscrita no CNPJ/ME sob o n&ordm; 15.111.975/0001-64, na Cidade de S&atilde;o Paulo, Estado de S&atilde;o Paulo, Institui&ccedil;&atilde;o de Pagamento devidamente autorizada a funcionar pelo Banco Central do Brasil, respons&aacute;vel pela realiza&ccedil;&atilde;o da contas de pagamento e desde j&aacute; aceitam e aderem tamb&eacute;m os termos e condi&ccedil;&otilde;es de presta&ccedil;&atilde;o de servi&ccedil;os do &nbsp;da IUGU. Tal empresa ser&aacute; respons&aacute;vel por cadastrar os dados dos </span>
<span class="c1">USU&Aacute;RIOS, </span>
<span class="c0">criar e manter as contas bem como e processar todos os pagamentos, transfer&ecirc;ncias e saques realizados na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">2.6. Ao aceitar </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">E POL&Iacute;TICAS DA ECOM</span>
<span class="c0">&nbsp;, voc&ecirc; declara que conhece e concorda com os seus conte&uacute;dos. As </span>
<span class="c1">POL&Iacute;TICAS DA ECOM</span>
<span class="c0">&nbsp; s&atilde;o parte integrante destes </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; e se incorporam a eles por refer&ecirc;ncia, ainda que sejam apresentadas em textos separados. Em caso de conflito, as regras do </span><span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; devem prevalecer sobre as condi&ccedil;&otilde;es estabelecidas nas </span>
<span class="c1">&nbsp;POL&Iacute;TICAS DA ECOM</span>
<span class="c0">&nbsp;, exceto se houver previs&otilde;es espec&iacute;ficas estabelecendo que determinada </span>
<span class="c1">POL&Iacute;TICA DA ECOM</span>
<span class="c0">&nbsp; prevalece. O presente </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; e suas atualiza&ccedil;&otilde;es tamb&eacute;m prevalecem sobre todas as propostas, entendimentos ou acordos anteriores, verbais ou escritos, que possam existir entre o </span>
<span class="c1">USU&Aacute;RIO </span>
<span class="c0">e a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">2.7. Ap&oacute;s voc&ecirc; aceitar o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; concede a voc&ecirc; automaticamente uma licen&ccedil;a de uso n&atilde;o exclusiva da </span>
<span class="c1">PLATAFORMA ECOMPAY.</span>
<span class="c0">&nbsp; Os recursos contidos na </span>
<span class="c1">PLATAFORMA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOMPAY</span>
<span class="c0">&nbsp; s&atilde;o licenciados no estado em que se encontram. Eles podem ser modificados, substitu&iacute;dos ou removidos da </span>
<span class="c1">PLATAFORMA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOMPAY</span>
<span class="c0 c2">&nbsp; a qualquer momento, sem aviso pr&eacute;vio.</span>
</p>
<p class="c4">
<span class="c0">2.8. O respeito &agrave;s condi&ccedil;&otilde;es do </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; &eacute; essencial para o uso leg&iacute;timo dos recursos disponibilizados na </span>
<span class="c1">PLATAFORMA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOMPAY</span>
<span class="c0">&nbsp;. Se voc&ecirc; usar a </span>
<span class="c1">PLATAFORMA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOMPAY</span>
<span class="c0">&nbsp; de modo incompat&iacute;vel com o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode aplicar diferentes medidas, a qualquer tempo, com ou sem aviso pr&eacute;vio. Essas medidas podem incluir, sem preju&iacute;zo de outras, (a) a suspens&atilde;o do acesso a um ou mais </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;; (b) o cancelamento da </span>
<span class="c1">CONTA ECOM;</span>
<span class="c0">&nbsp; ou (c) o encerramento da sua licen&ccedil;a de uso da </span>
<span class="c1">PLATAFORMA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">2.9. Ao aceitar o </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, voc&ecirc; autoriza que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; reporte &agrave;s autoridades competentes qualquer ato relacionado ao acesso ou uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; considere ter ind&iacute;cios de irregularidades ou ilegalidades. O </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; reportado n&atilde;o pode exigir que a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; pague qualquer tipo de indeniza&ccedil;&atilde;o, por dano patrimonial ou extrapatrimonial, por consequ&ecirc;ncia dessa comunica&ccedil;&atilde;o.</span>
</p>
<p class="c4">
<span class="c0">2.10. O tratamento de dados pessoais relacionados ao acesso e uso dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; &nbsp;est&aacute; descrito na Pol&iacute;tica de Privacidade da </span>
<span class="c1 c2">ECOM.</span>
</p>
<p class="c4">
<span class="c0">2.11. O servi&ccedil;o de processamento de pagamento relacionado ao uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; &nbsp;ou realizado por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;&eacute; prestado a voc&ecirc;, conforme estabelecido na </span>
<span class="c1">POL&Iacute;TICA DE PAGAMENTO DA ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">2.12. Ao usar os </span>
<span class="c1">SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; com rela&ccedil;&atilde;o a qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; se responsabiliza por identificar, compreender e cumprir todas as leis, regras e regulamenta&ccedil;&otilde;es aplic&aacute;veis. Isso pode incluir, entre outras, normas de natureza c&iacute;vel, normas sobre propriedade intelectual, privacidade, uso de dados pessoais, sobre o an&uacute;ncio de seu </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; e sobre sua comercializa&ccedil;&atilde;o perante os </span>
<span class="c1">&nbsp;COMPRADORES</span>
<span class="c0">&nbsp;. As normas aplic&aacute;veis tamb&eacute;m podem exigir provid&ecirc;ncias de natureza regulat&oacute;ria, como obten&ccedil;&atilde;o de licen&ccedil;as, permiss&otilde;es ou autoriza&ccedil;&otilde;es por &oacute;rg&atilde;os p&uacute;blicos, al&eacute;m de inscri&ccedil;&otilde;es em registros profissionais e o respeito a regulamenta&ccedil;&otilde;es emitidas por eles. Regras sobre tributos ou contabilidade tamb&eacute;m podem incidir. Em caso de d&uacute;vidas sobre como as leis locais se aplicam aos seus neg&oacute;cios na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou &agrave; sua rela&ccedil;&atilde;o com a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, procure a sua assessoria jur&iacute;dica ou cont&aacute;bil.</span>
</p>
<p class="c4">
<span class="c0">2.13. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se reserva o direito de modificar o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; a qualquer tempo. Se o </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp; for alterado, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; publicar&aacute; na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; revisado contendo a da data da &uacute;ltima atualiza&ccedil;&atilde;o. O </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; poder&aacute; ou n&atilde;o ser notificado sobre as altera&ccedil;&otilde;es antes da data de in&iacute;cio da sua vig&ecirc;ncia. Se voc&ecirc; discordar da nova vers&atilde;o do </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;, voc&ecirc; pode encerrar este </span>
<span class="c1">CONTRATO</span>
<span class="c0">&nbsp; com efeito imediato. A continua&ccedil;&atilde;o do acesso e uso da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">significar&aacute; aceita&ccedil;&atilde;o por parte do </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0 c2">&nbsp; das altera&ccedil;&otilde;es.</span>
</p>
<p class="c4">
<span class="c0">2.14. Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o exercer imediatamente algum direito que lhe seja cab&iacute;vel na forma da lei ou do </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, por qualquer motivo, ou mesmo se deixar de exercer algum direito em determinado caso, isso n&atilde;o significa que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; estar&aacute; renunciando ao exerc&iacute;cio desses direitos. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode exercer seus direitos a qualquer tempo, a seu crit&eacute;rio, e se a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; renunciar ao exerc&iacute;cio de qualquer um de seus direitos individualmente isso n&atilde;o significa que estar&aacute; renunciando ao exerc&iacute;cio de seus direitos como um todo.</span>
</p>
<p class="c4">
<span class="c0">2.15. O uso de exemplos no </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0 c2">&nbsp;, bem como o uso de express&otilde;es como &ldquo;inclusive&rdquo;, &ldquo;incluindo&rdquo; e outros termos semelhantes, n&atilde;o pode ser interpretado de modo a limitar a abrang&ecirc;ncia das regras que os utilizam, tendo sempre fun&ccedil;&atilde;o exemplificativa e, portanto, n&atilde;o exaustiva.</span>
</p>
<p class="c4">
<span class="c0">2.16. O idioma oficial do </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; &eacute; o portugu&ecirc;s brasileiro. Qualquer vers&atilde;o do </span>
<span class="c1">TERMO DE USO</span>
<span class="c0 c2">&nbsp; em outro idioma &eacute; uma tradu&ccedil;&atilde;o fornecida por cortesia. Em caso de conflito, a vers&atilde;o em portugu&ecirc;s brasileiro deve prevalecer para todas as contrata&ccedil;&otilde;es.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span>
</p>
<h2 class="c10" id="h.1fob9te">
<span class="c1 c2">3. SERVI&Ccedil;OS DA ECOM E PLATAFORMA ECOMPAY</span>
</h2>
<p class="c11">
<span class="c0">3.1. Escopo dos </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM: </span>
<span class="c0">a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; disponibiliza um conjunto de recursos </span>
<span class="c0 c7">online</span>
<span class="c0">&nbsp; que permitem aos seus </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; transacionar </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;. Quando os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; realizam uma transa&ccedil;&atilde;o referente a um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, eles celebram um contrato diretamente um com o outro. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o &eacute; e n&atilde;o se torna parte interveniente ou garantidora de qualquer rela&ccedil;&atilde;o entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.2. Rela&ccedil;&atilde;o entre os </span>
<span class="c1">&nbsp;USU&Aacute;RIOS </span>
<span class="c0">e a </span>
<span class="c1">&nbsp;ECOM</span>
<span class="c0">&nbsp;: os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">contratam por meio deste </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; os </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp;. O </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; em nenhuma hip&oacute;tese pode ser interpretado como contrato que rege rela&ccedil;&atilde;o de v&iacute;nculo de emprego, de colabora&ccedil;&atilde;o, de representa&ccedil;&atilde;o, agenciamento, societ&aacute;ria ou parceria com a 
</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Os </span>
<span class="c1">&nbsp;USU&Aacute;RIOS</span>
<span class="c0">&nbsp; n&atilde;o podem: (i) se apresentar como se tivessem v&iacute;nculos citados acima com a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;; (ii) atuar em nome ou em benef&iacute;cio da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; t&ecirc;m liberdade de atua&ccedil;&atilde;o e discricionariedade para divulgar e comercializar os </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; dos quais sejam </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp;, respeitadas as normas legais, as regras do </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; e a </span>
<span class="c1">POL&Iacute;TICAS DA ECOM</span>
<span class="c0 c2">&nbsp;, bem como as leis, regras e legisla&ccedil;&otilde;es aplic&aacute;veis.</span>
</p>
<p class="c4">
<span class="c0">3.3. </span>
<span class="c1">Titularidade dos PRODUTOS OU SERVI&Ccedil;OS:</span>
<span class="c0">&nbsp; a condi&ccedil;&atilde;o de titular e fornecedora dos recursos disponibilizados pela </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;n&atilde;o significa que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; cria, elabora, controla, endossa ou fornece qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Os </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; s&atilde;o integralmente respons&aacute;veis pelos seus </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;, inclusive suas regras de oferta e uso. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o se responsabiliza por quaisquer disputas ou danos, de qualquer natureza, que possam surgir do relacionamento entre seus </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;, ou deles com terceiros. Em especial, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o &eacute; respons&aacute;vel por qualquer pretens&atilde;o que o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; possa ter por confiar em uma informa&ccedil;&atilde;o fornecida por um </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTOR</span>
<span class="c0">&nbsp; ou um </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; ou veiculada em um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.4. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o promete nem garante que o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; atingir&aacute; qualquer desempenho, ganho ou resultado com o uso da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;ou com a aquisi&ccedil;&atilde;o de qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Al&eacute;m disso, nenhum </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADOR</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">COPRODUTOR</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; pode prometer desempenho, ganho ou resultado de qualquer natureza, seja decorrente do uso da 
</span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, de sua condi&ccedil;&atilde;o de </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;, ou do uso ou comercializa&ccedil;&atilde;o dos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O </span>
<span class="c0">na </span><span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p><p class="c4">
<span class="c0">3.5. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o se obriga a fazer curadoria ou controle de qualidade dos </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; tamb&eacute;m n&atilde;o se obriga a fazer controle pr&eacute;vio da sua base de </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.6. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se reserva o direito de realizar verifica&ccedil;&otilde;es antes ou depois do cadastro dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;, da oferta de um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, bem como o direito de exigir informa&ccedil;&otilde;es adicionais ou at&eacute; mesmo mudan&ccedil;as no </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; cadastrado na </span>
<span class="c1">PLATAFORMA ECOMPAY, </span>
<span class="c0">assim como eventual exclus&atilde;o </span>
<span class="c1">&nbsp;do PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Quaisquer refer&ecirc;ncias a um </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; sendo &ldquo;analisado&rdquo; (ou linguagem similar) apenas indica que o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; se submete a um processo de verifica&ccedil;&atilde;o cadastral, e nada mais. Nenhuma dessas descri&ccedil;&otilde;es significa endosso, certifica&ccedil;&atilde;o ou garantia da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; sobre a qualidade do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Essas descri&ccedil;&otilde;es tamb&eacute;m n&atilde;o representam qualquer atestado de que o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; ou o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; respeitam o disposto na legisla&ccedil;&atilde;o aplic&aacute;vel, no </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp; ou nas </span>
<span class="c1">POL&Iacute;TICAS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Lembramos que os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; s&atilde;o os respons&aacute;veis por cumprir todas as regras aplic&aacute;veis aos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; cadastrados e a sua comercializa&ccedil;&atilde;o, e n&atilde;o a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.7. Para ampliar a exposi&ccedil;&atilde;o dos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;s, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode inclu&iacute;-los em seu </span>
<span class="c0 c7">e-commerce </span>
<span class="c0">na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, de acordo com crit&eacute;rios estabelecidos pela pr&oacute;pria </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; tamb&eacute;m pode mencionar ou promover os </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; em suas comunica&ccedil;&otilde;es, interna ou externa, bem como em outros </span>
<span class="c0 c7">sites</span>
<span class="c0">&nbsp;, em aplicativos, em publicidade </span>
<span class="c0 c7">on</span>
<span class="c0">&nbsp;</span>
<span class="c0 c7">line</span>
<span class="c0">&nbsp; e </span>
<span class="c0 c7">off-line</span>
<span class="c0">&nbsp;, inclusive por meio de </span>
<span class="c1">SERVI&Ccedil;OS DE</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">TERCEIROS.</span>
<span class="c0">&nbsp; Essa divulga&ccedil;&atilde;o n&atilde;o significa que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; endossa ou concorda com o conte&uacute;do dos </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;. Al&eacute;m disso, </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; que tiverem seus </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; divulgados n&atilde;o fazem jus a qualquer benef&iacute;cio, remunera&ccedil;&atilde;o ou indeniza&ccedil;&atilde;o por essa divulga&ccedil;&atilde;o ou fim da exposi&ccedil;&atilde;o no </span>
<span class="c0 c7">e-commerce</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.8. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o pode garantir que a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;fique dispon&iacute;vel e acess&iacute;vel ininterruptamente. Al&eacute;m disso, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode restringir a disponibilidade da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou de certas &aacute;reas ou recursos a ela relacionados, se necess&aacute;rio, considerando os limites de capacidade, a seguran&ccedil;a ou a integridade de seus servidores, bem como para realizar medidas de manuten&ccedil;&atilde;o ou aprimoramento dos seus servi&ccedil;os. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o pode ser responsabilizada pelo </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; ou por qualquer terceiro em fun&ccedil;&atilde;o do impedimento ou altera&ccedil;&atilde;o na forma de acesso &agrave; </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">e aos </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode melhorar e alterar a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;a qualquer tempo, seja para modificar, substituir ou remover </span>
<span class="c1">SERVI&Ccedil;OS da ECOM </span>
<span class="c0">existentes, ou para adicionar </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0 c2">novos.</span>
</p>
<p class="c4">
<span class="c0">3.9. A </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; pode conter </span>
<span class="c0 c7">links</span>
<span class="c0">&nbsp; para </span>
<span class="c0 c7">sites</span>
<span class="c0">&nbsp; ou recursos de terceiros. Esses </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">de Terceiros n&atilde;o integram o escopo dos </span>
<span class="c1">SERVI&Ccedil;OS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; e n&atilde;o fazem parte da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. Por isso, eles est&atilde;o sujeitos a termos e condi&ccedil;&otilde;es diferentes, inclusive com rela&ccedil;&atilde;o &agrave;s pr&aacute;ticas de privacidade. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o &eacute; respons&aacute;vel pela disponibilidade ou pela precis&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS DE TERCEIROS</span>
<span class="c0">&nbsp;, tampouco pelo seu conte&uacute;do, pelos produtos ou servi&ccedil;os dispon&iacute;veis nesses </span>
<span class="c1">SERVI&Ccedil;OS DE TERCEIROS</span>
<span class="c0">&nbsp;. Os </span>
<span class="c0 c7">links</span>
<span class="c0">&nbsp; para esses </span>
<span class="c1">SERVI&Ccedil;OS DE TERCEIROS</span>
<span class="c0">&nbsp; n&atilde;o representam endosso ou concord&acirc;ncia da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; a seu respeito.</span>
</p>
<p class="c4"><span class="c0">3.10. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; disp&otilde;e de mecanismos de atendimento aos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; para facilitar a solu&ccedil;&atilde;o de problemas relativos aos </span>
<span class="c1">SERVI&Ccedil;OS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o disponibiliza servi&ccedil;o de atendimento ao consumidor para resolver problemas relativos aos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, o que &eacute; de responsabilidade integral do </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp;. &nbsp;&ndash;</span>
</p>
<p class="c4">
<span class="c0">3.11. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; oferece uma </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">cujo o objetivo &eacute; que o VENDEDOR &nbsp;consiga focar em desenvolver </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; de qualidade e criar relacionamentos mais pr&oacute;ximos com seus Compradores. Para isso, al&eacute;m de recursos para cria&ccedil;&atilde;o, divulga&ccedil;&atilde;o e pagamento pelo </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; tamb&eacute;m disponibiliza recursos que auxiliam as vendas de forma simplificada, cabendo aos VENDEDORES &nbsp;escolherem a quais desejam integrar seus </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.12. Ao usar qualquer recurso da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;para criar, divulgar ou comercializar um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; declara ser o titular exclusivo de todos os direitos de propriedade intelectual relativos ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; e ao seu conte&uacute;do, ou estar regularmente autorizado pelos titulares desses direitos para faz&ecirc;-lo. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; tamb&eacute;m garante que o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; n&atilde;o viola quaisquer direitos de propriedade intelectual de terceiros, inclusive de imagem ou direitos autorais, sendo o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; obra original do </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, ou tendo o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; recebido as licen&ccedil;as ou cess&otilde;es de direitos pertinentes dos titulares de direitos sobre o conte&uacute;do integrante do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Al&eacute;m disso, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; deve ter todos os direitos para conceder &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; as licen&ccedil;as necess&aacute;rias para a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; desempenhar suas atividades relativas ao uso da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;com rela&ccedil;&atilde;o ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, na forma d </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; e das </span>
<span class="c1">POL&Iacute;TICAS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.13. Ao usar qualquer recurso da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;para criar, divulgar ou comercializar um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; declara ter o direito de comercializar o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; em car&aacute;ter de exclusividade e assume a obriga&ccedil;&atilde;o de comercializ&aacute;-lo exclusivamente pela </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; tamb&eacute;m n&atilde;o pode autorizar terceiros a explorarem qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; concorrentemente, nem deixar de tomar medidas para impedir que terceiros os explorem em concorr&ecirc;ncia com a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">3.14. O prop&oacute;sito da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; &eacute; possibilitar que as pessoas empreendam e se eduquem com mais facilidade e, assim, se desenvolvam tanto na &aacute;rea profissional quanto na pessoal. Para atingir esse objetivo, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; adota alguns valores e princ&iacute;pios, que devem ser observados por todos seus </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;: o respeito ao pr&oacute;ximo, aos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; e &agrave;s leis locais &eacute; um deles, assim como a prote&ccedil;&atilde;o &agrave; liberdade de express&atilde;o e a neutralidade e isonomia das redes de Internet. Por isso, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o aceita conte&uacute;dos que violem essas diretrizes. Em termos gerais, voc&ecirc; n&atilde;o pode usar a </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; para criar, divulgar ou comercializar </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; de formato ou conte&uacute;do que violem a legisla&ccedil;&atilde;o aplic&aacute;vel ou direitos de terceiros; (b) que incitem viol&ecirc;ncia, &oacute;dio, discrimina&ccedil;&atilde;o, ou que possam causar preju&iacute;zo aos 
</span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; ou &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;; (c) que prejudiquem a experi&ecirc;ncia dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;; ou (d) que estejam em desacordo com o modelo de neg&oacute;cios da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; ou que sejam contr&aacute;rios aos valores da empresa.</span>
</p>
<p class="c4">
<span class="c0">3.15. Ao adquirir um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp; est&aacute; adquirindo o direito de acesso propriedade, uso, gozo ou posse ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, nos termos estabelecidos na </span>
<span class="c1">P&Aacute;GINA DE VENDA</span>
<span class="c0">&nbsp; do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. A compra do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; n&atilde;o concede ao Comprador direitos de propriedade intelectual sobre o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. O Comprador n&atilde;o est&aacute; autorizado a comercializar ou ceder a terceiros o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, no todo ou em parte, ainda que a t&iacute;tulo gratuito. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode cessar de forma tempor&aacute;ria ou definitiva o acesso do </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp; ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, inclusive por bloqueio de acesso ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou bloqueio de </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;, se for verificada a inobserv&acirc;ncia d </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; ou a suspeita de fraude. Uma fraude pode ser caracterizada pelo fornecimento ou compartilhamento de senha, assim como por outras condutas, como o </span>
<span class="c0 c7">download</span>
<span class="c0">&nbsp;, transmiss&atilde;o, retransmiss&atilde;o ou armazenamento n&atilde;o autorizado do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<h2 class="c10" id="h.3znysh7">
<span class="c1 c2">4. CADASTRO</span>
</h2>
<p class="c11">
<span class="c0">4.1. A idade m&iacute;nima para cadastrar uma </span>
<span class="c1">CONTA ECOM </span>
<span class="c0">na qualidade de </span>
<span class="c1">&nbsp;VENDEDOR, COPRODUTOR, COLABORADOR </span>
<span class="c0">ou </span>
<span class="c1">AFILIADO </span>
<span class="c0 c2">&nbsp;&eacute; de 18 (dezoito) anos de idade, ou menos, caso seja emancipado e ser plenamente capaz para exercer todos os atos da vida civil, em todos os casos,respeitando a legisla&ccedil;&atilde;o aplic&aacute;vel.</span>
</p>
<p class="c4">
<span class="c0">4.2. A idade m&iacute;nima para </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp; se cadastrarem na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; &eacute; de 18 (dezoito) anos. Caso haja uma transa&ccedil;&atilde;o realizada por menor de idade, a responsabilidade pela transa&ccedil;&atilde;o &eacute; dos respons&aacute;veis legais, estando isenta </span>
<span class="c1">a ECOM</span>
<span class="c0">&nbsp; &nbsp;de qualquer demanda dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp; ou de terceiros.</span>
</p>
<p class="c4">
<span class="c0">4.3. Se o cadastro da </span>
<span class="c1">CONTA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; for efetuado em nome de pessoa jur&iacute;dica, o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; se compromete a ter e manter em seu poder todas as autoriza&ccedil;&otilde;es necess&aacute;rias para vincular a pessoa jur&iacute;dica da qual &eacute; representante legal ao </span>
<span class="c1">TERMO DE USO </span>
<span class="c0">e </span>
<span class="c1">&nbsp;POL&Iacute;TICAS DA ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">4.4. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode recusar ou cancelar o cadastro de determinado </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0 c2">&nbsp; se tomar conhecimento, por si ou por den&uacute;ncia fundamentada de terceiro, da exist&ecirc;ncia de condena&ccedil;&atilde;o, mediante senten&ccedil;a transitada em julgado, por (a) crimes hediondos, ou equiparados a crimes hediondos; (b) envolvimento em organiza&ccedil;&otilde;es criminosas, lavagem de dinheiro, atos terroristas ou tr&aacute;fico internacional de pessoas; ou (c) crimes cometidos por meios eletr&ocirc;nicos ou mecanismos cibern&eacute;ticos.</span>
</p>
<p class="c4">
<span class="c0">4.5. Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; limitar seu acesso ou uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, suspender sua conta ou encerrar este Contrato, voc&ecirc; n&atilde;o poder&aacute; registrar uma nova conta na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, nem acessar ou usar a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">atrav&eacute;s da conta de outro </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;, sem autoriza&ccedil;&atilde;o pr&eacute;via da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; e desde que os motivos que ensejaram a suspens&atilde;o, ou cancelamento, n&atilde;o subsistam.</span>
</p>
<p class="c4">
<span class="c0">4.6. O </span>
<span class="c1">USU&Aacute;RIO </span>
<span class="c0">se compromete e se obriga a preencher adequadamente e com informa&ccedil;&otilde;es corretas todos os dados solicitados pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode usar quaisquer meios legalmente admitidos para identificar o </span>
<span class="c1">USU&Aacute;RIO,</span>
<span class="c0">&nbsp; assim como pode requerer a qualquer momento dados adicionais para complementar aqueles j&aacute; fornecidos. Na hip&oacute;tese do </span><span class="c1">USU&Aacute;RIO </span>
<span class="c0">cadastrar ou </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;enviar informa&ccedil;&otilde;es inver&iacute;dicas, incorretas ou incompletas, o seu cadastro na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; pode ser suspenso ou cancelado. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode recusar seu cadastro ou cancel&aacute;-lo se entender que h&aacute; ind&iacute;cios de que voc&ecirc; est&aacute; usando ou tende a utilizar a </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; em desacordo com </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">4.7. O </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; &eacute; o &uacute;nico respons&aacute;vel por cadastrar dados verdadeiros, exatos e atualizados, e responde pelas consequ&ecirc;ncias dos dados ou informa&ccedil;&otilde;es inver&iacute;dicos, incompletos ou incorretos que fornecer no cadastro ou depois dele. &Eacute; de responsabilidade do </span>
<span class="c1">USU&Aacute;RIO </span>
<span class="c0">averiguar sempre as informa&ccedil;&otilde;es fornecidas &agrave; </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp; antes de concluir o seu cadastro.</span>
</p>
<p class="c4">
<span class="c0">4.8. O </span>
<span class="c1">USU&Aacute;RIO </span>
<span class="c0">&eacute; o &uacute;nico respons&aacute;vel por seu </span>
<span class="c0 c7">login</span>
<span class="c0">&nbsp;, por sua senha e por qualquer atividade conduzida por meio de sua conta na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o &eacute; respons&aacute;vel por quaisquer danos, patrimoniais ou extrapatrimoniais, resultantes do uso indevido da sua conta por terceiros. &Eacute; imprescind&iacute;vel que voc&ecirc; mantenha a confidencialidade e a seguran&ccedil;a das suas credenciais de acesso &agrave; sua </span>
<span class="c1">CONTA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, que s&atilde;o pessoais e intransfer&iacute;veis. N&atilde;o divulgue ou de qualquer maneira compartilhe essas credenciais a terceiros.</span>
</p>
<p class="c4">
<span class="c0">4.9. Nenhum empregado, colaborador, representante, agente ou qualquer pessoa vinculada direta ou indiretamente &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; est&aacute; autorizada a solicitar que voc&ecirc; compartilhe sua senha de acesso. Voc&ecirc; tamb&eacute;m n&atilde;o deve compartilhar suas credenciais de acesso com </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; ou outros </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;, pois as atividades que esses </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; desempenham n&atilde;o dependem do compartilhamento desse tipo de informa&ccedil;&atilde;o. Voc&ecirc; deve notificar a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; imediatamente se voc&ecirc; tomar conhecimento ou suspeitar que suas credenciais de acesso foram extraviadas, furtadas, apropriadas indevidamente por terceiros, tiveram sua confidencialidade comprometida ou foram de qualquer forma utilizadas sem sua autoriza&ccedil;&atilde;o.</span>
</p>
<p class="c4">
<span class="c0">4.10. O </span>
<span class="c1">VENDEDOR, COPRODUTOR</span>
<span class="c0">&nbsp; e/ou </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; devem cadastrar os dados banc&aacute;rios, indicando conta corrente de sua titularidade, no mesmo domic&iacute;lio de seu cadastro para iniciar o processo de vendas efetivado por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. A valida&ccedil;&atilde;o banc&aacute;ria pode levar at&eacute; 02 (dois) dias &uacute;teis. O cadastro ou o repasse de valores devidos aos </span><span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; para conta banc&aacute;ria de terceiros n&atilde;o &eacute; permitido em nenhuma circunst&acirc;ncia e a nenhum pretexto. Tamb&eacute;m &eacute; proibido o cadastro ou repasse de valores para conta banc&aacute;ria de mesma titularidade, mas registrada em domicilio diferente do </span>
<span class="c1">VENDEDOR, COPRODUTOR</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADO</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">4.11. </span>
<span class="c1">Tratamento dos Dados: </span>
<span class="c0">a </span>
<span class="c1">POL&Iacute;TICA DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; rege o tratamento dos dados pessoais que voc&ecirc; fornece &agrave; </span><span class="c1">ECOM</span>
<span class="c0">&nbsp; durante o uso e o acesso da </span><span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<h2 class="c10" id="h.2et92p0">
<span class="c1 c2">5. RESPONSABILIDADES E OBRIGA&Ccedil;&Otilde;ES DOS USU&Aacute;RIOS</span>
</h2>
<p class="c11">
<span class="c0">5.1. Os </span>
<span class="c1">&nbsp;USU&Aacute;RIOS </span>
<span class="c0">da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">e dos </span>
<span class="c1">&nbsp;SERVI&Ccedil;OS DAS ECOM </span>
<span class="c0">devem acessar a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou usar os </span>
<span class="c1">SERVI&Ccedil;OS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; apenas para fins l&iacute;citos.</span>
</p>
<p class="c4">
<span class="c0">5.2. Os </span>
<span class="c1">USU&Aacute;RIOS </span>
<span class="c0">se comprometem a </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;preservar a reputa&ccedil;&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; e evitar que qualquer pr&aacute;tica realizada por si ou por terceiros relacionados aos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; possa, direta ou indiretamente, desabonar a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, os </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp;, incluindo a </span>
<span class="c1">PLATAFORMAR ECOMPAY</span>
<span class="c0 c2">&nbsp;, seus empregados, colaboradores, representantes, agentes, s&oacute;cios ou parceiros.</span>
</p>
<p class="c4">
<span class="c0">5.3. Os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; e dos </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp; s&atilde;o proibidos de copiar, distribuir, usar ou publicar total ou parcialmente qualquer material, informa&ccedil;&atilde;o, qualquer item da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ofertado na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou nos </span>
<span class="c1">&nbsp;SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp; sem pr&eacute;via autoriza&ccedil;&atilde;o por escrito da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, a&iacute; inclu&iacute;dos, mas n&atilde;o limitados a qualquer tipo de informa&ccedil;&atilde;o, </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;marcas, patentes, criativos, artes, v&iacute;deos, textos, desenhos industriais, nomes empresariais, nomes de dom&iacute;nio, programas, bancos de dados, redes, arquivos, obras autorais e qualquer outra propriedade intelectual relacionada aos 
</span>
<span class="c1">SERVI&Ccedil;OS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; ou contida na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">que s&atilde;o de titularidade exclusiva da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, ou foram regularmente cedidas ou licenciadas &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;. Esses elementos s&atilde;o protegidos pelas leis e tratados internacionais de propriedade intelectual.</span>
</p>
<p class="c4">
<span class="c0">5.4. Os </span>
<span class="c1">&nbsp;USU&Aacute;RIOS</span>
<span class="c0">&nbsp; se comprometem e se obrigam a respeitar os demais </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; e terceiros e manter um relacionamento saud&aacute;vel, amistoso e harmonioso com os demais </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; e com terceiros durante o uso e decorrente do uso da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp;. Em caso de desrespeito a tal compromisso e obriga&ccedil;&atilde;o, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se reserva ao direito de, ao seu exclusivo crit&eacute;rio, tomar uma s&eacute;rie de medidas previstas no </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, dentre elas a suspens&atilde;o da licen&ccedil;a de uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; e o encerramento da presta&ccedil;&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;, excluindo os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; envolvidos da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;, al&eacute;m de buscar concomitantemente a repara&ccedil;&atilde;o de quaisquer danos.</span>
</p>
<p class="c4">
<span class="c0">5.5. Os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; se obrigam a manter a </span>
<span class="c1">ECOM </span>
<span class="c0">e seus controladores, &agrave;s suas sociedades controladas ou coligadas, os seus administradores, diretores, gerentes, empregados, agentes, colaboradores, representantes e procuradores indenes e a reparar quaisquer danos causados &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; ou a seus controladores, &agrave;s suas sociedades controladas ou coligadas, aos seus administradores, diretores, gerentes, empregados, agentes, colaboradores, representantes e procuradores por a&ccedil;&atilde;o ou omiss&atilde;o que seja imput&aacute;vel ao pr&oacute;prio </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;. Essa obriga&ccedil;&atilde;o de evitar e reparar danos inclui danos patrimoniais e extrapatrimoniais, al&eacute;m de todas as despesas incorridas pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; em decorr&ecirc;ncia de condutas imput&aacute;veis ao pr&oacute;prio </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;, como despesas com a&ccedil;&otilde;es judiciais, acordos extrajudiciais e com honor&aacute;rios de advogados e peritos, decorrentes dos </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS </span>
<span class="c0 c2">comercializados.</span>
</p>
<p class="c4">
<span class="c0">5.6. Aos </span>
<span class="c1">USU&Aacute;RIOS </span>
<span class="c0 c2">&eacute; vedado:</span>
</p>
<p class="c4">
<span class="c0">(a) Violar ou tentar violar quaisquer medidas de seguran&ccedil;a da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">ou </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp;, nem tirar proveito de eventual inconsist&ecirc;ncia de sistemas da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(b) Alterar os dados de qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; depois que a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; concluir o processo de verifica&ccedil;&atilde;o cadastral, inclusive seu t&iacute;tulo, descritivo, condi&ccedil;&otilde;es de compra e conte&uacute;do;</span>
</p>
<p class="c4">
<span class="c0">(c) Induzir terceiros a acreditar, por a&ccedil;&atilde;o ou omiss&atilde;o sua, dentro ou fora da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, que voc&ecirc; &eacute; empregado, colaborador, prestador de servi&ccedil;o, representante ou que mant&eacute;m com a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; qualquer outro v&iacute;nculo al&eacute;m de </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(d) Manipular pre&ccedil;os de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(e) Interferir nas transa&ccedil;&otilde;es realizadas entre outros </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(f) Adotar quaisquer pr&aacute;ticas de divulga&ccedil;&atilde;o, capta&ccedil;&atilde;o de informa&ccedil;&atilde;o ou publicidade que sejam ilegais ou nocivas como por exemplo, mas n&atilde;o se limitando a </span>
<span class="c0 c7">spam, flooding, adware, malware</span>
<span class="c0">&nbsp; para promover qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; cadastrado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, ou </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(g) Declarar ou sugerir que os </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS </span>
<span class="c0">cadastrados </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp; permitem a obten&ccedil;&atilde;o de ganhos f&aacute;ceis, r&aacute;pido, ou que n&atilde;o requerem trabalho ou esfor&ccedil;o;</span>
</p>
<p class="c4">
<span class="c0">(h) Comprar qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O </span>
<span class="c0">com seu pr&oacute;prio link de </span>
<span class="c1">AFILIADO</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(i) Substituir o </span>
<span class="c0 c7">link </span>
<span class="c0">de </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; constante de uma p&aacute;gina de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; em benef&iacute;cio pr&oacute;prio ou de terceiros;</span>
</p>
<p class="c4">
<span class="c0">(j) Usar </span>
<span class="c0 c7">link</span>
<span class="c0">&nbsp; de </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; de seu pr&oacute;prio </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; em sua p&aacute;gina de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(k) Utilizar qualquer sistema para o envio de requisi&ccedil;&otilde;es de acesso e utiliza&ccedil;&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">que supere, em um dado per&iacute;odo de tempo, o que seria normalmente poss&iacute;vel responder, levando ao impedimento de acesso, deteriorando ou de qualquer forma alterando a experi&ecirc;ncia de utiliza&ccedil;&atilde;o da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">e dos </span>
<span class="c1">&nbsp;SERVI&Ccedil;OS DA ECOM</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(l) Utilizar qualquer sistema automatizado, inclusive rob&ocirc;s, </span>
<span class="c0 c7">spiders, scripts ou off-line readers</span>
<span class="c0">&nbsp;, que acessem a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">e os </span>
<span class="c1">&nbsp;SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp; venham a utiliz&aacute;-los de forma contr&aacute;ria ao previsto n </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0 c2">&nbsp; ou na legisla&ccedil;&atilde;o em vigor.</span>
</p>
<h2 class="c10" id="h.tyjcwt">
<span class="c1 c2">6. OBRIGA&Ccedil;&Atilde;O DOS VENDEDORES</span>
</h2>
<p class="c11">
<span class="c0">6.1. Todos os </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; devem ser negociados e comercializados na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">de forma exclusiva. &Eacute; terminantemente proibido ao </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; exibir, divulgar ou comercializar qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, total ou parcialmente, por outras plataformas, meios ou suportes, </span>
<span class="c0 c7">on-line</span>
<span class="c0 c2">&nbsp;, a qualquer t&iacute;tulo, de maneira onerosa ou gratuita.</span>
</p>
<p class="c4">
<span class="c0">6.2. &Eacute; vedado utilizar o nome comercial da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; para divulgar qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, bem como indicar direta ou indiretamente que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se associou, aprovou ou certificou o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Voc&ecirc; deve usar e acessar a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">apenas nos limites necess&aacute;rios para usar os </span>
<span class="c1">SERVI&Ccedil;OS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; na cria&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou comercializa&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.3. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; ser&aacute; o titular de todos os direitos e obriga&ccedil;&otilde;es relacionados ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; criado, divulgado ou comercializado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. Entretanto, ao cadastrar um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; e enquanto for </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; desta, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; automaticamente concede &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; licen&ccedil;a de uso de todos os direitos de propriedade intelectual relativos ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, inclusive os direitos autorais, patrimoniais, de modo sublicenci&aacute;vel, isenta de </span>
<span class="c0 c7">royalties</span>
<span class="c0">&nbsp; ou de qualquer outra remunera&ccedil;&atilde;o. A integra&ccedil;&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; n&atilde;o significa que o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou os direitos de propriedade intelectual a ele associados passam a ser de titularidade da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Essa licen&ccedil;a &eacute; concedida de forma n&atilde;o exclusiva, sendo v&aacute;lida em todos os territ&oacute;rios do mundo, para todos os fins e efeitos legais, e para todas as modalidades de explora&ccedil;&atilde;o, com o objetivo de a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; utilizar, reproduzir, processar, adaptar, retificar, traduzir, incluir legendas, publicar, transmitir, exibir ou distribuir o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; em qualquer m&iacute;dia ou suporte, por qualquer meio de distribui&ccedil;&atilde;o, conhecido ou a ser desenvolvido, dentro e fora da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. Essa licen&ccedil;a tamb&eacute;m permite que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; disponibilize o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; a terceiros, </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; ou n&atilde;o, que mantenham com a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; parceria, contrato ou outro arranjo para fins de </span>
<span class="c0 c7">marketing</span>
<span class="c0">&nbsp;, que podem pressupor o uso, divulga&ccedil;&atilde;o, promo&ccedil;&atilde;o, publicidade, comercializa&ccedil;&atilde;o, venda, distribui&ccedil;&atilde;o, transmiss&atilde;o ou publica&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. A integra&ccedil;&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">DA</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; n&atilde;o significa que o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou os direitos de propriedade intelectual a ele associados passam a ser de titularidade da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. O </span>
<span class="c1">VENDEDOR </span>
<span class="c0">continua sendo titular de todos os direitos e obriga&ccedil;&otilde;es relacionados ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; criado, divulgado ou comercializado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.3.1. Na hip&oacute;tese de encerramento deste </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se compromete a n&atilde;o mais usar, reproduzir, processar, adaptar, alterar, traduzir, incluir legendas, publicar, transmitir, exibir, distribuir ou comercializar o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; em sua </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">para a realiza&ccedil;&atilde;o de novas vendas. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; estar&aacute; autorizada a manter a </span>
<span class="c1">P&Aacute;GINA DE VENDA</span>
<span class="c0">&nbsp; durante todo o prazo de vig&ecirc;ncia estabelecido entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; para os </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp; que tenham adquirido o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; antes da rescis&atilde;o contratual.</span>
</p>
<p class="c4">
<span class="c0">6.4. O </span>
<span class="c1">&nbsp;VENDEDOR</span>
<span class="c0">&nbsp; cede automaticamente &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; o direito de, a seu exclusivo crit&eacute;rio, adicionalmente a qualquer a&ccedil;&atilde;o tomada ou n&atilde;o pelo </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, acionar quaisquer medidas, judiciais ou extrajudiciais, que julgar cab&iacute;vel contra terceiros, relacionadas ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;, bem como cede os direitos de exigir e receber qualquer indeniza&ccedil;&atilde;o decorrente de tais medidas. Isso inclui todos os poderes necess&aacute;rios e exig&iacute;veis para o exerc&iacute;cio e prote&ccedil;&atilde;o dos direitos em ju&iacute;zo ou fora dele.</span>
</p>
<p class="c4">
<span class="c0">6.5. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; se obriga a respeitar &agrave; Lei Geral de Prote&ccedil;&atilde;o de Dados (Lei Federal n&ordm; 13.709/18, ou a que vier a suced&ecirc;-la), para obter, tratar e manter os dados pessoais dos </span>
<span class="c1">USU&Aacute;RIOS DOS SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp; aos quais tiver acesso em decorr&ecirc;ncia dos </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp; com os quais se relaciona ou n&atilde;o com a </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;, em total respeito &agrave; Pol&iacute;tica de Privacidade da ECOM e &agrave;s leis aplic&aacute;veis .</span>
</p>
<p class="c4">
<span class="c0">6.5.1. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; se obriga a comunicar imediatamente &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; qualquer incidente de seguran&ccedil;a ocorrido ou que possa ter ocorrido no tratamento de dados pessoais dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.6. O </span>
<span class="c1">&nbsp;VENDEDOR </span>
<span class="c0">se compromete a manter a </span>
<span class="c1">P&Aacute;GINA DE VENDA</span>
<span class="c0">&nbsp; relativas aos seus </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS </span>
<span class="c0">colocados &agrave; venda </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;na </span>
<span class="c1">&nbsp;PLATAFORMA ECOMPAY </span>
<span class="c0">com todas as informa&ccedil;&otilde;es necess&aacute;rias &agrave; adequada compreens&atilde;o pelos demais 
</span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O </span>
<span class="c0 c2">oferecido. Tais informa&ccedil;&otilde;es ser&atilde;o claras, objetivas, transparentes, completas, contendo pelo menos os seguintes t&oacute;picos:</span>
</p>
<p class="c4">
<span class="c0 c2">(a) Descri&ccedil;&atilde;o, contendo composi&ccedil;&atilde;o, caracter&iacute;sticas, validade, prazo;
</span>
</p>
<p class="c4">
<span class="c0">(b) Nome do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(c) Quantidade (quando o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; n&atilde;o for vendido por unidade);</span>
</p>
<p class="c4">
<span class="c0 c2">(d) Atualiza&ccedil;&otilde;es e vers&otilde;es, se aplic&aacute;vel;</span>
</p>
<p class="c4">
<span class="c0 c2">(e) Garantias e formas de exerc&iacute;cio de garantia ou direito de arrependimento;</span>
</p>
<p class="c4">
<span class="c0">(f) Deveres que devem ser cumpridos pelos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp; e limites aos seus direitos;</span>
</p>
<p class="c4">
<span class="c0 c2">(g) Pre&ccedil;o, formas de pagamento, condi&ccedil;&otilde;es de parcelamento e eventuais promo&ccedil;&otilde;es e descontos;</span>
</p>
<p class="c4">
<span class="c0 c2">(h) Requisitos obrigat&oacute;rios e certifica&ccedil;&atilde;o, se aplic&aacute;vel;</span>
</p>
<p class="c4">
<span class="c0 c2">(i) Formas, prazos, custos e per&iacute;odos de entrega e/ou de acesso;</span>
</p>
<p class="c4">
<span class="c0">(j) Regras de aceita&ccedil;&atilde;o e comiss&atilde;o dos </span>
<span class="c1">AFILIADOS</span>
<span class="c0 c2">&nbsp;; e</span>
</p>
<p class="c4">
<span class="c0">(k) Qualquer outra informa&ccedil;&atilde;o relevante ou decorrente de obriga&ccedil;&atilde;o legal sobre o aproveitamento e a comercializa&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.7. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; se compromete a inserir advert&ecirc;ncias para determinadas categorias de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, ou para alguns </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; espec&iacute;ficos, atendendo &agrave; legisla&ccedil;&atilde;o vigente ou solicita&ccedil;&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, objetivando a garantia de maior transpar&ecirc;ncia sobre os riscos e as expectativas de entrega de 
</span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; cadastrados na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.8.O </span>
<span class="c1">&nbsp;VENDEDOR </span>
<span class="c0">se obriga a </span>
<span class="c1 c2">:</span>
</p>
<p class="c4">
<span class="c0">(a) </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;Prestar suporte t&eacute;cnico e comercial solicitado pelo </span>
<span class="c1">COMPRADOR</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(b) Definir o pre&ccedil;o final do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; que ser&aacute; cobrado do </span>
<span class="c1">COMPRADOR</span>
<span class="c0 c2">&nbsp;:</span>
</p>
<p class="c4">
<span class="c0">(c) Entregar ao </span>
<span class="c1">&nbsp;COMPRADOR </span>
<span class="c0">o </span>
<span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; tal como lhe foi ofertado no momento da compra;</span>
</p>
<p class="c4">
<span class="c0">(d) Responder aos eventuais contatos da equipe de suporte da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; para viabilizar o atendimento adequado aos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(e) Solucionar todos e quaisquer problemas que ocorram com o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ap&oacute;s a entrega ao </span>
<span class="c1">COMPRADOR</span>
<span class="c0 c2">&nbsp;, em especial os problemas t&eacute;cnicos, conforme legisla&ccedil;&atilde;o e garantias oferecidas no momento da compra;</span>
</p>
<p class="c4">
<span class="c0">(f) Respeitar os prazos de exerc&iacute;cio de direito de arrependimento e de garantia exigidos por lei, se aplic&aacute;veis, bem como qualquer prazo adicional que oferecer aos </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp;, obrigando-se a aceitar eventual solicita&ccedil;&atilde;o de cancelamento da venda e reembolso por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; ou de seus canais de atendimento e de suporte. Para tanto, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; desde j&aacute; autoriza a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a realizar a dedu&ccedil;&atilde;o dos valores de reembolso devidos pelo </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; a qualquer </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode deduzir esses valores dos cr&eacute;ditos existentes ou futuros na conta do </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.9. O </span>
<span class="c1">VENDEDOR </span>
<span class="c0">s&oacute; pode cadastrar cada </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; uma &uacute;nica vez, exceto, se decidir cadastrar o mesmo </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; com meios de pagamentos distintos entre si.</span>
</p>
<p class="c4">
<span class="c0">6.10. Os </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; s&atilde;o os &uacute;nicos respons&aacute;veis pelas informa&ccedil;&otilde;es de cadastro, dos </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; relacionados aos seus </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;, garantindo que estes conhecem, concordam e se obrigam a cumprir o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.11. Os </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; devem definir na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; o conjunto de atos facultados a cada </span>
<span class="c1">COPRODUTOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp;, bem como os percentuais, quando aplic&aacute;veis, da remunera&ccedil;&atilde;o pela venda do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; a que cada um faz jus.</span>
</p>
<p class="c4">
<span class="c0">6.11.1. Cabe aos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; a decis&atilde;o de aderir ao n&atilde;o ao </span>
<span class="c1">PROGRAMA DE AFILIADOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.12. Os </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; s&atilde;o integral e exclusivamente respons&aacute;veis pelos atos ou omiss&otilde;es, assim como dos </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; e </span>
<span class="c1">TERCEIROS RELACIONADOS</span>
<span class="c0">&nbsp;, aqui considerados quaisquer pessoas naturais ou jur&iacute;dicas que estejam direta ou indiretamente relacionadas ao seu </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.12.1. </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;Os </span>
<span class="c1">&nbsp;VENDEDORES </span>
<span class="c0">s&atilde;o integral e exclusivamente respons&aacute;veis pelas a&ccedil;&otilde;es ou omiss&otilde;es relacionadas ao </span>
<span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;e </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;desde j&aacute; isentam a </span>
<span class="c1">ECOM </span>
<span class="c0">de qualquer demanda pr&oacute;pria, dos </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; ou de </span>
<span class="c1">TERCEIROS RELACIONADOS</span>
<span class="c0">&nbsp; ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; transacionado ou ofertado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, ou qualquer demanda de terceiro decorrente de ato do pr&oacute;prio </span>
<span class="c1">VENDEDOR</span><span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">AFILIADOS </span>
<span class="c0">ou qualquer outra pessoa que tenha atuado em nome do </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp; e que tenha causado dano ou preju&iacute;zo a terceiros, assumindo desde j&aacute; todos os &ocirc;nus decorrente de tal demanda.</span>
</p>
<p class="c4">
<span class="c0">6.13. Os </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; s&atilde;o integral respons&aacute;veis por obter e manter eficazes, durante todo o per&iacute;odo de vida do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, os direitos e autoriza&ccedil;&otilde;es necess&aacute;rias para a explora&ccedil;&atilde;o da imagem e dos demais direitos de personalidade dos </span>
<span class="c1">TERCEIROS RELACIONADOS</span>
<span class="c0">&nbsp; para a comercializa&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O na PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.14.O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; pode disponibilizar o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; por meio da </span>
<span class="c1">FUNCIONALIDADE TRIAL</span>
<span class="c0">&nbsp;, em um per&iacute;odo de utiliza&ccedil;&atilde;o n&atilde;o vinculativo, a t&iacute;tulo de teste, pelo </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp; que pode variar de 1 (um) a 30 (trinta) dias, de acordo com a escolha do </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.15. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; determinar&aacute; os requisitos os quais os </span>
<span class="c1">COMPRADORES </span>
<span class="c0">dever&atilde;o obedecer para utilizar a </span>
<span class="c1">FUNCIONALIDADE TRIAL</span>
<span class="c0">&nbsp; como, por exemplo, seu uso e tempo de dura&ccedil;&atilde;o. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; pode limitar o acesso dos </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp; a </span>
<span class="c1">FUNCIONALIDADE TRIAL</span>
<span class="c0">&nbsp; para evitar abusos, devendo assim o fazer em conformidade com o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">6.15.1. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; deve cobrar do Comprador o valor do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ao final do per&iacute;odo cadastrado para o uso da </span>
<span class="c1">FUNCIONALIDADE TRIAL</span>
<span class="c0">&nbsp;, a n&atilde;o ser que o </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp; cancele o uso da </span>
<span class="c1">FUNCIONALIDADE TRIAL</span>
<span class="c0 c2">&nbsp; antes do final desse per&iacute;odo.</span>
</p>
<p class="c4">
<span class="c0">6.15.2. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; pode optar por cadastrar a </span>
<span class="c1">FUNCIONALIDADE TRIAL</span>
<span class="c0">&nbsp; no modelo gratuito ou </span>
<span class="c0 c7">test-drive</span>
<span class="c0">&nbsp;, a seu exclusivo crit&eacute;rio. No modelo gratuito, o </span><span class="c1">COMPRADOR</span>
<span class="c0">&nbsp; n&atilde;o paga nada pelo per&iacute;odo em que usou o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O durante o uso da FUNCIONALIDADE TRIAL. </span>
<span class="c0">No modelo </span>
<span class="c1">&nbsp;</span>
<span class="c0">&nbsp;</span>
<span class="c0 c7">test-drive</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp;, ao decidir continuar com o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; pagar&aacute; pelo per&iacute;odo utilizado durante o uso da </span>
<span class="c1 c2">FUNCIONALIDADE TRIAL.</span>
</p>
<p class="c4">
<span class="c0">6.16. O </span>
<span class="c1">VENDEDOR </span>
<span class="c0">autoriza a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;:</span>
</p>
<p class="c4">
<span class="c0">(a) A efetuar e manter todos os registros das transa&ccedil;&otilde;es efetuadas por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(b) A realizar opera&ccedil;&otilde;es e negocia&ccedil;&otilde;es de receb&iacute;veis oriundos de tais transa&ccedil;&otilde;es, nos sistemas de registro mantidos por entidades registradoras, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel do Banco Central do Brasil, por meio do envio de informa&ccedil;&otilde;es do </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp; e das transa&ccedil;&otilde;es &agrave;s entidades registradoras;</span>
</p>
<p class="c4">
<span class="c0">(c) A realizar consulta, pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; e por terceiros parceiros da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; que atuem no </span>
<span class="c0 c5">contexto da antecipa&ccedil;&atilde;o dos receb&iacute;veis do </span>
<span class="c1 c5">VENDEDOR (caso tal servi&ccedil;o seja disponibilizado pela ECOM)</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">, aos dados de agenda de pagamentos do </span>
<span class="c1 c5">VENDEDOR</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5 c9">&nbsp;junto &agrave;s entidades registradoras, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel.</span>
</p>
<p class="c4">
<span class="c0 c5">(d) Reter saldos da sua CONTA ECOM</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;e dos COPRODUTORES</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;e AFILIADOS</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;em caso de demandas de terceiros contra a ECOM</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;oriundas da comercialia&ccedil;&atilde;o do</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">.</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5 c9">.</span>
</p>
<p class="c3">
<span class="c0 c5 c9"></span>
</p>
<h2 class="c10" id="h.3dy6vkm">
<span class="c1 c2">7. OBRIGA&Ccedil;&Atilde;O DOS COPRODUTORES</span>
</h2>
<p class="c11">
<span class="c0">7.1. Os </span>
<span class="c1">&nbsp;COPRODUTORES </span>
<span class="c0">s&atilde;o integral e solidariamente respons&aacute;veis por a&ccedil;&otilde;es ou omiss&otilde;es relacionadas ao </span>
<span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O,</span>
<span class="c0">&nbsp; isentando, desde j&aacute;, a </span>
<span class="c1">ECOM </span>
<span class="c0">de qualquer demanda pr&oacute;pria, dos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; ou de </span>
<span class="c1">TERCEIROS RELACIONADOS</span>
<span class="c0">&nbsp; ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; transacionado ou ofertado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, ou qualquer demanda de terceiro decorrente de ato do pr&oacute;prio </span>
<span class="c1">COPRODUTOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">AFILIADOS </span>
<span class="c0">ou qualquer outra pessoa que tenha atuado em nome do </span>
<span class="c1">COPRODUTOR</span>
<span class="c0 c2">&nbsp; e que tenha causado dano ou preju&iacute;zo a terceiros, assumindo desde j&aacute; todos os &ocirc;nus decorrente de tal demanda.</span>
</p>
<p class="c4">
<span class="c0 c5">7.2. O COPRODUTOR</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;desde j&aacute; autoriza a ECOM</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5 c9">:</span>
</p>
<p class="c4">
<span class="c0">(a) A efetuar e manter todos os registros das transa&ccedil;&otilde;es efetuadas por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(b) A realizar opera&ccedil;&otilde;es e negocia&ccedil;&otilde;es de receb&iacute;veis oriundos de tais transa&ccedil;&otilde;es, nos sistemas de registro mantidos por entidades registradoras, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel do Banco Central do Brasil, por meio do envio de informa&ccedil;&otilde;es do </span>
<span class="c1">COPRODUTOR</span>
<span class="c0 c2">&nbsp; e das transa&ccedil;&otilde;es &agrave;s entidades registradoras;</span>
</p>
<p class="c4">
<span class="c0">(c) A realizar consulta, pela </span>
<span class="c1">ECOM</span><span class="c0">&nbsp; e por terceiros parceiros da </span><span class="c1">ECOM</span>
<span class="c0">&nbsp; que atuem no </span>
<span class="c0 c5">contexto da antecipa&ccedil;&atilde;o dos receb&iacute;veis do </span>
<span class="c1 c5">COPRODUTOR (caso tal servi&ccedil;o seja disponibilizado pela ECOM)</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">, aos dados de agenda de pagamentos do </span>
<span class="c1 c5">COPRODUTOR</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5 c9">&nbsp;junto &agrave;s entidades registradoras, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel.</span>
</p>
<p class="c4">
<span class="c0 c5">(d) Reter saldos da sua CONTA ECOM</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;e dos VENDEDORES</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;e AFILIADOS</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">&nbsp;em caso de demandas de terceiros contra a ECOM</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5 c9">&nbsp;oriundas da comercialia&ccedil;&atilde;o do PRODUTOS OU SERVI&Ccedil;OS.</span>
</p>
<p class="c3">
<span class="c0 c5 c9"></span>
</p>
<h2 class="c10" id="h.1t3h5sf">
<span class="c1 c2">&nbsp;8. OBRIGA&Ccedil;&Atilde;O DOS COLABORADORES</span>
</h2>
<p class="c11">
<span class="c0">8.1. Os </span>
<span class="c1">&nbsp;COLABORADORES </span>
<span class="c0">s&atilde;o integral e solidariamente respons&aacute;veis pelas a&ccedil;&otilde;es ou omiss&otilde;es relacionadas ao </span>
<span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O, </span>
<span class="c0">isentando, desde j&aacute;, a </span>
<span class="c1">ECOM </span>
<span class="c0">de qualquer demanda pr&oacute;pria, dos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; ou de </span>
<span class="c1">TERCEIROS RELACIONADOS</span>
<span class="c0">&nbsp; ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; transacionado ou ofertado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, ou qualquer demanda de terceiro decorrente de ato do pr&oacute;prio </span>
<span class="c1">COLABORADOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">AFILIADOS </span>
<span class="c0">ou qualquer outra pessoa que tenha atuado em nome do </span>
<span class="c1">COLABORADOR</span>
<span class="c0 c2">&nbsp; e que tenha causado dano ou preju&iacute;zo a terceiros, assumindo desde j&aacute; todos os &ocirc;nus decorrente de tal demanda.</span>
</p>
<p class="c4">
<span class="c1 c2">9. DOS AFILIADOS</span>
</p>
<p class="c4">
<span class="c1 c2">9.1 DAS OBRIGA&Ccedil;&Otilde;ES DOS AFILIADOS</span>
</p>
<p class="c4">
<span class="c0">&nbsp;Os </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; s&atilde;o integral e solidariamente respons&aacute;veis pelas a&ccedil;&otilde;es ou omiss&otilde;es relacionadas ao </span>
<span class="c1">&nbsp;PRODUTO OU SERVI&Ccedil;O,</span>
<span class="c0">&nbsp; isentando, desde j&aacute;, a </span>
<span class="c1">ECOM </span>
<span class="c0">de qualquer demanda pr&oacute;pria, dos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">COLABORADORES</span>
<span class="c0">&nbsp; ou de </span>
<span class="c1">TERCEIROS RELACIONADOS</span>
<span class="c0">&nbsp; ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; transacionado ou ofertado na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, ou qualquer demanda de terceiro decorrente de ato do pr&oacute;prio </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">AFILIADOS </span>
<span class="c0">ou qualquer outra pessoa que tenha atuado em nome do </span>
<span class="c1">AFILIADO</span>
<span class="c0 c2">&nbsp; e que tenha causado dano ou preju&iacute;zo a terceiros, assumindo desde j&aacute; todos os &ocirc;nus decorrente de tal demanda.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span>
</p>
<p class="c4">
<span class="c1 c2">9.2 DA RESPONSABILIDADE PELOS AFILIADOS</span>
</p>
<p class="c4">
<span class="c0">&nbsp;O VENDEDOR &eacute; integralmente respons&aacute;vel pela atua&ccedil;&atilde;o dos </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; por ocasi&atilde;o da promo&ccedil;&atilde;o das vendas de seu </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Se qualquer </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; adotar condutas inadequadas, ou praticar a&ccedil;&otilde;es ou omiss&otilde;es contr&aacute;rias a </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; ou &agrave;s Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode aplicar quaisquer das medidas previstas na Se&ccedil;&atilde;o 10 do </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, de maneira isolada ou cumulativa, em desfavor do </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp;, do </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, do seu </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou de todos. S&atilde;o consideradas condutas inadequadas dos </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp;, entre outras, os seguintes exemplos: (a) a mudan&ccedil;a da narrativa de vendas do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (b) a presta&ccedil;&atilde;o de informa&ccedil;&otilde;es falsas, incompletas ou imprecisas sobre quaisquer caracter&iacute;sticas do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (c) a pr&aacute;tica de spam ou qualquer pr&aacute;tica que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; entender nociva ao mercado de afilia&ccedil;&atilde;o ou ao </span>
<span class="c1">PROGRAMA DE</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">AFILIADOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c3">
<span class="c0 c2"></span>
</p>
<p class="c4">
<span class="c1 c2">9.3 DO REPASSE AOS AFILIADOS:</span>
</p>
<p class="c4">
<span class="c0">Qualquer repasse de remunera&ccedil;&atilde;o aos </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; deve se dar exclusivamente por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; n&atilde;o pode criar outros mecanismos de pagamento de comiss&atilde;o, compensa&ccedil;&atilde;o, bonifica&ccedil;&atilde;o, pagamento direto ou remunera&ccedil;&atilde;o a qualquer t&iacute;tulo realizados fora da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; tamb&eacute;m n&atilde;o pode exigir como requisitos para aceitar candidatos ou mant&ecirc;-los como </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; que (a) atinjam metas, (b) comprem previamente qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou (c) paguem qualquer valor ao </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c3">
<span class="c0 c2"></span>
</p>
<p class="c4">
<span class="c1 c2">9.4 DA COMUNICA&Ccedil;&Atilde;O AO AFILIADO</span>
</p>
<p class="c4">
<span class="c0">O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; deve comunicar ao </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; ou &agrave; sua rede de </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp;, com anteced&ecirc;ncia m&iacute;nima de 03 (tr&ecirc;s), 30 (trinta), 60 (sessenta) ou 90 (noventa) dias, conforme sua escolha, todas as condi&ccedil;&otilde;es de afilia&ccedil;&atilde;o e qualquer mudan&ccedil;a que fizer sobre as regras aplic&aacute;veis &agrave; afilia&ccedil;&atilde;o como, por exemplo, a sua interrup&ccedil;&atilde;o ou mudan&ccedil;as nos percentuais de comiss&atilde;o, salvo se ficar demonstrado o descumprimento, pelo 
</span>
<span class="c1">AFILIADO</span>
<span class="c0 c2">&nbsp;, das regras previamente estabelecidas.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span>
</p>
<p class="c4">
<span class="c1 c2">9.5 DAS REGRAS DE AFILIA&Ccedil;&Atilde;O</span>
</p>
<p class="c4">
<span class="c0">a)Ao aderir ao PROGRAMA DE </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; deve observar as determina&ccedil;&otilde;es m&iacute;nimas estabelecidas n </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp;, sendo de atribui&ccedil;&atilde;o exclusiva do </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;: (a) determinar o percentual devido ao </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; pela venda de cada </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (b) estabelecer os crit&eacute;rios de participa&ccedil;&atilde;o do </span>
<span class="c1">PROGRAMA DE AFILIADOS</span>
<span class="c0">&nbsp; para seus </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (c) aceitar ou recusar os candidatos inscritos, conforme crit&eacute;rios previamente estabelecidos; (d) definir as formas e limita&ccedil;&otilde;es de divulga&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; e (d) fazer a gest&atilde;o de sua rede de </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se reserva o direito de limitar o n&uacute;mero de </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; de determinado </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, bem como o direito de acrescentar deveres e obriga&ccedil;&otilde;es aos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; adicionais aos previstos n </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp;, a qualquer tempo, com ou sem aviso pr&eacute;vio. Na hip&oacute;tese de conflito entre as regras estabelecidas pelos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; e as condi&ccedil;&otilde;es d </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0 c2">&nbsp;, estes &uacute;ltimos devem sempre prevalecer.</span>
</p>
<p class="c4">
<span class="c0">b) </span>
<span class="c1">Processo de Afilia&ccedil;&atilde;o</span>
<span class="c0">&nbsp;: O </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; deve apresentar candidatura para afilia&ccedil;&atilde;o a determinado </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; espec&iacute;fico. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; pode aceit&aacute;-la ou recus&aacute;-la com base em seus crit&eacute;rios previamente definidos, salvo nos casos em que a afilia&ccedil;&atilde;o n&atilde;o esteja condicionada &agrave; pr&eacute;via aprova&ccedil;&atilde;o. Ao submeter a sua candidatura, o </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; concorda com todas as regras estabelecidas pelo </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, inclusive com rela&ccedil;&atilde;o aos valores e &agrave;s regras de divis&atilde;o da comiss&atilde;o pela venda do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, que s&atilde;o determinadas exclusivamente pelo </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, sem a participa&ccedil;&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. O </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; deve levar em conta todas as condi&ccedil;&otilde;es, gerais e espec&iacute;ficas, da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; e do </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, antes de decidir se engajar na promo&ccedil;&atilde;o de um determinado </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">c) </span>
<span class="c1">Condi&ccedil;&atilde;o de AFILIADO:</span>
<span class="c0">&nbsp; A afilia&ccedil;&atilde;o a um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; n&atilde;o estabelece qualquer rela&ccedil;&atilde;o entre o </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; e a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; al&eacute;m daquela de </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;. O </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; n&atilde;o pode praticar qualquer a&ccedil;&atilde;o ou omiss&atilde;o que, direta ou indiretamente, o identifique com a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; ou possa sugerir v&iacute;nculo de qualquer outra natureza com a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, seja pelo uso indevido da marca ou de qualquer outra propriedade intelectual da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, seja pela descri&ccedil;&atilde;o de cargos ou rela&ccedil;&atilde;o profissional, ou por qualquer outro meio. Por exemplo, o </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; n&atilde;o pode criar perfis em redes sociais, de cunho pessoal ou profissional, se apresentando como &ldquo;vendedor da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;&rdquo; ou &ldquo;agente da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;&rdquo;.</span>
</p>
<p class="c4">
<span class="c0">d) </span>
<span class="c1">Divulga&ccedil;&atilde;o e Comercializa&ccedil;&atilde;o dos PRODUTO OU SERVI&Ccedil;OS:</span>
<span class="c0">&nbsp; O </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; &eacute; respons&aacute;vel por reproduzir ao p&uacute;blico para o qual divulgar o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; todas as caracter&iacute;sticas do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; e as condi&ccedil;&otilde;es de venda inseridas pelo </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">. O </span> 
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; assume perante o </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp; responsabilidade solid&aacute;ria com o </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp; pelo cumprimento integral das condi&ccedil;&otilde;es de venda.</span>
</p>
<p class="c4">
<span class="c0">e) </span>
<span class="c1">Veda&ccedil;&otilde;es ao Afiliado:</span>
<span class="c0">&nbsp; O </span>
<span class="c1">AFILIADO</span>
<span class="c0 c2">&nbsp; n&atilde;o pode criar ou praticar, direta ou indiretamente, qualquer forma de revenda ou sub-afilia&ccedil;&atilde;o, de marketing multin&iacute;vel, ou din&acirc;micas similares.</span>
</p>
<p class="c4">
<span class="c0 c5 c9">7.2. O AFILIADO desde j&aacute; autoriza a ECOM:</span>
</p>
<p class="c4">
<span class="c0">(a) A efetuar e manter todos os registros das transa&ccedil;&otilde;es efetuadas por meio da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;;</span>
</p>
<p class="c4">
<span class="c0">(b) A realizar opera&ccedil;&otilde;es e negocia&ccedil;&otilde;es de receb&iacute;veis oriundos de tais transa&ccedil;&otilde;es, nos sistemas de registro mantidos por entidades registradoras, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel do Banco Central do Brasil, por meio do envio de informa&ccedil;&otilde;es do </span>
<span class="c1">AFILIADO</span>
<span class="c0 c2">&nbsp; e das transa&ccedil;&otilde;es &agrave;s entidades registradoras;</span>
</p>
<p class="c4">
<span class="c0">(c) A realizar consulta, pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; e por terceiros parceiros da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; que atuem no </span>
<span class="c0 c5">contexto da antecipa&ccedil;&atilde;o dos receb&iacute;veis do </span>
<span class="c1 c5">AFILIADO (caso tal servi&ccedil;o seja disponibilizado pela ECOM)</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5">, aos dados de agenda de pagamentos do </span>
<span class="c1 c5">COPRODUTOR</span>
<span class="c0">&nbsp;</span>
<span class="c0 c5 c9">&nbsp;junto &agrave;s entidades registradoras, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel.</span>
</p>
<p class="c4">
<span class="c0 c5 c9">(d) Reter saldos da sua CONTA ECOM e dos VENDEDORES e AFILIADOS em caso de demandas de terceiros contra a ECOM oriundas da comercializa&ccedil;&atilde;o do PRODUTOS OU SERVI&Ccedil;OS.</span>
</p>
<p class="c3">
<span class="c0 c5 c9"></span>
</p>
<h2 class="c10" id="h.4d34og8">
<span class="c1 c2">10. RESOLU&Ccedil;&Atilde;O DE CONFLITOS</span>
</h2>
<p class="c11">
<span class="c0">10.1. Os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; se comprometem a n&atilde;o envolver direta ou indiretamente a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; nos contratos, acordos, negocia&ccedil;&otilde;es, existentes entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; e nem na solu&ccedil;&atilde;o de diverg&ecirc;ncias eventualmente existentes entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; decorrentes do uso dos </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM.</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o se envolve e nem interv&eacute;m na resolu&ccedil;&atilde;o de qualquer conflito envolvendo os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c11 c13">
<span class="c0 c2"></span>
</p>
<p class="c4">
<span class="c1 c2">11. A EXPERI&Ecirc;NCIA DE COMPRA DO COMPRADOR</span>
</p>
<p class="c4">
<span class="c0">11.1. </span>
<span class="c1">Responsabilidade pela Experi&ecirc;ncia do COMPRADOR: </span>
<span class="c0">A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; acredita que aprender e ensinar s&atilde;o atividades que podem e devem ser democratizadas. Por isso, os </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; podem ser utilizados por qualquer </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; tamb&eacute;m preza pela autonomia das pessoas, acreditando que seus </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; devem ter o poder de decis&atilde;o sobre quais conte&uacute;dos desejam adquirir. Por isso, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o se obriga a fazer controle pr&eacute;vio (editorial ou de qualquer outra natureza) ou curadoria de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode fazer verifica&ccedil;&otilde;es sobre o cadastro dos </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp;, mas n&atilde;o &eacute; capaz de determinar se os </span>
<span class="c1">PRODUTOS OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; observam todas as determina&ccedil;&otilde;es legais, se s&atilde;o adequados para os fins a que se prop&otilde;em, nem &eacute; capaz de determinar a veracidade, a exatid&atilde;o e a completude das informa&ccedil;&otilde;es prestadas pelos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;. Ao adquirir algum </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, o Comprador deve verificar a adequa&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; e do </span><span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; aos fins buscados por ocasi&atilde;o da compra, bem como a veracidade das demais informa&ccedil;&otilde;es e caracter&iacute;sticas prestadas em rela&ccedil;&atilde;o ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Al&eacute;m disso, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; assume voluntariamente todo e qualquer risco associado &agrave; aquisi&ccedil;&atilde;o do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Isso inclui o risco de ser exposto a assunto que considere ofensivo, indecente ou censur&aacute;vel, ou de que o conhecimento veiculado possa resultar em efeitos danosos ou adversos, f&iacute;sicos ou mentais, pelos quais o </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp; assume total responsabilidade.</span>
</p>
<p class="c4">
<span class="c0">11.2. </span>
<span class="c1">Dilig&ecirc;ncia sobre as Informa&ccedil;&otilde;es Prestadas:</span>
<span class="c0">&nbsp; O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; &eacute; integralmente respons&aacute;vel por todas as informa&ccedil;&otilde;es que prestar e pelas declara&ccedil;&otilde;es que fizer aos </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; ou a qualquer outra pessoa por ocasi&atilde;o do uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, em raz&atilde;o da compra ou em decorr&ecirc;ncia do uso de qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span>
</p>
<p class="c4">
<span class="c1 c2">12. A REMUNERA&Ccedil;&Atilde;O DA ECOM</span>
</p>
<p class="c4">
<span class="c0">12.1. </span>
<span class="c1">Taxas do VENDEDOR:</span>
<span class="c0">&nbsp; O uso pelo </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; dos recursos que a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">disponibiliza est&aacute; sujeito ao pagamento das Taxas de Licen&ccedil;a e de Intermedia&ccedil;&atilde;o, que s&atilde;o cobradas por ocasi&atilde;o da venda de cada </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, no momento em que a transa&ccedil;&atilde;o &eacute; aprovada, e deduzidas do valor final cobrado pelo </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; de cada </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp;. Essas taxas, seus respectivos meios de pagamento, faturamento e tipo de pagamento est&atilde;o estabelecidos na </span>
<span class="c1">POL&Iacute;TICA DE PAGAMENTOS</span>
<span class="c0">&nbsp;, e podem ser alteradas a qualquer tempo, a crit&eacute;rio da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, mediante notifica&ccedil;&atilde;o pr&eacute;via.</span>
</p>
<p class="c4">
<span class="c0">12.2. </span>
<span class="c1">Taxas do AFILIADO, COPRODUTORES e COMPRADOR:</span>
<span class="c0">&nbsp; Os </span>
<span class="c1">COMPRADORES, COPRODUTORES</span>
<span class="c0">&nbsp; e </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; podem acessar e usar os </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; gratuitamente. No entanto, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode cobrar valores decorrentes de opera&ccedil;&otilde;es financeiras realizadas por esses </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;, como a antecipa&ccedil;&atilde;o de receb&iacute;veis solicitada (se tal servi&ccedil;o for disponibilizado pela ECOM ), outras taxas financeiras, como por saque, transfer&ecirc;ncia dos valores da Conta da ECOM &nbsp;para outra institui&ccedil;&atilde;o financeira de sua titularidade e domic&iacute;lio p or </span>
<span class="c1">AFILIADO ou</span>
<span class="c0">&nbsp;</span>
<span class="c1">&nbsp;COPRODUTOR, </span>
<span class="c0">ou o parcelamento ou uso de diferentes meios de pagamento por </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp;, conforme estabelecido na </span>
<span class="c1">POL&Iacute;TICA DE PAGAMENTOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c3">
<span class="c0 c2"></span>
</p>
<p class="c4">
<span class="c1 c2">13. LIMITES DE RESPONSABILIDADE DA ECOM</span>
</p>
<p class="c4">
<span class="c0">13.1. </span>
<span class="c1">Limites da Responsabilidade da ECOM:</span>
<span class="c0">&nbsp; Lembramos que, ao usar a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;como </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COPRODUTOR,</span>
<span class="c0">&nbsp; &nbsp;</span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; OU </span>
<span class="c1">COMPRADOR</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; &eacute; um contratante dos </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">de intermedia&ccedil;&atilde;o prestados pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o cria, elabora, controla, endossa ou fornece qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; para o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;. Os </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp; s&atilde;o os respons&aacute;veis pelo conte&uacute;do e pelas informa&ccedil;&otilde;es relativas aos seus </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;. Ao aceitar </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; e usar a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">, </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; o faz voluntariamente, por sua conta e risco. Por isso, excetuadas as hip&oacute;teses de responsabilidade determinadas por lei, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o responde por danos de qualquer natureza decorrentes do acesso ou uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, sejam patrimoniais ou extrapatrimoniais, diretos ou indiretos, preju&iacute;zos efetivos ou lucros cessantes. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o responde, a t&iacute;tulo exemplificativo e n&atilde;o exaustivo, por: (a) danos decorrentes da inadequa&ccedil;&atilde;o dos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; aos fins a que se destinam; (b) danos decorrentes de defeitos ou v&iacute;cios de qualidade ou quantidade dos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (c) danos decorrentes de riscos relativos ao uso de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, inclusive potencial nocividade ou periculosidade; (d) reclama&ccedil;&otilde;es decorrentes de insatisfa&ccedil;&otilde;es pela baixa qualidade dos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;s; (e) danos decorrentes de erro, incompletude, inverdade ou inadequa&ccedil;&atilde;o de informa&ccedil;&otilde;es prestadas na divulga&ccedil;&atilde;o de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; mediante recursos disponibilizados pela </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;; (f) danos decorrentes do descumprimento, pelos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;, dos seus deveres legais ou contratuais perante outros </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;; (g) danos decorrentes de erro, incompletude, inverdade ou inadequa&ccedil;&atilde;o de informa&ccedil;&otilde;es prestadas durante o processamento de transa&ccedil;&otilde;es de pagamento; (h) danos decorrentes dos 
</span>
<span class="c1">SERVI&Ccedil;OS DE TERCEIROS</span>
<span class="c0">&nbsp;; (i) danos resultantes dos </span>
<span class="c1">SERVI&Ccedil;OS DE TERCEIROS</span>
<span class="c0">&nbsp; no processamento de convers&atilde;o e saque de moeda; (j) danos decorrentes do uso indevido da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; por terceiros, sejam </span>
<span class="c1">VENDEDORES</span>
<span class="c0">&nbsp;, </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp; ou </span><span class="c1">COMPRADORES,</span>
<span class="c0">&nbsp; a que t&iacute;tulo for, especialmente em caso de divulga&ccedil;&atilde;o em outras </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">de </span><span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;S previamente adquiridos; (k) danos decorrentes dos materiais, informa&ccedil;&otilde;es ou conte&uacute;dos publicados em </span>
<span class="c0 c7">sites</span>
<span class="c0">&nbsp; de terceiros, tampouco pela disponibilidade destes </span>
<span class="c0 c7">sites</span>
<span class="c0">&nbsp;, competindo ao </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; usar seu pr&oacute;prio discernimento, precau&ccedil;&atilde;o e senso comum ao acessar </span>
<span class="c0 c7">sites</span>
<span class="c0">&nbsp; de terceiros, devendo conferir as respectivas pol&iacute;ticas de privacidade e os termos de uso aplic&aacute;veis; (l) danos decorrentes de dificuldades t&eacute;cnicas ou falhas nos sistemas, servidores ou na internet; ou (m) danos decorrentes de ataques de v&iacute;rus ao equipamento do </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; ocorridos durante o uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0 c2">&nbsp;, ou como consequ&ecirc;ncia da transfer&ecirc;ncia de dados, arquivos, imagens, textos ou &aacute;udio.</span>
</p>
<p class="c4">
<span class="c0">13.2. </span>
<span class="c1">Exclus&atilde;o da ECOM do Polo Passivo de Eventual Disputa:</span>
<span class="c0">&nbsp; Ao aceitar o </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, voc&ecirc; desde j&aacute; concorda com a exclus&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; do polo passivo de qualquer tipo de reclama&ccedil;&atilde;o ou processo, judicial ou extrajudicial, que voc&ecirc; iniciar contra outros </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; ou terceiros a respeito do uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. Essa exclus&atilde;o tamb&eacute;m se aplica em favor dos s&oacute;cios da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, dos seus controladores, das suas sociedades controladas ou coligadas, dos seus administradores, diretores, gerentes, empregados, agentes, colaboradores, representantes e procuradores.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span></p><p class="c4">
<span class="c1 c2">14. INFRA&Ccedil;&Otilde;ES AOS TERMOS E &Agrave;S POL&Iacute;TICAS DA ECOM</span>
</p>
<p class="c4">
<span class="c0">14.1. </span>
<span class="c1">Infra&ccedil;&otilde;es aos Termos:</span>
<span class="c0">&nbsp; Se voc&ecirc; deixar de observar qualquer condi&ccedil;&atilde;o do </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp; ou de qualquer Pol&iacute;tica da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, total ou parcialmente, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode aplicar diferentes medidas, a seu exclusivo crit&eacute;rio, de forma isolada ou cumulativa, com ou sem pr&eacute;vio aviso, a qualquer tempo e pelo per&iacute;odo que lhe aprouver. Entre essas medidas, sem preju&iacute;zo de outras n&atilde;o previstas no </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span><span class="c0">&nbsp; pode aplicar: (a) advert&ecirc;ncia; (b) limita&ccedil;&atilde;o, remo&ccedil;&atilde;o ou encerramento de acesso ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (c) retirada ou diminui&ccedil;&atilde;o de visibilidade do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; do </span>
<span class="c0 c7">marketplace</span>
<span class="c0">&nbsp;, </span>
<span class="c0 c7">skin</span>
<span class="c0">&nbsp; da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; ou qualquer outro meio de divulga&ccedil;&atilde;o; (d) limita&ccedil;&atilde;o de acesso, suspens&atilde;o ou desligamento do </span>
<span class="c1">PROGRAMA DE AFILIADOS</span>
<span class="c0">&nbsp;; (e) limita&ccedil;&atilde;o de acesso, suspens&atilde;o ou encerramento de qualquer benef&iacute;cio ou categoria especial oferecida pela </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; relacionada ao </span>
<span class="c1">VENDEDOR,</span>
<span class="c0">&nbsp; &agrave; sua conta, ou a determinado </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (f) rebaixamento de categoria de </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;; (g) limita&ccedil;&atilde;o, suspens&atilde;o ou encerramento de acesso a alguma funcionalidade especial da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;; (h) remo&ccedil;&atilde;o da p&aacute;gina do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;; (i) limita&ccedil;&atilde;o de acesso, suspens&atilde;o, bloqueio ou remo&ccedil;&atilde;o de </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, conta ou </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0 c2">&nbsp;; ou (j) encerramento deste Contrato.</span>
</p>
<p class="c4">
<span class="c0">14.2. </span>
<span class="c1">Medidas Preventivas:</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode, a seu crit&eacute;rio, adotar medidas preventivas para averiguar poss&iacute;veis infra&ccedil;&otilde;es aos Termos ou &agrave;s Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, ou se entender ser necess&aacute;rio para proteger interesses pr&oacute;prios ou de terceiros. Essas medidas podem durar o tempo necess&aacute;rio para que eventual corre&ccedil;&atilde;o ou verifica&ccedil;&atilde;o seja feita, ou at&eacute; a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; decidir pelo encerramento ou n&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, ou pela aplica&ccedil;&atilde;o de outras medidas. As medidas preventivas podem resultar na suspens&atilde;o tempor&aacute;ria dos </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a determinado </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;. Nessa hip&oacute;tese, durante todo o per&iacute;odo de suspens&atilde;o, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode alterar ou retirar o acesso total ou parcial de algumas funcionalidades da conta do </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;, dentre as quais, a t&iacute;tulo exemplificativo: (a) a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode suspender a remessa de valores transacionados oriundos de vendas realizadas mediante a </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; ou impedir o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; de realizar qualquer resgate de valores acumulados na sua conta, conforme a Pol&iacute;tica de Pagamento da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;; ou ainda, (b) a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode impedir o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; de alterar dados cadastrais, dados banc&aacute;rios e os </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; cadastrados. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; tamb&eacute;m pode cancelar </span>
<span class="c0 c7">links </span>
<span class="c0">de vendas e ofertas ativas em caso de suspens&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Nessas hip&oacute;teses, o </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; n&atilde;o tem direito a indeniza&ccedil;&atilde;o de quaisquer danos, patrimoniais ou extrapatrimoniais, decorrentes das medidas preventivas aplicadas pela </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">14.3. </span>
<span class="c1">Outras Medidas Judiciais ou Extrajudiciais:</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; pode adotar quaisquer outras medidas, judiciais ou extrajudiciais, que entender cab&iacute;veis, em nome pr&oacute;prio ou mediante terceiros legitimados, sem preju&iacute;zo da ado&ccedil;&atilde;o isolada ou cumulativa das outras mencionadas nos itens anteriores.</span>
</p>
<p class="c3">
<span class="c0 c2"></span>
</p>
<p class="c4">
<span class="c1 c2">15. SUSPENS&Atilde;O OU ENCERRAMENTO DOS SERVI&Ccedil;OS DA ECOM</span>
</p>
<p class="c4">
<span class="c0">15.1. </span>
<span class="c1">Suspens&atilde;o ou Encerramento dos SERVI&Ccedil;OS da ECOM:</span>
<span class="c0">&nbsp; Os valores e princ&iacute;pios da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; s&atilde;o amparados no respeito ao pr&oacute;ximo e &agrave; liberdade, e estamos sempre empenhados em facilitar o empreendedorismo, o conhecimento e a educa&ccedil;&atilde;o para que mais pessoas atinjam desenvolvimento pessoal e profissional. Por isso, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; se reserva o direito de tomar diversas a&ccedil;&otilde;es destinadas a proteger a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;ou nossa comunidade, mediante requerimento justificado de terceiro ou por iniciativa pr&oacute;pria. Essas medidas podem incluir suspender ou interromper a presta&ccedil;&atilde;o de servi&ccedil;os, encerrar a licen&ccedil;a de uso concedida a </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;, tornar </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; indispon&iacute;vel, remov&ecirc;-lo da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, bem como quaisquer outras medidas previstas ou n&atilde;o neste </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode decidir tomar essas e outras medidas a qualquer tempo, com ou sem aviso pr&eacute;vio, de modo isolado ou cumulativo. Qualquer decis&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a esse respeito ocorrer&aacute; a seu exclusivo crit&eacute;rio, por motivos que podem incluir: (a) a inobserv&acirc;ncia d </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; ou das Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;; (b) a pr&aacute;tica de ato que contrarie de alguma maneira os valores ou princ&iacute;pios da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;; (c) qualquer conduta, praticada dentro ou fora da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, que possa impactar negativamente a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, ou que possa levar a empresa ou seus neg&oacute;cios a descr&eacute;dito p&uacute;blico, desonra, esc&acirc;ndalo ou rid&iacute;culo. O fato de qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; ter sido habilitado anteriormente durante o processo de verifica&ccedil;&atilde;o cadastral n&atilde;o impede a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; de tomar as medidas que julgar adequadas, quando entender cab&iacute;vel. Ao aceitar o </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;, voc&ecirc; reconhece que n&atilde;o tem direito a indeniza&ccedil;&atilde;o de qualquer dano, patrimonial ou extrapatrimonial, se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; adotar qualquer medida baseada em lei, n </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; ou nas Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, inclusive se voc&ecirc; tiver seu cadastro ou qualquer </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; recusado, se tiver o acesso &agrave; sua conta suspenso ou interrompido, se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; encerrar a licen&ccedil;a conferida a voc&ecirc; para uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, ou se voc&ecirc; tiver um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; removido da </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0 c2">ou tornado indispon&iacute;vel.</span>
</p>
<p class="c4">
<span class="c0">15.2. </span>
<span class="c1">Encerramento Imotivado: </span>
<span class="c0">Ao aceitar este Contrato, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; lhe concede automaticamente uma licen&ccedil;a de uso n&atilde;o exclusiva da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, por prazo indeterminado. Desse modo, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode encerrar este Contrato e a respectiva licen&ccedil;a de uso a qualquer momento, a crit&eacute;rio da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, mediante o envio de notifica&ccedil;&atilde;o com 30 (trinta) dias de anteced&ecirc;ncia para o endere&ccedil;o de email que voc&ecirc; registrar na sua conta.</span>
</p>
<p class="c4">
<span class="c0">15.3. </span>
<span class="c1">Encerramento Motivado:</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode encerrar este Contrato imediatamente, sem aviso pr&eacute;vio: (a) se voc&ecirc; violar </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; ou quaisquer condi&ccedil;&otilde;es das Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;; (b) para prevenir fraudes; (c) para impedir a ocorr&ecirc;ncia ou o agravamento de danos a si ou a terceiros; ou (d) em raz&atilde;o de estrito cumprimento de dever legal, por decis&atilde;o judicial ou determina&ccedil;&atilde;o de &oacute;rg&atilde;o governamental ou ag&ecirc;ncia regulat&oacute;ria.</span>
</p>
<p class="c4">
<span class="c0">15.3.1. </span>
<span class="c1">Encerramento Imediato por Condutas Inadequadas:</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; leva a integridade da comunidade que usa a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">&nbsp;muito a s&eacute;rio. Assim, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode aplicar quaisquer das medidas previstas ou n&atilde;o neste </span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;, ou, se entender necess&aacute;rio, pode inclusive encerrar este Contrato imediatamente, sem aviso pr&eacute;vio, (a) se voc&ecirc; receber repetidamente avalia&ccedil;&otilde;es ou coment&aacute;rios negativos; (b) se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; tomar ci&ecirc;ncia de reclama&ccedil;&otilde;es sobre conduta inapropriada sua praticada de maneira reiterada ou contumaz; (c) se o seu </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou se voc&ecirc;, como </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, receber um volume de reclama&ccedil;&otilde;es nos canais de atendimento, de solicita&ccedil;&otilde;es de cancelamento ou de estorno que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; entender incompat&iacute;vel com a qualidade que se espera de </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;s cadastrados na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;; (d) se voc&ecirc;, como </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp;, deixar de prestar suporte aos Compradores; (e) se voc&ecirc; se recusar a corrigir, incluir ou excluir informa&ccedil;&atilde;o relevante sobre o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou sobre voc&ecirc; mesmo na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;; (f) se os &iacute;ndices de </span>
<span class="c0 c7">chargebacks</span>
<span class="c0">&nbsp;, reclama&ccedil;&otilde;es e contesta&ccedil;&otilde;es referentes &agrave;s transa&ccedil;&otilde;es de pagamento da sua conta forem acima de 1%; (g) se voc&ecirc; fornecer informa&ccedil;&otilde;es incompletas, desatualizadas, incorretas, fraudulentas ou imprecisas em seu processo de cadastro, sobre o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; ou para receber atendimento pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;; (h) se voc&ecirc; fizer algo nocivo e a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; entender que o encerramento do Contrato seja medida necess&aacute;ria, em bases razo&aacute;veis, para proteger a sa&uacute;de, a seguran&ccedil;a, a reputa&ccedil;&atilde;o, a honra e os direitos da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, de seus empregados, colaboradores, investidores, s&oacute;cios, administradores, dos seus </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp; ou de terceiros afetados por a&ccedil;&atilde;o ou omiss&atilde;o que voc&ecirc; praticar.</span>
</p>
<p class="c4">
<span class="c0">15.4. </span>
<span class="c1">Efeitos do Encerramento:</span>
<span class="c0">&nbsp; Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; encerrar este Contrato, interrompendo a presta&ccedil;&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, em qualquer modalidade e por qualquer raz&atilde;o, voc&ecirc; deve observar as condi&ccedil;&otilde;es a seguir:</span>
</p>
<p class="c4">
<span class="c0">15.4.1. </span>
<span class="c1">Acesso &agrave; Conta ECOM:</span>
<span class="c0">&nbsp; Se este Contrato for encerrado, voc&ecirc; n&atilde;o ter&aacute; direito a uma restaura&ccedil;&atilde;o da sua conta na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">, nem &agrave; manuten&ccedil;&atilde;o dos seus </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;s na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. Voc&ecirc; tamb&eacute;m n&atilde;o poder&aacute; registrar uma nova conta na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, nem acessar ou usar a </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp; atrav&eacute;s da conta de outro </span>
<span class="c1">USU&Aacute;RIO</span><span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">15.4.2. </span>
<span class="c1">Fornecimento de Dados:</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o &eacute; obrigada a fornecer aos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; dados adicionais aos quais eles j&aacute; tenham acesso em decorr&ecirc;ncia do uso regular da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, em observ&acirc;ncia da Pol&iacute;tica de Privacidade. O </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;, </span>
<span class="c1">COLABORADOR </span>
<span class="c0">ou </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; deve observar todas as regras da Pol&iacute;tica de Privacidade a respeito da exclus&atilde;o de dados pessoais dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp; sob sua responsabilidade em caso de encerramento deste Contrato.</span>
</p>
<p class="c4">
<span class="c0">15.4.3. </span>
<span class="c1">Produto ou Servi&ccedil;os de Assinatura:</span>
<span class="c0">&nbsp; A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode, a seu crit&eacute;rio e pelo tempo que lhe aprouver, continuar processando os pagamentos para os </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; de assinatura, que s&atilde;o os </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;OS</span>
<span class="c0">&nbsp; em que o pagamento &eacute; feito de forma peri&oacute;dica e sucessiva por meio de d&eacute;bitos na fatura de cart&atilde;o de cr&eacute;dito do Comprador. Em caso de migra&ccedil;&atilde;o dos Compradores de um 
</span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; para outra modalidade de pagamento, ou para outra </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, o </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; deve providenciar essa migra&ccedil;&atilde;o, dentro do prazo que lhe for conferido, com os dados dos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; aos quais tiver acesso por ocasi&atilde;o do uso da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; n&atilde;o fornecer&aacute; qualquer dado adicional &agrave;queles j&aacute; disponibilizados ao </span>
<span class="c1">VENDEDOR</span>
<span class="c0 c2">&nbsp; para que a migra&ccedil;&atilde;o seja feita.</span>
</p>
<p class="c4">
<span class="c0">15.4.4. </span>
<span class="c1">Resgate de Saldo da Conta ECOM: </span>
<span class="c0">Se houver saldo na conta do </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0 c2">&nbsp; no momento do encerramento do Contrato, os valores devem ser transferidos para a conta banc&aacute;ria indicada no cadastro em at&eacute; 30 (trinta) dias, ap&oacute;s a solicita&ccedil;&atilde;o de transfer&ecirc;ncia, devendo ser respeitados as regras, prazos e descontados os valores referentes as taxas aplic&aacute;veis.</span>
</p>
<p class="c4">
<span class="c0">15.4.5. </span>
<span class="c1">Reembolso a COMPRADORES Afetados:</span>
<span class="c0">&nbsp; Se o encerramento do Contrato de um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; afetar o acesso de </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp; a um determinado </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; somente se obriga a reembolsar os </span>
<span class="c1">COMPRADORES</span>
<span class="c0">&nbsp; at&eacute; o limite do saldo existente na conta de pagamento do </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp;</span>
<span class="c0">
<a class="c8" href="https://www.google.com/url?q=https://ecompay.app/%23cmnt1&amp;sa=D&amp;source=editors&amp;ust=1724090706087674&amp;usg=AOvVaw3Uu0T9iMhuOFqAgTIMEJAL">[a]</a>
</span>
<span class="c0 c2">&nbsp; em linha com o previsto na POL&Iacute;TICA DE PAGAMENTO. A Diferen&ccedil;a eventualmente existente dever&aacute; ser cobrada diretamente pelo COMPRADOR ao VENDEDOR, sem nenhum tipo de envolvimento da ECOM.</span>
</p>
<p class="c4">
<span class="c0">15.4.6. </span>
<span class="c1">Reten&ccedil;&atilde;o de Valores pela ECOM:</span>
<span class="c0">&nbsp; Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; encerrar o Contrato, por qualquer motivo, com um </span>
<span class="c1">VENDEDOR, COPRODUTOR</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp;, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode reter um montante do saldo do </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; ou </span>
<span class="c1">AFILIADO</span>
<span class="c0">&nbsp; na </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">por per&iacute;odo de at&eacute; 180 (cento e oitenta) dias contados a partir da &uacute;ltima transa&ccedil;&atilde;o realizada. Essa reten&ccedil;&atilde;o deve ter como finalidade assegurar os direitos de regresso e compensa&ccedil;&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; por quaisquer despesas que ela suportar e que sejam de responsabilidade dos </span>
<span class="c1">VENDEDORES </span>
<span class="c0">ou </span>
<span class="c1">AFILIADOS</span>
<span class="c0">&nbsp;. Ap&oacute;s esse per&iacute;odo, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; deve liberar o saldo na conta banc&aacute;ria de cadastro do </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp;ou</span>
<span class="c1">AFILIADO</span><span class="c0 c2">.</span>
</p>
<p class="c4">
<span class="c0">15.4.7. </span>
<span class="c1">Suporte aos USU&Aacute;RIOS ap&oacute;s o Encerramento:</span>
<span class="c0">&nbsp; O suporte ao </span>
<span class="c1">VENDEDOR</span>
<span class="c0">&nbsp; e o respectivo acesso ao gerente de conta, caso existente, ser&atilde;o automaticamente encerrados com o encerramento do Contrato. Havendo necessidade, os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; interessados devem endere&ccedil;ar quaisquer d&uacute;vidas ou problemas remanescentes ou decorrentes do encerramento ao servi&ccedil;o de suporte ao cliente da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">15.4.8. </span>
<span class="c1">Acordos Espec&iacute;ficos com a ECOM:</span>
<span class="c0">&nbsp; Se voc&ecirc; tiver este Contrato encerrado e tiver algum outro acordo espec&iacute;fico relativo a </span>
<span class="c1">PLATAFORMA ECOMPAY </span>
<span class="c0">pactuado com a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;, este outro acordo tamb&eacute;m ser&aacute; encerrado imediatamente.</span>
</p>
<p class="c4">
<span class="c0">15.5. </span>
<span class="c1">Cl&aacute;usulas Sobreviventes ao Encerramento:</span>
<span class="c0">&nbsp; As Se&ccedil;&otilde;es 5, 6, 7, 8, 9 e 16 do </span>
<span class="c1">TERMO DE USO</span>
<span class="c0 c2">&nbsp; devem sobreviver a qualquer encerramento deste Contrato.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span>
</p>
<p class="c4">
<span class="c1 c2">16. MECANISMOS DE DEN&Uacute;NCIAS E SOLU&Ccedil;&Atilde;O DE DISPUTAS</span>
</p>
<p class="c4">
<span class="c0">16.1. </span>
<span class="c1">Mecanismos de Den&uacute;ncias:</span>
<span class="c0">&nbsp; quaisquer interessados podem reportar viola&ccedil;&atilde;o de direitos e outras irregularidades na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">, atrav&eacute;s do e-mail do suporte </span>
<span class="c0 c5">
<a class="c8" href="mailto:suporte@ecompay.app">suporte@ecompay.app</a>
</span>
<span class="c0">, &nbsp;inclusive infra&ccedil;&otilde;es a</span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp;e &agrave;s Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">.</span>
</p>
<p class="c4">
<span class="c0 c2">Essas den&uacute;ncias s&atilde;o apuradas com rigor, e podem resultar em consequ&ecirc;ncias diversas aos envolvidos. Por isso, &eacute; muito importante que o canal de atendimento seja utilizado com &eacute;tica. Em caso de d&uacute;vida sobre a ocorr&ecirc;ncia de infra&ccedil;&atilde;o, recomendamos que busque orienta&ccedil;&atilde;o jur&iacute;dica antes de enviar qualquer notifica&ccedil;&atilde;o, al&eacute;m de avaliar se existem formas de provar o que pretende informar.</span>
</p>
<p class="c4">
<span class="c0">16.2. </span>
<span class="c1">Den&uacute;ncias sobre viola&ccedil;&atilde;o a direitos de propriedade intelectual</span>
<span class="c0 c2">&nbsp;: Se a sua den&uacute;ncia for relativa a viola&ccedil;&atilde;o de direitos de propriedade intelectual, voc&ecirc; deve seguir todas as orienta&ccedil;&otilde;es dos canais, incluindo o compartilhamento de informa&ccedil;&otilde;es capazes de provar os fatos denunciados, em especial: (a) suas informa&ccedil;&otilde;es de contato, para que possamos nos comunicar com voc&ecirc;; (b) informa&ccedil;&otilde;es sobre o conte&uacute;do denunciado, com o detalhamento do ocorrido e os documentos demonstrativos dos fatos narrados, no que for poss&iacute;vel; e (c) informa&ccedil;&otilde;es sobre a titularidade dos direitos de propriedade intelectual que possam ter sido violados.</span>
</p>
<p class="c4">
<span class="c0">16.2.1. A den&uacute;ncia deve ser enviada pela pessoa cujos direitos de propriedade intelectual foram violados, ou por representante munido de procura&ccedil;&atilde;o com poderes para tanto. A </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; n&atilde;o aceita den&uacute;ncias enviadas por terceiros.</span>
</p>
<p class="c4">
<span class="c0">16.2.2. Ao encaminhar uma den&uacute;ncia, o denunciante declara estar ciente e concordar que a </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;compartilhar&aacute; suas informa&ccedil;&otilde;es com o denunciado, e que o denunciado poder&aacute; contatar o denunciante para esclarecer os fatos e dirimir a quest&atilde;o.</span>
</p>
<p class="c4">
<span class="c0">16.2.3. No prazo de 10 (dez) dias contados do encaminhamento da den&uacute;ncia pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, o denunciado pode apresentar a sua defesa, informando os motivos que desqualificam a den&uacute;ncia apresentada e comprovando a titularidade do direito supostamente violado. O denunciado deve enviar: (a) informa&ccedil;&otilde;es de contato; (b) informa&ccedil;&otilde;es sobre o conte&uacute;do denunciado; (c) informa&ccedil;&otilde;es sobre a titularidade dos direitos de propriedade intelectual sobre o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; denunciado, incluindo a documenta&ccedil;&atilde;o comprobat&oacute;ria pertinente; e (d) declara&ccedil;&atilde;o de discord&acirc;ncia sobre a den&uacute;ncia de viola&ccedil;&atilde;o a direitos de propriedade intelectual, responsabilizando-se por quaisquer danos causados pela continuidade da comercializa&ccedil;&atilde;o ou do acesso ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; objeto da den&uacute;ncia.</span>
</p>
<p class="c4">
<span class="c0">16.3. </span>
<span class="c1">Den&uacute;ncias sobre divulga&ccedil;&atilde;o de cenas de nudez ou atos sexuais de car&aacute;ter privado:</span>
<span class="c0">&nbsp; Se a sua den&uacute;ncia for relativa a viola&ccedil;&atilde;o da intimidade decorrente da divulga&ccedil;&atilde;o, sem autoriza&ccedil;&atilde;o de seus participantes, de imagens, de v&iacute;deos ou de outros materiais contendo cenas de nudez ou de atos sexuais de car&aacute;ter privado, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; promover&aacute; a indisponibiliza&ccedil;&atilde;o desse conte&uacute;do, no &acirc;mbito e nos limites t&eacute;cnicos do seu servi&ccedil;o, de forma diligente, em at&eacute; 10 (dez) dias corridos da data do recebimento pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; de todas as informa&ccedil;&otilde;es necess&aacute;rias para que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; possa tomar essa provid&ecirc;ncia. Para isso, voc&ecirc; deve indicar precisamente: (a) a URL onde est&aacute; o material objeto da den&uacute;ncia; e (b) os meios que possibilitem &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp; identificar a v&iacute;tima no material denunciado, conforme sua descri&ccedil;&atilde;o. &Eacute; poss&iacute;vel denunciar apenas conte&uacute;do que envolva voc&ecirc;, um familiar ou outra pessoa de quem voc&ecirc; seja o representante legal, com as comprova&ccedil;&otilde;es de v&iacute;nculo ou parentesco. Materiais que envolvam outras pessoas devem ser denunciados por elas, seus familiares ou respons&aacute;veis.</span>
</p>
<p class="c4">
<span class="c0">16.4. </span>
<span class="c1">Provid&ecirc;ncias a serem adotadas pela ECOM:</span>
<span class="c0">&nbsp; Qualquer den&uacute;ncia deve ser encaminhada contendo todas as informa&ccedil;&otilde;es exigidas nos itens anteriores. A </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;n&atilde;o iniciar&aacute; qualquer processo de verifica&ccedil;&atilde;o se verificar a aus&ecirc;ncia de qualquer daquelas informa&ccedil;&otilde;es. &Eacute; de exclusivo crit&eacute;rio da ECOM envidar esfor&ccedil;os para intermediar a solu&ccedil;&atilde;o da disputa. Caso n&atilde;o seja do interesse da ECOM envolver-se no tratamento da den&uacute;ncia ela pode comunicar as partes envolvidas, podendo suspender imediatamente os SERVI&Ccedil;OS DA ECOM aos USU&Aacute;RIOS envolvidos, at&eacute; que a disputa seja resolvida entre eles, extra ou judicialmente, devendo o USU&Aacute;RIO interessado em reaver a presta&ccedil;&atilde;o de servi&ccedil;os enviar &agrave; ECOM documenta&ccedil;&atilde;o comprobat&oacute;ria da solu&ccedil;&atilde;o do conflito. Os USU&Aacute;RIOS envolvidos na disputa entendem e desde j&aacute; isentam a ECOM de qualquer demanda referente a qualquer tipo de &ocirc;nus ou preju&iacute;zo decorrente da interrup&ccedil;&atilde;o do servi&ccedil;o e concordam n&atilde;o envolver a ECOM em qualquer disputa extra ou judicial.</span>
</p>
<p class="c4">
<span class="c0">16.5. </span>
<span class="c1">Veda&ccedil;&atilde;o ao anonimato: </span>
<span class="c0">A </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;n&atilde;o dar&aacute; prosseguimento a den&uacute;ncias an&ocirc;nimas.</span>
</p>
<p class="c4">
<span class="c0">16.6. </span>
<span class="c1">Responsabilidade do denunciante: </span>
<span class="c0">O envio intencional de den&uacute;ncias falsas ou enganosas pode levar &agrave; aplica&ccedil;&atilde;o de qualquer das medidas previstas neste</span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, inclusive o bloqueio definitivo de conta, se o denunciante for </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; da </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;, al&eacute;m da sua responsabilidade por danos, de acordo com a legisla&ccedil;&atilde;o aplic&aacute;vel. Esclarecemos que denunciantes de m&aacute;-f&eacute; podem ser investigados pelas autoridades competentes e que </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; cooperar&aacute; com as investiga&ccedil;&otilde;es oficiais nesse sentido. O denunciante responde integralmente por quaisquer danos que causar ao denunciado, &agrave; </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; ou a terceiros em raz&atilde;o de den&uacute;ncias infundadas, principalmente nos casos em que a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, diante da den&uacute;ncia, e a seu exclusivo crit&eacute;rio, bloquear preventiva ou definitivamente a comercializa&ccedil;&atilde;o ou o acesso a um </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span><span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">16.7. </span>
<span class="c1">Processo de Apura&ccedil;&atilde;o de Den&uacute;ncias</span>
<span class="c0">&nbsp;: Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; entender que uma den&uacute;ncia n&atilde;o tem fundamentos ou elementos de prova suficientes para iniciar processo interno de apura&ccedil;&atilde;o, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode arquivar a den&uacute;ncia e encerrar a ocorr&ecirc;ncia reportada. Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; entender que a apura&ccedil;&atilde;o dos fatos &eacute; necess&aacute;ria, e desde que a den&uacute;ncia n&atilde;o tenha trazido elementos e provas incontest&aacute;veis sobre a sua proced&ecirc;ncia, ela pode encaminhar a den&uacute;ncia ao denunciado ou ao respons&aacute;vel pelo </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; denunciado, sem preju&iacute;zo de a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; adotar, preventivamente e a seu exclusivo crit&eacute;rio, quaisquer das medidas previstas neste </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, de modo isolado ou cumulativo. Se requerido, o denunciado pode responder a den&uacute;ncia em at&eacute; 5 (cinco) dias contados do seu encaminhamento pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Nesse caso, denunciado deve enviar: (a) informa&ccedil;&otilde;es de contato; (b) informa&ccedil;&otilde;es sobre o conte&uacute;do denunciado; (c) informa&ccedil;&otilde;es sobre a titularidade dos direitos de propriedade intelectual sobre o </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0">&nbsp; denunciado, incluindo a documenta&ccedil;&atilde;o comprobat&oacute;ria pertinente; e (d) declara&ccedil;&atilde;o de discord&acirc;ncia sobre a den&uacute;ncia de viola&ccedil;&atilde;o a direitos de propriedade intelectual, responsabilizando-se por quaisquer danos causados pela continuidade da comercializa&ccedil;&atilde;o ou do acesso ao </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;O</span>
<span class="c0 c2">&nbsp; objeto da den&uacute;ncia.</span>
</p>
<p class="c4">
<span class="c0">16.7.1. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode, a seu exclusivo crit&eacute;rio, iniciar procedimento de investiga&ccedil;&atilde;o interna, a qualquer tempo, sempre que entender necess&aacute;rio &agrave; apura&ccedil;&atilde;o dos fatos denunciados, sobretudo diante: (a) de reclama&ccedil;&otilde;es cujo esclarecimento seja capaz de solucionar a potencial disputa entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;; (b) de fatos que n&atilde;o tenham sido narrados de forma clara, mas que tragam ind&iacute;cios de irregularidades praticadas pelo denunciado; ou (c) de den&uacute;ncias com inconsist&ecirc;ncias ou ind&iacute;cios indicativos de m&aacute;-f&eacute;, fraude ou dolo do denunciante. Os resultados das investiga&ccedil;&otilde;es internas conduzidas pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;poder&atilde;o ser compartilhados com as autoridades competentes, sempre que exigido por lei ou necess&aacute;rio. Se o compartilhamento n&atilde;o for obrigat&oacute;rio por lei, cabe exclusivamente &agrave; 
</span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;definir se ir&aacute; divulgar ou n&atilde;o quaisquer resultados de sua apura&ccedil;&atilde;o.</span>
</p>
<p class="c4">
<span class="c0">16.8. </span>
<span class="c1">Avalia&ccedil;&atilde;o das Den&uacute;ncias pela ECOM</span>
<span class="c0">&nbsp;: Se o denunciado n&atilde;o responder a den&uacute;ncia em 10 (dez) dias, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode aplicar, em car&aacute;ter definitivo, quaisquer das medidas previstas neste </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, de modo isolado ou cumulativo, se entender cab&iacute;veis, dando ci&ecirc;ncia ao denunciante e comunicando as medidas ao </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; afetado. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;tamb&eacute;m pode aplicar quaisquer das medidas previstas na neste </span>
<span class="c1">&nbsp;TERMO DE USO</span>
<span class="c0">&nbsp;, de modo isolado ou cumulativo, se, diante da resposta do denunciado, concluir que houve infra&ccedil;&atilde;o aos Termos, viola&ccedil;&atilde;o de direitos ou outras irregularidades praticadas na </span>
<span class="c1">PLATAFORMA ECOMPAY</span>
<span class="c0">&nbsp;. Se a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;, por si ou por terceiros por ela determinados, entender que a quest&atilde;o denunciada &eacute; razoavelmente controversa, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode instaurar um procedimento de solu&ccedil;&atilde;o de disputas entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp;.</span>
</p>
<p class="c4">
<span class="c0">16.9. </span>
<span class="c1">Solu&ccedil;&atilde;o de Disputas entre USU&Aacute;RIOS:</span><
span class="c0">&nbsp; Os </span>
<span class="c1">SERVI&Ccedil;OS </span>
<span class="c0">da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;s&atilde;o prestados para conectar os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; entre si. As disputas que surgem dessas rela&ccedil;&otilde;es devem ser resolvidas entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; diretamente, inclusive aquelas relativas &agrave; qualidade, &agrave;s garantias e aos aspectos t&eacute;cnicos dos </span>
<span class="c1">PRODUTO OU SERVI&Ccedil;OS, </span>
<span class="c0 c2">isentando desde j&aacute; a ECOM de qualquer responsabilidade ou &ocirc;nus.</span>
</p>
<p class="c4">
<span class="c0">16.10. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; oferece servi&ccedil;o de suporte aos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp;, sem custo, e que atende por meio do </span>
<span class="c0 c7">email</span>
<span class="c0">&nbsp; &nbsp;suporte@ ecompay.app. &nbsp;Uma vez acionado, o servi&ccedil;o de suporte pode entrar em contato com os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; envolvidos numa disputa. Voc&ecirc; concorda que deve aplicar seus melhores esfor&ccedil;os para responder aos chamados do servi&ccedil;o de suporte, com foco na solu&ccedil;&atilde;o do problema, no menor tempo poss&iacute;vel e dentro dos prazos estabelecidos pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. Se n&atilde;o for poss&iacute;vel a composi&ccedil;&atilde;o entre os </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0">&nbsp; acerca de uma disputa, a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode (mas n&atilde;o se obriga a) encerrar a disputa mediante decis&atilde;o pr&oacute;pria, pautada na boa-f&eacute; e em par&acirc;metros de equidade, considerando as regras d </span>
<span class="c1">o TERMO DE USO</span>
<span class="c0">&nbsp; e das Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;. A </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; pode a seu exclusivo crit&eacute;rio: (i) n&atilde;o querer mediar tal disputa, (ii) delegar a solu&ccedil;&atilde;o de qualquer disputa a terceiros por ela determinados, sendo os custos de tal media&ccedil;&atilde;o exclusivamente arcados pelo USU&Aacute;RIO que perder a disputa, (iii) propor mecanismos de autorregulamenta&ccedil;&atilde;o entre os USU&Aacute;RIOS, (iv) encerrar de imediato a presta&ccedil;&atilde;o dos </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0">&nbsp; para os USU&Aacute;RIOS envolvidos na disputa, sendo certo desde j&aacute; que tais USU&Aacute;RIOS isentam &agrave; </span>
<span class="c1">ECOM </span>
<span class="c0">de qualquer demanda ou preju&iacute;zos decorrentes de eventual interrup&ccedil;&atilde;o dos servi&ccedil;os . A decis&atilde;o da </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a respeito da disputa, ou de quem ela designar, deve ser acatada de modo integral e irrecorr&iacute;vel pelos </span>
<span class="c1">USU&Aacute;RIOS</span>
<span class="c0 c2">&nbsp; envolvidos.</span>
</p>
<p class="c3">
<span class="c0 c2">
</span>
</p>
<p class="c4">
<span class="c1 c2">17. DISPOSI&Ccedil;&Otilde;ES FINAIS</span>
</p>
<p class="c4">
<span class="c0">17.1. O presente</span>
<span class="c1">&nbsp;</span>
<span class="c0 c2">TERMO DE USO e as POL&Iacute;TICAS DA ECOMPAY consolidam o Contrato que reger&aacute; as rela&ccedil;&otilde;es entre a ECOM, neste ato representada pela E-COM COMUNICA&Ccedil;&Otilde;ES LTDA, e terceiros.</span>
</p>
<p class="c4">
<span class="c0 c2">17.2. &nbsp;A toler&acirc;ncia de uma Parte relativamente ao descumprimento de qualquer das obriga&ccedil;&otilde;es da outra constantes neste Contrato e demais documentos existentes entre as Partes n&atilde;o ser&aacute; considerada nova&ccedil;&atilde;o ou ren&uacute;ncia a qualquer direito, constituindo mera liberalidade, que n&atilde;o impedir&aacute; a Parte tolerante de exigir da outra seu cumprimento, a qualquer tempo.</span>
</p>
<p class="c4">
<span class="c0">17.3. &nbsp;As comunica&ccedil;&otilde;es e noti&#64257;ca&ccedil;&otilde;es a serem realizadas pela </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;ao </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;S poder&atilde;o ser realizadas por meio de sua Dashboard ou do endere&ccedil;o de e-mail indicado pelo </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp; no formul&aacute;rio de cadastro de sua </span>
<span class="c1">CONTA ECOM</span>
<span class="c0">&nbsp;. </span>
<span class="c1">O USU&Aacute;RIO</span>
<span class="c0">&nbsp;se compromete a manter tal endere&ccedil;o atualizado, devendo comunicar &agrave; iugu qualquer altera&ccedil;&atilde;o. </span>
<span class="c1">O USU&Aacute;RIO</span>
<span class="c0">&nbsp;autoriza desde j&aacute; a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp; a enviar mensagens noti&#64257;cando-o sobre a presta&ccedil;&atilde;o dos Servi&ccedil;os, bem como mensagens de conte&uacute;do publicit&aacute;rio sobre os servi&ccedil;os prestados pela iugu. As noti&#64257;ca&ccedil;&otilde;es realizadas pelo </span>
<span class="c1">USU&Aacute;RIO</span>
<span class="c0">&nbsp;&agrave; </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;dever&atilde;o ser realizadas atrav&eacute;s do email: suporte@ecompay.app ou pelo formul&aacute;rio de suporte, caso esteja dispon&iacute;vel.</span>
</p>
<p class="c4">
<span class="c0">17.4. Os </span>
<span class="c1">SERVI&Ccedil;OS DA ECOM</span>
<span class="c0 c2">&nbsp;dever&atilde;o ser utilizados em territ&oacute;rio nacional, n&atilde;o sendo poss&iacute;vel sua utiliza&ccedil;&atilde;o fora do territ&oacute;rio nacional.</span>
</p>
<p class="c4">
<span class="c0">17.5. Os pre&ccedil;os dos </span>
<span class="c1">PRODUTOS E SERVI&Ccedil;OS dos VENDEDORES</span>
<span class="c0">&nbsp;devem estar estabelecidos em moeda corrente nacional e a </span>
<span class="c1">ECOM</span>
<span class="c0">&nbsp;n&atilde;o garante, em nenhuma hip&oacute;tese, a solv&ecirc;ncia dos consumidores finais da transa&ccedil;&atilde;o de pagamentos para pagamento dos produtos ou servi&ccedil;os objetos das transa&ccedil;&otilde;es com o </span>
<span class="c1 c2">&nbsp;VENDEDORES, AFILIADOS OU COPRODUTORES.</span>
</p>
<p class="c4">
<span class="c0">17.5. </span>
<span class="c1">Foro e Lei Aplic&aacute;vel</span>
<span class="c0">: </span>
<span class="c1">O</span>
<span class="c0">&nbsp;</span>
<span class="c1">TERMO DE USO</span>
<span class="c0">&nbsp;e as Pol&iacute;ticas da </span>
<span class="c1">ECOM</span>
<span class="c0 c2">&nbsp;s&atilde;o regidos pelas leis do Brasil. O foro competente fica eleito o foro do Rio de Janeiro. &nbsp; &nbsp;</span>
</p>
<p class="c6">
<span class="c0">
<a class="c8" href="https://www.google.com/url?q=https://ecompay.app/%23cmnt_ref1&amp;sa=D&amp;source=editors&amp;ust=1724090706095921&amp;usg=AOvVaw0898a_cp9lVUZvHYcHl5gl">[a]</a>
</span>
<span class="c0 c2">&nbsp;Devem sem respeitadas a politica de reembolso da IUGU https://dev.iugu.com/docs/realizar-o-reembolso-de-faturas-estorno-por-api</span></p><p class="c11 c13"><span class="c0 c2"></span>
</p>
</body>
</html>`

export default contract;