import React from "react";
import { Notification } from 'rsuite';

// console.log(process.env.REACT_APP_API_URL); // Isso imprimirá o valor da variável no console

const showError = (message) => {
  if(message === "Failed to fetch"){
    message = "Falha de rede, verifique sua conexão com a internet"
  }
  Notification['error']({
    title: "Erro",
    description: <p>{message}</p>
  });
}

class BaseService{

    BASE_URL;

    constructor(handler = undefined) {
      const REACT_APP_API_URL = process.env.REACT_APP_API_URL
      this.BASE_URL = handler ? `${REACT_APP_API_URL}/${handler}` : REACT_APP_API_URL
    }

    getImpersonated(){
      return JSON.parse(localStorage.getItem('impersonated') || '{}');
    }


    getToken() {
      return localStorage.getItem('access_token');
    }
  
    async get(url = "", options = {}){
      const { silent = false } = options;
      try{
        const impersonated = this.getImpersonated()
        const response = await fetch(`${this.BASE_URL}/${url}`, {
          "method": "GET",
          "headers": {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.getToken()}`,
            'Cookie': document.cookie,
            ...impersonated?._id ? { "x-profile-id" : impersonated?._id} : {}
          },
        })

        const json = await response.json()

        if(json && json?.error && !silent) {
            showError(json?.error)
          return []
        }
  
        return json;
      }catch(e){
        if(silent === false){
          showError(e.message);
        }
        return false
      }
    }
  
    async post(payload, url = "", silent = false){
      try{
        const impersonated = this.getImpersonated()

        const response = await fetch(`${this.BASE_URL}/${url}`, {
          "method": "POST",
          "headers": {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.getToken()}`,
            'Cookie': document.cookie,
            ...impersonated?._id ? { "x-profile-id" : impersonated?._id} : {}
          },
          "body": JSON.stringify(payload)
        })

        const json = await response.json()

        if(json && json.error && !silent) {
          showError(json.error)
        }
  
        return json;
      }catch(e){
        if(silent === false){
          showError(e.message);
        }
        return false
      }
    }

    async put(payload, url = ""){
      try{
        const impersonated = this.getImpersonated()

        const response = await fetch(`${this.BASE_URL}/${url}`, {
          "method": "PUT",
          "headers": {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.getToken()}`,
            'Cookie': document.cookie,
            ...impersonated?._id ? { "x-profile-id" : impersonated?._id} : {}
          },
          "body": JSON.stringify(payload)
        })

        const json = await response.json()

        if(json && json?.error) {
          showError(json?.error)
        }
  
        return json;
      }catch(e){
        showError(e.message);
        return false;
      }
    }

    async delete(url = ""){
      try{
        const impersonated = this.getImpersonated()
        const response = await fetch(`${this.BASE_URL}/${url}`, {
          "method": "DELETE",
          "headers": {
            "Authorization": `Bearer ${this.getToken()}`,
            'Cookie': document.cookie,
            ...impersonated?._id ? { "x-profile-id" : impersonated?._id} : {}
          }
        })

        const json = await response.json()

        if(json.error) {
          showError(json.error)
        }
  
        return json;
      }catch(e){
        showError(e.message);
        return false;
      }
    }
  
  
  }
  
  export default BaseService;