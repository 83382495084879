const policies = `<html>
    <head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type">
  <style type="text/css">

    .c2 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 11pt
    }

    .c13 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 18pt
    }

    .c5 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c20 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 11pt
    }

    .c12 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify;
      height: 11pt
    }

    .c11 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c18 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c21 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c26 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c27 {
      padding-top: 0pt;
      padding-bottom: 12pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: justify
    }

    .c0 {
      font-size: 12pt;
      font-family: "Times New Roman";
      font-style: italic;
      font-weight: 400
    }

    .c19 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      text-align: left
    }

    .c4 {
      color: #000000;
      text-decoration: none;
      vertical-align: baseline;
      font-style: normal
    }

    .c3 {
      font-size: 12pt;
      font-family: "Times New Roman";
      font-weight: 400
    }

    .c9 {
      color: #000000;
      vertical-align: baseline;
      font-style: normal
    }

    .c8 {
      -webkit-text-decoration-skip: none;
      text-decoration: underline;
      text-decoration-skip-ink: none
    }

    .c1 {
      font-size: 12pt;
      font-family: "Times New Roman";
      font-weight: 700
    }

    .c14 {
      color: inherit;
      text-decoration: inherit
    }

    .c23 {
      max-width: 425.2pt;
      padding: 70.8pt 85pt 70.8pt 85pt
    }

    .c10 {
      font-weight: 400;
      font-family: "Arial"
    }

    .c7 {
      border: 1px solid black;
      margin: 5px
    }

    .c24 {
      font-weight: 700;
      font-family: "Arial"
    }

    .c6 {
    }

    .c25 {
    }

    .c16 {
      height: 18pt
    }

    .c22 {
    }

    .c15 {
      font-size: 11pt
    }

    .c17 {
    }

    .titulo-contrato {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 36pt;'
      padding-bottom: 6pt;
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .subtitulo-contrato {
      padding-top: 18pt;
      color: #666666;
      font-size: 24pt;
      padding-bottom: 4pt;
      font-family: "Georgia";
      line-height: 1.0791666666666666;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
    }

   
  </style>
</head>

<body class="c19 doc-content">
    <p class="c7"><span class="c1">POL&Iacute;TICA DE PRIVACIDADE PLATAFORMA ECOMPAY</span></p>
    <p class="c34">
        <span class="c4">A sua privacidade &eacute; importante para n&oacute;s. &Eacute; pol&iacute;tica da </span><span class="c11">PLATAFORMA ECOMPAY</span>
        <span class="c4">&nbsp;respeitar a sua privacidade em rela&ccedil;&atilde;o a qualquer informa&ccedil;&atilde;o sua que possamos coletar na </span><span class="c11">PLATAFORMA ECOMPAY</span>
        <span class="c0">, e outros sites que possu&iacute;mos e operamos.</span>
    </p>
    <p class="c7">
        <span class="c0">
            Ser&atilde;o solicitadas informa&ccedil;&otilde;es pessoais somente quando realmente forem necess&aacute;rias para lhe fornecer um servi&ccedil;o. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento.
            Tamb&eacute;m informamos o motivo pelo qual estaremos coletando e como ser&atilde;o utilizadas.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            As informa&ccedil;&otilde;es coletadas ser&atilde;o retidas pelo tempo necess&aacute;rio para fornecer o servi&ccedil;o solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceit&aacute;veis
            &#8203;&#8203;para evitar perdas e roubos, bem como, divulga&ccedil;&atilde;o, c&oacute;pia, acesso, uso ou modifica&ccedil;&atilde;o n&atilde;o autorizados.
        </span>
    </p>
    <p class="c7"><span class="c0">Informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal n&atilde;o s&atilde;o compartilhadas publicamente ou com terceiros, exceto quando exigido por lei.</span></p>
    <p class="c7">
        <span class="c0">
            O nosso site pode conter links para sites externos que n&atilde;o s&atilde;o operados por n&oacute;s. Esteja ciente de que n&atilde;o temos controle sobre o conte&uacute;do e pr&aacute;ticas desses sites, e n&atilde;o nos
            responsabilizamos por suas respectivas pol&iacute;ticas de privacidade.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            Voc&ecirc; &eacute; n&atilde;o &eacute; obrigado a aceitar a nossa solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es pessoais, entendendo, pois, que talvez n&atilde;o ser&aacute; poss&iacute;vel fornecer alguns dos
            servi&ccedil;os desejados.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            O uso continuado de nosso site ser&aacute; considerado como aceita&ccedil;&atilde;o de nossas pr&aacute;ticas em torno de privacidade e informa&ccedil;&otilde;es pessoais. Havendo alguma d&uacute;vida sobre como lidamos com
            dados do usu&aacute;rio e informa&ccedil;&otilde;es pessoais, entre em contacto conosco.
        </span>
    </p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c11">POL&Iacute;TICA DE COOKIES</span><span class="c4">&nbsp;</span><span class="c11">PLATAFORMA ECOMPAY</span></p>
    <p class="c7">
        <span class="c0">
            <br />
            Voc&ecirc; sabe o que s&atilde;o cookies?
        </span>
    </p>
    <p class="c7">
        <span class="c4">
            <br />
            Como &eacute; de praxe em quase todos os sites profissionais, a
        </span>
        <span class="c11">PLATAFORMA ECOMPAY</span><span class="c4">&nbsp;usa cookies,</span><span class="c36">&nbsp;</span>
        <span class="c32">
            arquivos que cont&ecirc;m pequenos fragmentos de dados &mdash; como um nome de usu&aacute;rio e uma senha &mdash; que&nbsp;s&atilde;o&nbsp;trocados entre o computador de um usu&aacute;rio e um servidor Web para identificar
            usu&aacute;rios espec&iacute;ficos e melhorar sua experi&ecirc;ncia de navega&ccedil;&atilde;o
        </span>
        <span class="c0">. </span>
    </p>
    <p class="c7">
        <span class="c0">
            Aqui, &eacute; descrito quais informa&ccedil;&otilde;es os cookies coletam, como s&atilde;o utilizadas e por que, &agrave;s vezes, se faz necess&aacute;rio armazenar esses cookies. Tamb&eacute;m ser&aacute; compartilhado como
            voc&ecirc; pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou &#39;quebrar&#39; certos elementos da funcionalidade do site.
        </span>
    </p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c0">Como usamos os cookies?</span></p>
    <p class="c7">
        <span class="c0">
            <br />
            Os cookies s&atilde;o utilizados por v&aacute;rios motivos, detalhados abaixo. Infelizmente, na maioria dos casos, n&atilde;o h&aacute; op&ccedil;&otilde;es padr&atilde;o do setor para desativar os cookies sem desativar
            completamente a funcionalidade e os recursos que eles adicionam a este site. O ideal &eacute; que voc&ecirc; deixe todos os cookies se n&atilde;o tiver certeza se precisa ou n&atilde;o deles, caso sejam utilizados
            &#8203;&#8203;para fornecer um servi&ccedil;o que voc&ecirc; usa.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            Desativar cookies<br />
            Voc&ecirc; pode impedir a configura&ccedil;&atilde;o de cookies ao ajustar as configura&ccedil;&otilde;es do seu navegador. Esteja ciente de que a desativa&ccedil;&atilde;o de cookies afetar&aacute; a funcionalidade deste e de
            muitos outros sites que voc&ecirc; visita. A desativa&ccedil;&atilde;o de cookies geralmente resultar&aacute; na desativa&ccedil;&atilde;o de determinadas funcionalidades e recursos deste site. Portanto, &eacute;
            recomend&aacute;vel que voc&ecirc; n&atilde;o desative os cookies.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            Cookies que definimos<br />
            Cookies relacionados &agrave; conta
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            Se voc&ecirc; criar uma conta conosco, os cookies ser&atilde;o utilizados para o gerenciamento do processo de inscri&ccedil;&atilde;o e administra&ccedil;&atilde;o geral. Esses cookies geralmente ser&atilde;o exclu&iacute;dos
            quando voc&ecirc; sair do sistema, por&eacute;m, em alguns casos, eles poder&atilde;o permanecer posteriormente para lembrar as prefer&ecirc;ncias do seu site ao sair.
        </span>
    </p>
    <p class="c7"><span class="c0">Cookies relacionados ao login</span></p>
    <p class="c7">
        <span class="c0">
            Os cookies tamb&eacute;m s&atilde;o utilizados quando voc&ecirc; est&aacute; logado, para que possamos lembrar dessa a&ccedil;&atilde;o. Isso evita que voc&ecirc; precise fazer login sempre que visitar uma nova p&aacute;gina.
            Esses cookies s&atilde;o normalmente removidos ou limpos quando voc&ecirc; efetua logout para garantir que voc&ecirc; possa acessar apenas a recursos e &aacute;reas restritas ao efetuar login.
        </span>
    </p>
    <p class="c7"><span class="c5">Cookies relacionados a boletins por e-mail</span></p>
    <p class="c7">
        <span class="c5">
            Este site oferece servi&ccedil;os de assinatura de boletim informativo ou e-mail e os cookies podem ser usados &#8203;&#8203;para lembrar se voc&ecirc; j&aacute; est&aacute; registrado e se deve mostrar determinadas
            notifica&ccedil;&otilde;es v&aacute;lidas apenas para usu&aacute;rios inscritos / n&atilde;o inscritos.
        </span>
    </p>
    <p class="c7"><span class="c0">Pedidos processando cookies relacionados</span></p>
    <p class="c7">
        <span class="c0">
            Este site oferece facilidades de com&eacute;rcio eletr&ocirc;nico ou pagamento e alguns cookies s&atilde;o essenciais para garantir que seu pedido seja lembrado entre as p&aacute;ginas, para que se possa process&aacute;-lo
            adequadamente.
        </span>
    </p>
    <p class="c7"><span class="c0">Cookies relacionados a pesquisas</span></p>
    <p class="c7">
        <span class="c0">
            Periodicamente, s&atilde;o oferecidas pesquisas e question&aacute;rios para fornecer informa&ccedil;&otilde;es interessantes, ferramentas &uacute;teis ou para entender nossa base de usu&aacute;rios com mais precis&atilde;o.
            Essas pesquisas podem usar cookies para lembrar quem j&aacute; participou numa pesquisa ou para fornecer resultados precisos ap&oacute;s a altera&ccedil;&atilde;o das p&aacute;ginas.
        </span>
    </p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c0">Cookies relacionados a formul&aacute;rios</span></p>
    <p class="c7">
        <span class="c0">
            Quando voc&ecirc; envia dados por meio de um formul&aacute;rio como os encontrados nas p&aacute;ginas de contacto ou nos formul&aacute;rios de coment&aacute;rios, os cookies podem ser configurados para lembrar os detalhes do
            usu&aacute;rio para correspond&ecirc;ncia futura.
        </span>
    </p>
    <p class="c7"><span class="c0">Cookies de prefer&ecirc;ncias do site</span></p>
    <p class="c7">
        <span class="c4">Para proporcionar uma &oacute;tima experi&ecirc;ncia na </span><span class="c11">PLATAFORMA ECOMPAY</span>
        <span class="c0">
            , fornecemos a funcionalidade para definir suas prefer&ecirc;ncias de como esse site &eacute; executado quando voc&ecirc; o usa. Para lembrar suas prefer&ecirc;ncias, &eacute; necess&aacute;rio definir cookies para que essas
            informa&ccedil;&otilde;es possam ser chamadas sempre que voc&ecirc; interagir com uma p&aacute;gina for afetada por suas prefer&ecirc;ncias.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            <br />
            Cookies de Terceiros<br />
            Em alguns casos especiais, tamb&eacute;m usamos cookies fornecidos por terceiros confi&aacute;veis. A se&ccedil;&atilde;o a seguir detalha quais cookies de terceiros voc&ecirc; pode encontrar atrav&eacute;s deste site.
        </span>
    </p>
    <p class="c7">
        <span class="c5">
            Este site pode utilizar o Google Analytics, que &eacute; uma das solu&ccedil;&otilde;es de an&aacute;lise mais difundidas e confi&aacute;veis &#8203;&#8203;da Web, para nos ajudar a entender como voc&ecirc; usa o site e como
            podemos melhorar sua experi&ecirc;ncia. Esses cookies podem rastrear itens como quanto tempo voc&ecirc; gasta no site e as p&aacute;ginas visitadas, para que possamos continuar produzindo conte&uacute;do atraente.<br />
            Para mais informa&ccedil;&otilde;es sobre cookies do Google Analytics, consulte a p&aacute;gina oficial do Google Analytics.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            As an&aacute;lises de terceiros s&atilde;o utilizadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conte&uacute;do atrativo. Esses cookies podem rastrear itens como o tempo que voc&ecirc; passa
            no site ou as p&aacute;ginas visitadas, o que nos ajuda a entender como podemos melhorar o site para voc&ecirc;.<br />
            Periodicamente, novos recursos s&atilde;o testados e fazemos altera&ccedil;&otilde;es subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados &#8203;&#8203;para
            garantir que voc&ecirc; receba uma experi&ecirc;ncia consistente enquanto estiver no site, enquanto entendemos quais otimiza&ccedil;&otilde;es os nossos usu&aacute;rios mais apreciam.<br />
            &Agrave; medida que vendemos produtos, &eacute; importante entendermos as estat&iacute;sticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse &eacute; o tipo de dados que esses cookies
            rastrear&atilde;o. Isso &eacute; importante para voc&ecirc;, pois significa que podemos fazer previs&otilde;es de neg&oacute;cios com precis&atilde;o que nos permitem analisar nossos custos de publicidade e produtos para
            garantir o melhor pre&ccedil;o poss&iacute;vel.
        </span>
    </p>
    <p class="c7">
        <span class="c4">
            <br />
            Esperamos que esteja esclarecido e, como mencionado anteriormente, se houver algo que voc&ecirc; n&atilde;o tem certeza se precisa ou n&atilde;o, geralmente &eacute; mais seguro deixar os cookies ativados, caso interaja com um
            dos recursos que voc&ecirc; usa na
        </span>
        <span class="c2">PLATAFORMA ECOMPAY.</span>
    </p>
    <p class="c7 c8"><span class="c2"></span></p>
    <p class="c7 c8"><span class="c2"></span></p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c2">POL&Iacute;TICA DE CONTE&Uacute;DO</span></p>
    <p class="c7">
        <span class="c4">Esta Pol&iacute;tica de Conte&uacute;do (&ldquo;Pol&iacute;tica&rdquo;) possui o objetivo de informar os conte&uacute;dos que n&atilde;o ser&atilde;o permitidos no site</span><span class="c11">,</span>
        <span class="c4">&nbsp;por violar a legisla&ccedil;&atilde;o ou princ&iacute;pios e valores adotados pela </span><span class="c11">PLATAFORMA ECOMPAY.</span>
    </p>
    <p class="c7">
        <span class="c0">
            O desrespeito a esta Pol&iacute;tica poder&aacute; resultar em suspens&atilde;o, bloqueio e/ou encerramento da licen&ccedil;a de uso do Usu&aacute;rio, sem preju&iacute;zo de outras medidas legais cab&iacute;veis.
        </span>
    </p>
    <p class="c7">
        <span class="c4">No&nbsp;</span><span class="c4 c20">site</span><span class="c4">&nbsp;da </span><span class="c11">PLATAFORMA ECOMPAY, </span>
        <span class="c0">n&atilde;o ser&atilde;o permitidos os conte&uacute;dos abaixo listados.</span>
    </p>
    <ul class="c18 lst-kix_list_1-0 start">
        <li class="c9 li-bullet-0">
            <span class="c11">Conte&uacute;do f&iacute;sico</span><span class="c4">: que comercialize quaisquer servi&ccedil;os ou produtos f&iacute;sicos (a </span><span class="c11">PLATAFORMA ECOMPAY</span>
            <span class="c0">&nbsp;aceita apenas produtos digitais);</span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do perigoso ou ilegal</span>
            <span class="c0">
                &nbsp;: que promove o Usu&aacute;rio a praticar atos perigosos, que orienta como praticar crimes, que demonstre atos ilegais de abuso ou tortura animal, que promova organiza&ccedil;&otilde;es criminosas (ou seus membros),
                terrorismo ou outras atividades ilegais, que forne&ccedil;a orienta&ccedil;&otilde;es sobre como criar materiais ilegais, que promove uso de drogas ilegais.
            </span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do expl&iacute;cito ou violento</span>
            <span class="c0">
                &nbsp;: que demonstre ou incite viol&ecirc;ncia, com objetivo de chocar ou causar repulsa nos Usu&aacute;rios, o que envolve, por exemplo: acidentes, desastres naturais, guerras, ataques terroristas, lutas, ataques
                f&iacute;sicos, tortura, protestos, roubos, cad&aacute;veres, procedimentos m&eacute;dicos, ferimentos.
            </span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do sexual</span><span class="c4">&nbsp;:</span><span class="c11">&nbsp;</span>
            <span class="c0">
                que tenha como objetivo a estimula&ccedil;&atilde;o sexual, com pornografia ou representa&ccedil;&otilde;es expl&iacute;citas de atos sexuais ou nudez, ou de algum modo obsceno ou contr&aacute;rio &agrave; moral e aos bons
                costumes, conte&uacute;do sexual envolvendo menores, relacionados &agrave; pedofilia, &agrave; prostitui&ccedil;&atilde;o ou similares.
            </span>
        </li>
        <li class="c15 li-bullet-0">
            <span class="c11">Conte&uacute;do de &oacute;dio</span><span class="c4">&nbsp;:</span><span class="c11">&nbsp;</span>
            <span class="c0">
                que promova a viol&ecirc;ncia ou discrimina&ccedil;&atilde;o ou &oacute;dio a grupos ou indiv&iacute;duos, com base em alguma caracter&iacute;stica, tal como ra&ccedil;a, g&ecirc;nero, etnia, religi&atilde;o,
                orienta&ccedil;&atilde;o sexual, classe social, nacionalidade, dentre outros.
            </span>
        </li>
    </ul>
    <ul class="c18 lst-kix_list_2-0 start">
        <li class="c9 li-bullet-0">
            <span class="c11">Conte&uacute;do com venda de produtos ou servi&ccedil;os ilegais</span>
            <span class="c0">
                &nbsp;: que promova a venda de produtos e servi&ccedil;os ilegais, como drogas, armas de fogo, explosivos, &oacute;rg&atilde;os, servi&ccedil;os m&eacute;dicos n&atilde;o licenciados, documentos falsificados ou adulterados,
                tr&aacute;fico humano, produtos subtra&iacute;dos de terceiros, furtados ou roubados.
            </span>
        </li>
        <li class="c15 li-bullet-0">
            <span class="c11">Conte&uacute;do que implique a venda ou oferta de participa&ccedil;&otilde;es societ&aacute;rias e/ou de neg&oacute;cios e/ou de investimentos&nbsp;</span>
            <span class="c0">
                : conte&uacute;do cuja finalidade seja a venda ou de participa&ccedil;&otilde;es societ&aacute;rias e/ou de neg&oacute;cios e/ou de investimentos, ou seja, cujo objetivo ao final seja angariar investidores e n&atilde;o
                promover a informa&ccedil;&atilde;o sobre produtos na &aacute;rea financeira .
            </span>
        </li>
    </ul>
    <ul class="c18 lst-kix_list_3-0 start">
        <li class="c28 li-bullet-0"><span class="c11">Conte&uacute;do com apologia do fumo</span><span class="c0">&nbsp;: que fa&ccedil;am apologia do h&aacute;bito de fumar.</span></li>
    </ul>
    <ul class="c18 lst-kix_list_4-0 start">
        <li class="c28 li-bullet-0">
            <span class="c11">Conte&uacute;do de esquemas de pir&acirc;mides</span><span class="c4">&nbsp;:</span><span class="c11">&nbsp;</span><span class="c4">que promova esquemas de pir&acirc;mides,&nbsp;</span>
            <span class="c4 c20">marketing</span><span class="c4">&nbsp;multin&iacute;vel&nbsp;</span><span class="c4 c20">etc</span><span class="c0">.</span>
        </li>
    </ul>
    <ul class="c18 lst-kix_list_5-0 start">
        <li class="c9 li-bullet-0">
            <span class="c11">Conte&uacute;do com promessas</span>
            <span class="c0">
                &nbsp;: que promete limpar nome sujo no Serasa e similares, que promete economia de combust&iacute;vel atrav&eacute;s de kit de hidrog&ecirc;nio, que promete sucesso em loterias ou jogos de azar, que promete cura de
                problemas de sa&uacute;de sem respaldo de uma organiza&ccedil;&atilde;o reconhecidamente especializada no tema, que promete aumento de n&uacute;mero de seguidores em redes sociais, curtidas, entre outros, que promete ganhar
                dinheiro com sorteios nas redes sociais (&quot;Rob&ocirc; de Sorteios&quot;, &quot;Rob&ocirc; de Pix&quot;).
            </span>
        </li>
        <li class="c15 li-bullet-0">
            <span class="c11">Conte&uacute;do de distribui&ccedil;&atilde;o de imagens e v&iacute;deos sensuais</span><span class="c4">&nbsp;: relacionado a imagens ou v&iacute;deos sensuais/ adultos sendo entregues pelo Instagram (</span>
            <span class="c4 c20">close friends</span><span class="c0">), Telegram, &Aacute;rea de membros, ou qualquer outra forma de distribui&ccedil;&atilde;o.</span>
        </li>
    </ul>
    <ul class="c18 lst-kix_list_6-0 start">
        <li class="c9 li-bullet-0">
            <span class="c11">Conte&uacute;do de ass&eacute;dio e bullying virtual</span><span class="c4">&nbsp;</span><span class="c11">(</span><span class="c11 c20">cyberbullying</span><span class="c11">)</span>
            <span class="c4">&nbsp;:</span><span class="c11">&nbsp;</span><span class="c4">que promove amea&ccedil;a, persegui&ccedil;&atilde;o (</span><span class="c4 c20">stalking</span>
            <span class="c0">), intimida&ccedil;&atilde;o, insulto, que tenha linguagem abusiva ou humilhante.</span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do com viola&ccedil;&atilde;o a leis de propriedade intelectual&nbsp;</span><span class="c4">:</span><span class="c11">&nbsp;</span>
            <span class="c0">que viole direitos autorais, de marca ou de publicidade, segredos industriais, patentes, modelos e desenhos industriais.</span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do com restri&ccedil;&otilde;es</span><span class="c4">:</span><span class="c11">&nbsp;</span>
            <span class="c0">afetados por alguma restri&ccedil;&atilde;o de qualquer esp&eacute;cie quanto ao seu uso, explora&ccedil;&atilde;o ou transfer&ecirc;ncia de dom&iacute;nio ou posse.</span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do com viola&ccedil;&atilde;o de direitos de personalidade, privacidade e &agrave;s leis de prote&ccedil;&atilde;o de dados pessoais&nbsp;</span>
            <span class="c0">
                : produtos que violem a intimidade, a honra, a imagem, o nome ou qualquer outro direito de personalidade de terceiros, listas de correio ou base de dados pessoais, que contenha dados pessoais n&atilde;o autorizados pelos
                seus titulares, dentre eles imagem, voz, nome, endere&ccedil;o, telefone, dentre outros.
            </span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do com problemas de qualidade audiovisual</span>
            <span class="c0">: que contenha imagens deturpadas, com legendas imprecisas ou dessincronizadas, que n&atilde;o sejam v&iacute;deos, ou seja, conte&uacute;do com imagens est&aacute;ticas.</span>
        </li>
        <li class="c10 li-bullet-0">
            <span class="c11">Conte&uacute;do insuficiente para os fins propostos:&nbsp;</span>
            <span class="c0">
                que tenham baixa qualidade de conte&uacute;do educacional e que n&atilde;o se preste aos fins propostos como objetivo do Infoproduto por neglig&ecirc;ncia ou imper&iacute;cia do Infoprodutor e/ou pelo uso de
                informa&ccedil;&otilde;es falsa no todo ou em parte do Infoproduto;
            </span>
        </li>
        <li class="c15 li-bullet-0">
            <span class="c11">Conte&uacute;do com an&uacute;ncios publicit&aacute;rios enganosos, conte&uacute;do com an&uacute;ncios e com links externos&nbsp;</span>
            <span class="c4">: que sugira ou afirme de modo indevido a exist&ecirc;ncia de parceria com a </span><span class="c11">PLATAFORMA ECOMPAY</span>
            <span class="c4">, quando inexistente; que seja comercializado por pessoas ou empresas que n&atilde;o sejam parceiras oficiais da </span><span class="c11">PLATAFORMA ECOMPAY</span>
            <span class="c4">; que n&atilde;o s&atilde;o vendidos pela </span><span class="c11">PLATAFORMA ECOMPAY</span>
            <span class="c4">&nbsp;ou seus parceiros oficiais; que contenha apenas an&uacute;ncios publicit&aacute;rios; que contenha&nbsp;</span><span class="c4 c20">link</span>
            <span class="c4">&nbsp;externo, que direcione o Usu&aacute;rio para fora do&nbsp;</span><span class="c4 c20">site</span><span class="c0">.</span>
        </li>
    </ul>
    <p class="c7"><span class="c11">Exce&ccedil;&otilde;es</span></p>
    <p class="c7">
        <span class="c4">
            Em determinados casos, o conte&uacute;do poder&aacute; ser permitido se o objetivo for educativo e desde que exista um contexto claro nos v&iacute;deos a serem publicados. A an&aacute;lise do conte&uacute;do e sua
            autoriza&ccedil;&atilde;o para publica&ccedil;&atilde;o no&nbsp;
        </span>
        <span class="c4 c20">site</span><span class="c4">&nbsp;ser&aacute; feita exclusivamente pela </span><span class="c11">PLATAFORMA ECOMPAY</span>
        <span class="c0">&nbsp;e a seu exclusivo crit&eacute;rio, podendo optar por vetar a disponibiliza&ccedil;&atilde;o do conte&uacute;do, se assim julgar apropriado.</span>
    </p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c11">Mudan&ccedil;as na Pol&iacute;tica de Conte&uacute;do</span></p>
    <p class="c7">
        <span class="c4">Esta Pol&iacute;tica de Conte&uacute;do pode passar por atualiza&ccedil;&otilde;es conforme o que a </span><span class="c11">PLATAFORMA ECOMPAY</span>
        <span class="c4">&nbsp;julgar apropriado para melhorar os servi&ccedil;os e experi&ecirc;ncias, considerando seus princ&iacute;pios e valores. Quando houver altera&ccedil;&atilde;o da Pol&iacute;tica de Conte&uacute;do, a </span>
        <span class="c11">PLATAFORMA ECOMPAY</span><span class="c4">&nbsp;informar&aacute; no&nbsp;</span><span class="c4 c20">site</span><span class="c0">, em local de destaque.</span>
    </p>
    <p class="c7"><span class="c11">Den&uacute;ncias ou D&uacute;vidas</span></p>
    <p class="c7">
        <span class="c0">
            Se voc&ecirc; encontrar conte&uacute;do que viola esta Pol&iacute;tica ou tiver d&uacute;vidas, coment&aacute;rios ou sugest&otilde;es relacionadas a esta Pol&iacute;tica, entre em contato conosco, no seguinte e-mail:
            suporte@.com.br com o tema &ldquo;Den&uacute;ncia&rdquo; ou &ldquo;Pol&iacute;tica de conte&uacute;do&rdquo;.
        </span>
    </p>
    <p class="c6">
        <span class="c4">A </span><span class="c11">PLATAFORMA ECOMPAY</span><span class="c4">&nbsp;empregar&aacute; os melhores esfor&ccedil;os para responder a todos os&nbsp;</span><span class="c4 c20">e-mails</span>
        <span class="c0">&nbsp;no menor espa&ccedil;o de tempo poss&iacute;vel.</span>
    </p>
    <p class="c6 c8"><span class="c0"></span></p>
    <p class="c6 c8"><span class="c0"></span></p>
    <h1 class="c23"><span class="c24">TERMO DE LICEN&Ccedil;A DE USO DE SOFTWARE N&Atilde;O CUSTOMIZ&Aacute;VEL</span></h1>
    <p class="c7"><span class="c2">Partes envolvidas</span></p>
    <p class="c7">
        <span class="c0">S&atilde;o Partes neste Termo De Licen&ccedil;a De Uso De&nbsp;</span><span class="c3">Software</span>
        <span class="c0">
            &nbsp;N&atilde;o Customiz&aacute;vel (&ldquo;Termo&rdquo;), na qualidade de Licenciante, a empresa E-COM COMUNICA&Ccedil;&Otilde;ES LTDA, inscrita no CNPJ 07.722.534/0001-70, e, na qualidade de Licenciado, o adquirente da
            presente licen&ccedil;a de uso de&nbsp;
        </span>
        <span class="c3">software</span><span class="c0">, identificado no Termo de Uso da Plataforma </span><span class="c2">PLATAFORMA ECOMPAY</span>
    </p>
    <p class="c7">
        <span class="c0">A celebra&ccedil;&atilde;o deste&nbsp;</span><span class="c3">Termo</span>
        <span class="c0">
            &nbsp;&eacute; comprovada pela concord&acirc;ncia do Usu&aacute;rio (doravante denominado &ldquo;Licenciado&rdquo;) Licenciado por via eletr&ocirc;nica e pela confirma&ccedil;&atilde;o de leitura no ato da
            contrata&ccedil;&atilde;o dos servi&ccedil;os da
        </span>
        <span class="c2">PLATAFORMA ECOMPAY, </span><span class="c0">conforme Termo de Uso. O Licenciado declara ter lido integralmente este Termo, concordando com todas as suas cl&aacute;usulas.</span>
    </p>
    <p class="c7"><span class="c2">Disposi&ccedil;&otilde;es iniciais</span></p>
    <p class="c7">
        <span class="c0">Este Termo estabelece as regras para a utiliza&ccedil;&atilde;o do&nbsp;</span><span class="c3">Software</span><span class="c0">&nbsp;</span><span class="c2">PLATAFORMA ECOMPAY, </span>
        <span class="c0">disponibilizado em&nbsp;</span><span class="c3">cloud computing&nbsp;</span><span class="c0">(computa&ccedil;&atilde;o em nuvem) em Plataforma de titularidade da</span><span class="c2">&nbsp;</span>
        <span class="c0">E-COM COMUNICA&Ccedil;&Otilde;ES LTDA.</span><span class="c13">&nbsp;</span>
    </p>
    <p class="c7"><span class="c2">Objeto</span></p>
    <p class="c7">
        <span class="c0">O presente Termo tem por objeto a concess&atilde;o ao Licenciado, sem car&aacute;ter de exclusividade, de uma licen&ccedil;a de uso e acesso ao&nbsp;</span><span class="c3">Software</span>
        <span class="c0">&nbsp;da </span><span class="c2">PLATAFORMA ECOMPAY</span><span class="c0">, doravante denominado simplesmente &ldquo;</span><span class="c3">software</span><span class="c0">&rdquo;, cujo link &eacute; </span>
        <span class="c25">
            <a class="c29" href="https://www.google.com/url?q=https://dashboard.ecompay.app/%23/login&amp;sa=D&amp;source=editors&amp;ust=1724264431122538&amp;usg=AOvVaw2R_hKxSGb0tLUNxW-ykBf_">https://dashboard.ecompay.app/#/login</a>
        </span>
        <span class="c0">. </span>
    </p>
    <p class="c7">
        <span class="c0">A concess&atilde;o ora convencionada n&atilde;o se caracteriza como venda, mas como licen&ccedil;a de uso, conforme o disposto no artigo 9&ordm; da Lei n&ordm; 9.609/1998 (Lei do&nbsp;</span>
        <span class="c3">Software</span><span class="c0">) e n&atilde;o poder&aacute; ser transferida a quem quer que seja, sem o consentimento pr&eacute;vio, expresso e espec&iacute;fico da Licenciante.</span>
    </p>
    <p class="c7">
        <span class="c0">Est&atilde;o inclu&iacute;das neste Termo, as atualiza&ccedil;&otilde;es peri&oacute;dicas do&nbsp;</span><span class="c3">software</span>
        <span class="c0">, o treinamento dos usu&aacute;rios da Licenciada, a ser ministrado no momento da instala&ccedil;&atilde;o do&nbsp;</span><span class="c3">software</span><span class="c0">.</span>
    </p>
    <p class="c7 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c2">Pre&ccedil;o e condi&ccedil;&otilde;es de pagamento</span></p>
    <p class="c7">
        <span class="c5">O Licenciado pagar&aacute; uma taxa de administra&ccedil;&atilde;o &agrave; Licenciante, pela aquisi&ccedil;&atilde;o da assinatura da licen&ccedil;a de uso do&nbsp;</span><span class="c12">software</span>
        <span class="c5">, conforme pre&ccedil;o e condi&ccedil;&otilde;es definidas no setor de Taxas.</span>
    </p>
    <p class="c7"><span class="c2">Obriga&ccedil;&otilde;es e Responsabilidades da Licenciante</span></p>
    <p class="c7"><span class="c0">A Licenciante assume as seguintes obriga&ccedil;&otilde;es:</span></p>
    <p class="c7">
        <span class="c0">I. Fornecer ao Licenciado o acesso ao&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            &nbsp;em perfeitas condi&ccedil;&otilde;es de uso e navega&ccedil;&atilde;o, com os devidos controles de seguran&ccedil;a contra reprodu&ccedil;&atilde;o indevida/n&atilde;o autorizada, bem como vedar o acesso por pessoas
            n&atilde;o cadastradas;
        </span>
    </p>
    <p class="c7">
        <span class="c0">II. Informar ao Licenciado caso o&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            &nbsp;fique inacess&iacute;vel, responsabilizando-se por usa corre&ccedil;&atilde;o e disponibilidade no prazo de 48 (quarenta e oito) horas, salvo quando os inconvenientes/interrup&ccedil;&otilde;es sejam resultado de problemas
            t&eacute;cnicos que porventura venham a ocorrer nos servi&ccedil;os p&uacute;blicos de telecomunica&ccedil;&otilde;es ou em outros servi&ccedil;os prestados por terceiros;
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            III. Responder por toda e qualquer reclama&ccedil;&atilde;o de terceiros em rela&ccedil;&atilde;o a propriedade intelectual dos produtos utilizados ou criados na presta&ccedil;&atilde;o dos servi&ccedil;os ora contratados.
        </span>
    </p>
    <p class="c7"><span class="c2">Exclus&atilde;o de responsabilidades</span></p>
    <p class="c7">
        <span class="c0">A E-COM COMUNICA&Ccedil;&Otilde;ES LTDA</span><span class="c2">&nbsp;</span>
        <span class="c0">
            &nbsp;n&atilde;o &eacute; respons&aacute;vel por danos causados em raz&atilde;o da ocorr&ecirc;ncia de casos fortuitos ou de for&ccedil;a maior, conforme previsto pelo artigo 393, do C&oacute;digo Civil. A suspens&atilde;o,
            ainda que parcial, do servi&ccedil;o de&nbsp;
        </span>
        <span class="c3">internet</span><span class="c0">&nbsp;ou telefonia &agrave; sede da </span><span class="c2">PLATAFORMA ECOMPAY</span><span class="c0">&nbsp;&eacute; considerado caso fortuito para fins deste Termo.</span>
    </p>
    <p class="c7">
        <span class="c0">A E-COM COMUNICA&Ccedil;&Otilde;ES LTDA &eacute; isenta de qualquer responsabilidade por danos causados atrav&eacute;s da utiliza&ccedil;&atilde;o do&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            &nbsp;por pessoas n&atilde;o autorizadas ou terceiros que n&atilde;o fa&ccedil;am parte do quadro de funcion&aacute;rios do Licenciado ou ainda casos de viola&ccedil;&atilde;o a direitos nos quais n&atilde;o exista culpa
            subjetiva da
        </span>
        <span class="c2">&nbsp;</span><span class="c0">E-COM COMUNICA&Ccedil;&Otilde;ES LTDA</span><span class="c2">.</span>
    </p>
    <p class="c7"><span class="c2">Obriga&ccedil;&otilde;es e Responsabilidades do Licenciado</span></p>
    <p class="c7"><span class="c0">O Licenciado assume as seguintes obriga&ccedil;&otilde;es e responsabilidades:</span></p>
    <p class="c7"><span class="c0">I. Utilizar o&nbsp;</span><span class="c3">software</span><span class="c0">&nbsp;de boa-f&eacute; e sem a inten&ccedil;&atilde;o de praticar atos il&iacute;citos ou causar danos a terceiros.</span></p>
    <p class="c7"><span class="c0">II. Renegociar com a Licenciante as condi&ccedil;&otilde;es de prazo e remunera&ccedil;&atilde;o, caso venha a ser alterada a licen&ccedil;a de uso ora contratada.</span></p>
    <p class="c7"><span class="c0">III. Pagar os pre&ccedil;os nas condi&ccedil;&otilde;es ajustadas, conforme Taxa, conforme definido nos Termos de Uso.</span></p>
    <p class="c7"><span class="c0">IV. Prover a Licenciante de todas as informa&ccedil;&otilde;es e documentos necess&aacute;rios para o bom desenvolvimento dos servi&ccedil;os.</span></p>
    <p class="c7"><span class="c0">V. Respeitar os direitos inerentes ao&nbsp;</span><span class="c3">software</span><span class="c0">, bem como as condi&ccedil;&otilde;es de uso convencionadas neste Termo.</span></p>
    <p class="c7">
        <span class="c0">VI. Realizar o acesso ao&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            &nbsp;apenas em computadores seguros e privados, que estejam livres de v&iacute;rus e que sejam confi&aacute;veis do ponto de vista de seguran&ccedil;a dos dados inseridos, n&atilde;o havendo responsabilidade por qualquer forma
            de indeniza&ccedil;&atilde;o da E-COM COMUNICA&Ccedil;&Otilde;ES LTDA pelo acesso em computadores p&uacute;blicos, como por exemplo em&nbsp;
        </span>
        <span class="c3">lan houses</span><span class="c0">.</span>
    </p>
    <p class="c7">
        <span class="c0">VII. Instalar e manter atualizados&nbsp;</span><span class="c3">softwares</span><span class="c0">&nbsp;anti-&nbsp;</span><span class="c3">spywares</span>
        <span class="c0">, anti-v&iacute;rus e outros que objetivem evitar a viola&ccedil;&atilde;o do computador que acessar&aacute; o&nbsp;</span><span class="c3">software</span><span class="c0">.</span>
    </p>
    <p class="c7"><span class="c2">Veda&ccedil;&otilde;es de utiliza&ccedil;&atilde;o do&nbsp;</span><span class="c11 c20 c31">software</span></p>
    <p class="c7">
        <span class="c0">&Eacute; vedado ao Licenciado</span><span class="c2">&nbsp;</span><span class="c0">sem a pr&eacute;via, espec&iacute;fica e escrita autoriza&ccedil;&atilde;o da E-COM COMUNICA&Ccedil;&Otilde;ES LTDA</span>
        <span class="c2">:</span>
    </p>
    <p class="c7">
        <span class="c0">I. sublicenciar, alugar, arrendar, emprestar, dar, dispor ou de qualquer outra forma ceder total ou parcialmente o direito de uso do&nbsp;</span><span class="c3">software</span><span class="c0">;</span>
    </p>
    <p class="c7">
        <span class="c0">II. copiar, alterar, adaptar, aprimorar, corrigir, traduzir, atualizar, desenvolver novas vers&otilde;es ou elaborar obras derivadas do&nbsp;</span><span class="c3">software</span>
        <span class="c0">&nbsp;ou de qualquer de suas partes ou componentes;</span>
    </p>
    <p class="c7">
        <span class="c0">III. desmontar, descompilar, fazer engenharia reversa ou, por meio de qualquer outra forma, utilizar o seu c&oacute;digo-fonte e/ou qualquer dado ou informa&ccedil;&atilde;o confidencial relativo ao&nbsp;</span>
        <span class="c3">software</span><span class="c0">&nbsp;para fins n&atilde;o autorizados pela E-COM COMUNICA&Ccedil;&Otilde;ES LTDA; e</span>
    </p>
    <p class="c7"><span class="c0">IV. remover os avisos de direitos autorais ou quaisquer outros relativos &agrave; direitos de propriedade contidos no&nbsp;</span><span class="c3">software</span><span class="c0">.</span></p>
    <p class="c7">
        <span class="c0">A ocorr&ecirc;ncia das hip&oacute;teses previstas na cl&aacute;usula anterior acarretar&aacute; ao Licenciado</span><span class="c2">&nbsp;</span>
        <span class="c0">
            a aplica&ccedil;&atilde;o de multa equivalente a 50 (cinquenta) vezes o valor mensal do produto assinado pelo Licenciado. A multa n&atilde;o obstar&aacute; indeniza&ccedil;&atilde;o suplementar a ser apurada judicialmente.
        </span>
    </p>
    <p class="c7"><span class="c2">Propriedade intelectual</span></p>
    <p class="c7">
        <span class="c0">Todos os direitos de propriedade intelectual e autoral sobre o&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            &nbsp;s&atilde;o de titularidade exclusiva da E-COM COMUNICA&Ccedil;&Otilde;ES LTDA, inclusive quaisquer aprimoramentos, corre&ccedil;&otilde;es, tradu&ccedil;&otilde;es, altera&ccedil;&otilde;es, novas vers&otilde;es ou obras
            derivadas, entre outras que o modificarem ou o alterarem.
        </span>
    </p>
    <p class="c7">
        <span class="c0">A Licenciante declara que a licen&ccedil;a aqui pactuada n&atilde;o infringe qualquer patente, direito autoral, segredo industrial ou quaisquer outros direitos de terceiros ou leis nacionais ou estrangeiros.</span>
    </p>
    <p class="c7">
        <span class="c0">O&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            , incluindo sua funcionalidade, c&oacute;digo, arquitetura e implementa&ccedil;&atilde;o, bem como a sua apar&ecirc;ncia visual constituem propriedade intelectual da E-COM COMUNICA&Ccedil;&Otilde;ES LTDA, de modo que este Termo
            n&atilde;o confere ao Licenciado&nbsp;nenhum direito de propriedade intelectual ou autoral sobre o&nbsp;
        </span>
        <span class="c3">software</span><span class="c0">.</span>
    </p>
    <p class="c7"><span class="c2">Descumprimento das obriga&ccedil;&otilde;es</span></p>
    <p class="c7">
        <span class="c0">
            A parte que se sentir prejudicada, em raz&atilde;o de qualquer providencia que a outra parte deixar de tomar ou o faz&ecirc;-lo de maneira inadequada, dever&aacute; comunicar a outra parte e solicitar a solu&ccedil;&atilde;o da
            quest&atilde;o no prazo de 15 (quinze) dias a contar do recebimento da comunica&ccedil;&atilde;o, sob pena de rescis&atilde;o do presente Termo.
        </span>
    </p>
    <p class="c7"><span class="c2">Penalidades</span></p>
    <p class="c7">
        <span class="c0">
            No caso de inadimplemento, por qualquer das partes, a qualquer das obriga&ccedil;&otilde;es assumidas neste Termo, a parte inadimplente pagar&aacute; uma multa no valor de 10% (dez por cento) sobre o pre&ccedil;o anual do Termo,
            no prazo de 5 (cinco) dias a contar da verifica&ccedil;&atilde;o do fato, ficando a parte credora com a op&ccedil;&atilde;o de rescindir o presente Termo, sem preju&iacute;zo do recebimento do valor da multa.
        </span>
    </p>
    <p class="c7">
        <span class="c0">
            Se a obriga&ccedil;&atilde;o inadimplida for de pagar, a parte inocente poder&aacute; cobrar juros morat&oacute;rios e corre&ccedil;&atilde;o monet&aacute;ria sobre o valor em mora, &agrave; taxa total e global de 2% (dois por
            cento) ao m&ecirc;s.
        </span>
    </p>
    <p class="c7"><span class="c0">A imposi&ccedil;&atilde;o de multas previstas neste Termo n&atilde;o eximir&aacute; as partes de quaisquer outras penalidades aplic&aacute;veis, decorrentes de lei ou decis&atilde;o judicial.</span></p>
    <p class="c7"><span class="c2">Confidencialidade</span></p>
    <p class="c7">
        <span class="c0">
            As Partes, por si, seus funcion&aacute;rios, assessores ou prepostos manter&atilde;o o mais completo e absoluto sigilo sobre as cl&aacute;usulas aqui contratadas, bem como quanto &agrave;s informa&ccedil;&otilde;es, dados e
            documentos que vierem a receber uma da outra ou por outra forma vier eventualmente venham a ter conhecimento ou acesso, ou que lhe venham a ser confiados, em fun&ccedil;&atilde;o deste Termo, salvo em atendimento e na
            extens&atilde;o exigida por eventual ordem judicial. Neste caso, a Parte comunicar&aacute; a outra imediatamente, para que a esta seja dada a oportunidade de se opor &agrave; revela&ccedil;&atilde;o.
        </span>
    </p>
    <p class="c7"><span class="c0">Em caso de viola&ccedil;&atilde;o dos deveres de confidencialidade, caber&aacute; indeniza&ccedil;&atilde;o por perdas e danos a ser apurada judicialmente.</span></p>
    <p class="c7"><span class="c2">Comunica&ccedil;&otilde;es</span></p>
    <p class="c7">
        <span class="c0">Qualquer aviso, requerimento, comunica&ccedil;&atilde;o ou interpela&ccedil;&atilde;o relacionada a estes termos dever&aacute; ser efetuada por escrito, seja por meio f&iacute;sico ou por </span>
        <span class="c5">eletr&ocirc;nico (</span><span class="c12">e-mail</span><span class="c5">) discriminado nos Termos de Uso.</span>
    </p>
    <p class="c7 c8"><span class="c13"></span></p>
    <p class="c7"><span class="c2">Mudan&ccedil;as no Termo</span></p>
    <p class="c7">
        <span class="c0">A E-COM COMUNICA&Ccedil;&Otilde;ES LTDA pode revisar e alterar este Termo periodicamente para refletir mudan&ccedil;as e adequa&ccedil;&otilde;es em seus neg&oacute;cios,&nbsp;</span><span class="c3">software</span>
        <span class="c0">
            , na legisla&ccedil;&atilde;o aplic&aacute;vel ou sempre que essa entender necess&aacute;rio. Caso sejam feitas altera&ccedil;&otilde;es, a E-COM COMUNICA&Ccedil;&Otilde;ES LTDA notificar&aacute; o Licenciado pelo&nbsp;
        </span>
        <span class="c3">e-mail</span><span class="c0">&nbsp;cadastrado.</span>
    </p>
    <p class="c7"><span class="c0">Cada nova vers&atilde;o do Termo entrar&aacute; em vigor 15 (quinze) dias ap&oacute;s a comunica&ccedil;&atilde;o dos Licenciados via&nbsp;</span><span class="c3">e-mail</span><span class="c0">.</span></p>
    <p class="c6">
        <span class="c0">A E-COM COMUNICA&Ccedil;&Otilde;ES LTDA deve manter dispon&iacute;vel em seu&nbsp;</span><span class="c3">site&nbsp;</span>
        <span class="c0">todas as vers&otilde;es antigas do Termo que j&aacute; estiveram em vigor.</span>
    </p>
    <p class="c6 c8"><span class="c0"></span></p>
    <p class="c6 c8"><span class="c0"></span></p>
    <p class="c7"><span class="c2">POL&Iacute;TICA DE PAGAMENTOS</span></p>
    <p class="c26">
        <span class="c17"><br /></span><span class="c5">Esta Pol&iacute;tica regula o acesso ou uso da </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;para transa&ccedil;&otilde;es efetuadas na moeda Real (BRL) entre Usu&aacute;rios que, na sua conta na Plataforma, declarem domic&iacute;lio no Brasil. </span>
    </p>
    <p class="c8 c26"><span class="c5"></span></p>
    <p class="c6">
        <span class="c5">A </span><span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, sistema de pagamentos exclusivo da E-COM COMUNICA&Ccedil;&Otilde;ES</span><span class="c1">&nbsp;</span><span class="c5">LTDA</span>
        <span class="c1">&nbsp;,</span>
        <span class="c5">
            &nbsp;serve como um facilitador de transa&ccedil;&otilde;es de pagamento entre Produtores e Compradores, permitindo que os Produtores possam focar naquilo que fazem de melhor: desenvolver produtos de qualidade.
        </span>
    </p>
    <p class="c6">
        <span class="c5">O </span><span class="c1">PLATAFORMA ECOMPAY </span><span class="c5">&eacute; regido por esta Pol&iacute;tica de Pagamentos, que integra os&nbsp;Termos de Uso da </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">. Voc&ecirc; deve ler de forma atenta e cuidadosa as condi&ccedil;&otilde;es a seguir, pois elas cont&ecirc;m informa&ccedil;&otilde;es importantes sobre acesso ou uso dos recursos relativos ao </span>
        <span class="c1">ECOMPAY.</span>
    </p>
    <p class="c6">
        <span class="c5">Ap&oacute;s aceitar esta Pol&iacute;tica, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;lhe concede automaticamente uma licen&ccedil;a de uso n&atilde;o exclusiva da plataforma. Os recursos contidos no </span><span class="c1">ECOMPAY</span>
        <span class="c5">
            &nbsp;s&atilde;o licenciados no estado em que se encontram. Eles podem ser modificados, substitu&iacute;dos ou removidos a qualquer momento, sem aviso pr&eacute;vio. Se voc&ecirc; n&atilde;o concordar com uma nova vers&atilde;o
            da Pol&iacute;tica de Pagamentos, voc&ecirc; deve cessar o uso da sua conta na
        </span>
        <span class="c1">PLATAFORMA ECOMPAY.</span>
        <span class="c5">
            &nbsp;Esta Pol&iacute;tica e suas respectivas atualiza&ccedil;&otilde;es prevalecem sobre quaisquer propostas, contratos, entendimentos anteriores e acordos, verbais ou escritos, que possam existir entre voc&ecirc; e a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">.</span>
    </p>
    <p class="c6 c8"><span class="c1"></span></p>
    <h4 class="c16"><span class="c12">VIS&Atilde;O GERAL</span></h4>
    <p class="c6">
        <span class="c1">A.</span><span class="c5">&nbsp;Esta Pol&iacute;tica regula o acesso ou uso do</span><span class="c1">&nbsp;ECOMPAY</span><span class="c5">&nbsp;para todas as transa&ccedil;&otilde;es efetuadas na </span>
        <span class="c1">PLATAFORMA ECOMPAY.</span>
    </p>
    <p class="c6">
        <span class="c1">A.</span>
        <span class="c5">
            &nbsp;Ao aceitar esta Pol&iacute;tica, voc&ecirc; declara que conhece e concorda com o seu conte&uacute;do e com as demais Pol&iacute;ticas aplic&aacute;veis, inclusive com a Pol&iacute;tica de Privacidade, que, apesar de
            apresentadas em textos separados, s&atilde;o partes integrantes dos&nbsp;Termos Gerais de Uso da PLATAFORMA ECOMPAY.
        </span>
    </p>
    <p class="c6">
        <span class="c1">B.</span>
        <span class="c5">
            &nbsp;O idioma oficial destes Termos &eacute; o portugu&ecirc;s. Qualquer vers&atilde;o destes Termos em outro idioma &eacute; uma tradu&ccedil;&atilde;o fornecida por cortesia. Em caso de conflito, a vers&atilde;o em
            portugu&ecirc;s deve prevalecer para todas as contrata&ccedil;&otilde;es.
        </span>
    </p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h4 class="c16"><span class="c12">1. OS SERVI&Ccedil;OS PRESTADOS PELO SISTEMA DE PAGAMENTOS ECOMPAY</span></h4>
    <p class="c8 c21"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">1.1. Escopo dos Servi&ccedil;os do ECOMPAY:</span><span class="c5">&nbsp;O sistema de pagamentos </span><span class="c1">ECOMPAY</span><span class="c5">&nbsp;&eacute; um servi&ccedil;o da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;que processa as instru&ccedil;&otilde;es de pagamento realizadas pelos Compradores e as liquida para os Usu&aacute;rios, conforme regras e procedimentos determinados nesta Pol&iacute;tica e nos Termos da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY.</span><span class="c5">&nbsp;Ao oferecer o </span><span class="c1">ECOMPAY</span><span class="c5">&nbsp;no Brasil, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;atua como uma institui&ccedil;&atilde;o de pagamento, sujeita &agrave; legisla&ccedil;&atilde;o e &agrave; regulamenta&ccedil;&atilde;o aplic&aacute;vel. O</span><span class="c1">&nbsp;ECOMPAY</span>
        <span class="c5">
            &nbsp;possibilita ao Usu&aacute;rio: (a) pagar pela compra de Produtos cadastrados na Plataforma, se for Comprador; (b) receber os valores relativos &agrave; comercializa&ccedil;&atilde;o de seus Produtos, se for Produtor; (c)
            pagar aos Afiliados ou Coprodutores os valores devidos pela comercializa&ccedil;&atilde;o de Produtos, se for Produtor; (d) receber do Produtor os valores relativos &agrave; comercializa&ccedil;&atilde;o de Produtos, se for
            Afiliado ou Coprodutor; e (e) comprar Produtos utilizando o saldo da conta de pagamento na
        </span>
        <span class="c1">PLATAFORMA ECOMPAY.</span>
    </p>
    <p class="c6">
        <span class="c1">1.2. Inexist&ecirc;ncia de servi&ccedil;os de institui&ccedil;&atilde;o financeira ou de cart&atilde;o de cr&eacute;dito:</span><span class="c5">&nbsp;Os servi&ccedil;os da </span><span class="c1">ECOMPAY</span>
        <span class="c5">
            &nbsp;n&atilde;o s&atilde;o e nem se destinam a ser compar&aacute;veis aos servi&ccedil;os oferecidos por institui&ccedil;&otilde;es financeiras ou por administradoras ou credenciadoras de cart&atilde;o de cr&eacute;dito. O
        </span>
        <span class="c1">&nbsp;ECOMPAY</span>
        <span class="c5">
            &nbsp;&eacute; apenas um facilitador de transa&ccedil;&otilde;es de pagamento entre os Usu&aacute;rios da Plataforma, sendo que o pagamento de transa&ccedil;&otilde;es ocorre por conta e ordem dos Usu&aacute;rios. A
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;n&atilde;o &eacute; uma institui&ccedil;&atilde;o financeira e n&atilde;o realiza opera&ccedil;&otilde;es de cr&eacute;dito diretamente.</span>
    </p>
    <p class="c6">
        <span class="c1">1.3. Servi&ccedil;os de Terceiros:</span><span class="c5">&nbsp;As transa&ccedil;&otilde;es realizadas com Produtos cadastrados na Plataforma somente podem ser pagas mediante os recursos contidos no</span>
        <span class="c1">&nbsp;ECOMPAY.</span><span class="c5">&nbsp;Para tanto, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode usar meios de pagamento de terceiros e empresas parceiras para receber pagamentos, pagar valores ou adiantar receb&iacute;veis das transa&ccedil;&otilde;es (como institui&ccedil;&otilde;es banc&aacute;rias,
            administradoras de cart&otilde;es de cr&eacute;dito e outras entidades de cr&eacute;dito). Nesses casos, podem existir termos e condi&ccedil;&otilde;es distintos que voc&ecirc; deve cumprir. A
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;n&atilde;o &eacute; respons&aacute;vel pela disponibilidade ou pela precis&atilde;o da presta&ccedil;&atilde;o destes Servi&ccedil;os de Terceiros.</span>
    </p>
    <p class="c6">
        <span class="c1">1.4. Oferecimento de Servi&ccedil;os de Terceiros adicionais:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;pode permitir que parceiros seus (como institui&ccedil;&otilde;es financeiras e correspondentes banc&aacute;rios) ofere&ccedil;am seus servi&ccedil;os de forma agregada aos Servi&ccedil;os do </span>
        <span class="c1">&nbsp;ECOMPAY.</span><span class="c5">&nbsp;Tamb&eacute;m nesses casos, podem existir termos e condi&ccedil;&otilde;es distintos que voc&ecirc; deve cumprir, e a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;n&atilde;o &eacute; respons&aacute;vel pela disponibilidade ou pela precis&atilde;o da presta&ccedil;&atilde;o destes Servi&ccedil;os de Terceiros.</span>
    </p>
    <p class="c6">
        <span class="c1">1.5. Tributos e Nota Fiscal</span><span class="c5">: A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;n&atilde;o &eacute; respons&aacute;vel pelo recolhimento dos tributos pelos quais os seus Usu&aacute;rios sejam os contribuintes ou respons&aacute;veis tribut&aacute;rios. Cabe aos Usu&aacute;rios recolher os tributos de
            que sejam sujeitos passivos, na forma da legisla&ccedil;&atilde;o aplic&aacute;vel. As obriga&ccedil;&otilde;es tribut&aacute;rias podem variar de acordo com sua situa&ccedil;&atilde;o espec&iacute;fica, por isso recomendamos
            que voc&ecirc; pesquise a respeito e consulte profissionais especializados para obter informa&ccedil;&otilde;es detalhadas.
        </span>
    </p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h4 class="c16"><span class="c27">2. O ACESSO E USO DO</span><span class="c31 c20 c33">&nbsp;ECOMPAY</span></h4>
    <p class="c21 c8"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">2.1. Acesso ao ECOMPAY:</span>
        <span class="c5">
            &nbsp;Cumpridos todos os requisitos de elegibilidade, cadastro e acesso previstos nos&nbsp;Termos Gerais da PLATAFORMA ECOMPAY, no momento em que o Produtor se cadastra na Plataforma, ele concorda e solicita que a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">: (a) receba o pre&ccedil;o pago pelo Comprador; (b) pague ao Afiliado a remunera&ccedil;&atilde;o estabelecida pelo Produtor, quando houver; (c) deduza a remunera&ccedil;&atilde;o devida &agrave; </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;pelo uso de seus recursos; e (d) pague o valor remanescente devido ao Produtor na sua conta de pagamento na </span><span class="c1">PLATAFORMA ECOMPAY.</span>
    </p>
    <p class="c6">
        <span class="c1">2.2. Confirma&ccedil;&atilde;o dos dados do Usu&aacute;rio:</span><span class="c5">&nbsp;Al&eacute;m das a&ccedil;&otilde;es previstas nos Termos, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;reserva-se o direito de utilizar todos os meios v&aacute;lidos para confirmar os dados fornecidos pelo Usu&aacute;rio para permitir o seu uso do </span><span class="c1">ECOMPAY</span>
        <span class="c5">, se entender necess&aacute;rio. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;tamb&eacute;m pode solicitar dados adicionais e documentos que julgar pertinentes, como informes de rendimento, atestados de titularidade de conta banc&aacute;ria e extratos banc&aacute;rios completos. A
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode ainda consultar bancos de dados de car&aacute;ter p&uacute;blico ou privado e bases de dados de restri&ccedil;&otilde;es mantidos por terceiros e bases de restri&ccedil;&otilde;es credit&iacute;cias. Ao aceitar esta
            Pol&iacute;tica, voc&ecirc; autoriza a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;a efetuar esses procedimentos.</span>
    </p>
    <p class="c6">
        <span class="c1">2.3. Uso do ECOMPAY:</span>
        <span class="c5">
            &nbsp;Voc&ecirc; se obriga a tomar todas as precau&ccedil;&otilde;es necess&aacute;rias para evitar que terceiros usem sua conta em seu nome. Voc&ecirc; se responsabiliza pelas transa&ccedil;&otilde;es comerciais, pagamentos e
            movimenta&ccedil;&otilde;es financeiras feitas em seu nome por terceiros, ainda que sem sua autoriza&ccedil;&atilde;o, se esses terceiros tiverem acesso, sem culpa exclusiva da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, &agrave; sua senha ou a outros dados seus.</span>
    </p>
    <p class="c6">
        <span class="c1">2.4. Sigilo banc&aacute;rio e prote&ccedil;&atilde;o de dados:</span><span class="c5">&nbsp;Em cumprimento &agrave; Pol&iacute;tica de Privacidade e &agrave;s demais leis aplic&aacute;veis, a </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;n&atilde;o pode fornecer ao Produtor quaisquer dados de Compradores relacionados a transa&ccedil;&otilde;es de pagamento, a opera&ccedil;&otilde;es realizadas ou a serem realizadas por Compradores na Plataforma, mesmo em
            caso de exclus&atilde;o do Produtor da Plataforma. Os dados de pagamento coletados por meio do
        </span>
        <span class="c1">ECOMPAY </span><span class="c5">n&atilde;o ficam, de forma alguma, armazenados na Plataforma, tampouco poder&atilde;o ser repassados ao Produtor, que n&atilde;o figura como Controlador destes dados.</span>
    </p>
    <h4 class="c16"><span class="c12">3. PROCESSAMENTO DE PAGAMENTO POR MEIO DO ECOMPAY</span></h4>
    <p class="c6">
        <span class="c1">3.1. ECOMPAY como forma exclusiva de pagamento na Plataforma:</span><span class="c5">&nbsp;As solu&ccedil;&otilde;es de pagamento do</span><span class="c1">&nbsp;ECOMPAY</span>
        <span class="c5">&nbsp;s&atilde;o destinadas exclusivamente aos Usu&aacute;rios da Plataforma. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;n&atilde;o realiza qualquer pagamento a Produtores que n&atilde;o aceitem o </span><span class="c1">ECOMPAY</span>
        <span class="c5">&nbsp;como forma de pagamento pelos Produtos que oferecerem ou a quaisquer terceiros. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;tamb&eacute;m n&atilde;o realiza pagamentos a Produtores cujas contas estejam inativas ou bloqueadas na Plataforma.</span>
    </p>
    <p class="c6">
        <span class="c1">3.2. Confirma&ccedil;&atilde;o de instru&ccedil;&atilde;o de pagamento:</span><span class="c5">&nbsp;Ao escolher realizar um pagamento por meio do </span><span class="c1">ECOMPAY</span>
        <span class="c5">
            , o Comprador deve fornecer todas as informa&ccedil;&otilde;es banc&aacute;rias solicitadas para concretiz&aacute;-lo. A instru&ccedil;&atilde;o do Comprador ser&aacute; considerada concedida de forma irrevog&aacute;vel e
            irretrat&aacute;vel quando a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;confirmar ao Comprador o recebimento, com sucesso, dos dados enviados relativos &agrave; transa&ccedil;&atilde;o.</span>
    </p>
    <p class="c6">
        <span class="c1">3.3. Realiza&ccedil;&atilde;o de pagamento por meio do ECOMPAY:</span><span class="c5">&nbsp;Uma vez que a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;receber a confirma&ccedil;&atilde;o de que a transa&ccedil;&atilde;o foi aprovada pelos participantes do arranjo de pagamento, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;deve disponibilizar os valores devidos ao Usu&aacute;rio na sua conta de pagamento mantida na Plataforma, ressalvadas as hip&oacute;teses em que a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;est&aacute; autorizada a suspender ou cancelar os pagamentos, na forma dos Termos e desta Pol&iacute;tica. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;deve disponibilizar esses valores tamb&eacute;m observando: (a) a eventual ocorr&ecirc;ncia de chargebacks ou pedidos de reembolso; (b) o transcurso dos prazos de garantia ou exerc&iacute;cio de direito de arrependimento
            pelo Comprador e (c) eventual plano de antecipa&ccedil;&atilde;o de receb&iacute;veis contratado pelo Produtor.
        </span>
    </p>
    <p class="c6">
        <span class="c1">3.4. Responsabilidade do Comprador pelos dados informados:</span>
        <span class="c5">
            &nbsp;O Comprador &eacute; o &uacute;nico respons&aacute;vel por todos os atos que praticar relativos &agrave; instru&ccedil;&atilde;o de pagamento. O Comprador deve fornecer informa&ccedil;&otilde;es verdadeiras, completas e
            exatas. A
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;n&atilde;o &eacute; respons&aacute;vel por quaisquer danos, patrimoniais ou extrapatrimoniais, resultantes de qualquer falha, inveracidade ou incompletude imput&aacute;vel ao Comprador no momento em que preencher as
            informa&ccedil;&otilde;es referentes &agrave; instru&ccedil;&atilde;o de pagamento.
        </span>
    </p>
    <p class="c6">
        <span class="c1">3.5. Solicita&ccedil;&atilde;o de reembolso:</span>
        <span class="c5">
            &nbsp;Se o Comprador realizar uma transa&ccedil;&atilde;o e se sentir lesado em rela&ccedil;&atilde;o &agrave; qualidade do Produto adquirido, ele pode enviar uma solicita&ccedil;&atilde;o de reembolso atrav&eacute;s do site da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">- dentro dos prazos de garantia ou de exerc&iacute;cio de direito de arrependimento aplic&aacute;veis &agrave; respectiva transa&ccedil;&atilde;o, observada a legisla&ccedil;&atilde;o aplic&aacute;vel. A </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;deve comunicar ao Produtor a respeito, que ter&aacute; 05 dias corridos para responder a reclama&ccedil;&atilde;o de forma justificada ou sanar o problema. Se o Produtor n&atilde;o entrar em contato nesse prazo, a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;pode reembolsar integralmente ao Comprador os valores pagos, cujo montante deve ser deduzido do saldo da conta de pagamento do Produtor. &nbsp;</span>
    </p>
    <p class="c6">
        <span class="c1">3.6. Colabora&ccedil;&atilde;o da PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;</span><span class="c1">em caso de solicita&ccedil;&atilde;o de reembolso:</span><span class="c5">&nbsp;Os Servi&ccedil;os do </span>
        <span class="c1">ECOMPAY</span>
        <span class="c5">
            &nbsp;referentes a disputas e reembolsos s&atilde;o prestados apenas para facilitar e acompanhar o processo de realiza&ccedil;&atilde;o da transa&ccedil;&atilde;o de pagamento entre Comprador e Produtor. Assim, ao utilizar o
        </span>
        <span class="c1">ECOMPAY</span><span class="c5">, voc&ecirc; reconhece que qualquer transa&ccedil;&atilde;o de pagamento, quando efetivada, ocorre diretamente entre o Produtor e o Comprador. A </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;n&atilde;o &eacute; parte da cadeia de fornecimento dos Produtos adquiridos, n&atilde;o podendo ser considerada fornecedora, prestadora, agente ou corretora.</span>
    </p>
    <h4 class="c16"><span class="c12">5. TAXAS DO USO DO ECOMPAY</span></h4>
    <p class="c21 c8"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">5.1. Taxas do Criador:</span><span class="c5">&nbsp;O uso dos recursos do </span><span class="c1">ECOMPAY</span>
        <span class="c5">
            &nbsp;pelo Criador est&aacute; sujeito ao pagamento de duas taxas: a Taxa de Licen&ccedil;a e a Taxa de Intermedia&ccedil;&atilde;o. Elas s&atilde;o cobradas somente por ocasi&atilde;o da venda dos Produtos, no momento que a
            transa&ccedil;&atilde;o &eacute; aprovada, quando essas taxas s&atilde;o deduzidas do valor final cobrado pelo Criador. Essas taxas s&atilde;o cobradas cumulativamente, conforme valores a seguir.
        </span>
    </p>
    <p class="c6">
        <span class="c1">5.2. Taxa de Licen&ccedil;a:</span>
        <span class="c5">&nbsp;&Eacute; um valor fixo, aplic&aacute;vel de acordo com a moeda de compra utilizada pelo Comprador na transa&ccedil;&atilde;o, conforme valores indicados abaixo:</span>
    </p>
    <h3 class="c16"><span class="c5">Taxa de Licen&ccedil;a</span></h3>
    <p class="c22"><span class="c5">Moeda: Real</span></p>
    <p class="c22"><span class="c5">Valor: R$ 2,00 (Dois reais)</span></p>
    <p class="c6 c8"><span class="c1"></span></p>
    <p class="c6">
        <span class="c1">5.3. Taxa de Intermedia&ccedil;&atilde;o:</span>
        <span class="c5">
            &nbsp;O valor da Taxa de Intermedia&ccedil;&atilde;o &eacute; fixo no percentual de 6,99% sobre o valor da venda, independentemente da forma de pagamento utilizada para a aquisi&ccedil;&atilde;o do infoproduto.
        </span>
    </p>
    <p class="c6">
        <span class="c1">5.4. Base de c&aacute;lculo da Taxa de Intermedia&ccedil;&atilde;o:</span>
        <span class="c5">
            &nbsp;Em qualquer dos casos previstos acima, a Taxa de Intermedia&ccedil;&atilde;o ser&aacute; aplicada sobre o valor final cobrado pelo Produtor a cada Comprador, j&aacute; considerando a convers&atilde;o do pre&ccedil;o da
            oferta para a moeda de compra utilizada pelo Comprador. Nos casos de Usu&aacute;rios domiciliados no Brasil, a Taxa de Antecipa&ccedil;&atilde;o de Receb&iacute;veis, conforme venha a ser aplic&aacute;vel nos termos da
            Cl&aacute;usula 5.6 abaixo, n&atilde;o integra a base de c&aacute;lculo para da Taxa de Intermedia&ccedil;&atilde;o.
        </span>
    </p>
    <p class="c6">
        <span class="c1">5.5. Taxa de Antecipa&ccedil;&atilde;o:</span>
        <span class="c5">
            &nbsp;Nas transa&ccedil;&otilde;es realizadas exclusivamente entre Usu&aacute;rios domiciliados no Brasil, &eacute; poss&iacute;vel ao Produtor solicitar a antecipa&ccedil;&atilde;o de receb&iacute;veis, nos termos da
            Se&ccedil;&atilde;o 9 abaixo. Para tanto, ser&aacute; cobrada do Produtor uma taxa de antecipa&ccedil;&atilde;o, que poder&aacute; consistir: (a) em uma taxa cobrada diretamente pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, no caso de (a) antecipa&ccedil;&atilde;o se dar por meio do pr&eacute;-pagamento das obriga&ccedil;&otilde;es pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;ou (b) na taxa de cess&atilde;o aplicada pelo eventual cession&aacute;rio dos receb&iacute;veis indicado pela </span><span class="c1">PLATAFORMA ECOMPAY.</span>
        <span class="c5">&nbsp;A taxa de antecipa&ccedil;&atilde;o ser&aacute; calculada com base no valor final da venda realizada pelo Produtor, e ser&aacute; informada pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;ao Produtor no momento da contrata&ccedil;&atilde;o de um Plano Antecipado (conforme definido na Cl&aacute;usula 9.2 abaixo) ou da solicita&ccedil;&atilde;o da antecipa&ccedil;&atilde;o pelo Produtor, conforme o caso.
        </span>
    </p>
    <p class="c6">
        <span class="c1">5.6. Custos Aplic&aacute;veis ao Afiliado e ao Comprador:</span><span class="c5">&nbsp;Os servi&ccedil;os do </span><span class="c1">ECOMPAY </span>
        <span class="c5">
            s&atilde;o gratuitos para acesso e uso por Compradores e Afiliados. No entanto, podem incidir cobran&ccedil;as decorrentes de opera&ccedil;&otilde;es financeiras realizadas por esses Usu&aacute;rios, como a
            antecipa&ccedil;&atilde;o de receb&iacute;veis pelos Afiliados ou o parcelamento ou uso de diferentes meios de pagamento pelos Compradores.
        </span>
    </p>
    <p class="c6">
        <span class="c1">5.7. Mudan&ccedil;as das Taxas:</span>
        <span class="c5">&nbsp;As taxas mencionadas nesta Se&ccedil;&atilde;o, seus respectivos tipos de pagamento, meios de pagamento e faturamento podem ser alterados a qualquer tempo, a exclusivo crit&eacute;rio da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, mediante notifica&ccedil;&atilde;o pr&eacute;via aos Usu&aacute;rios.</span>
    </p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h4 class="c16"><span class="c12">6. CONTA DE PAGAMENTO PARA TRANSA&Ccedil;&Otilde;ES EM REAIS ENTRE USU&Aacute;RIOS COM DOMIC&Iacute;LIO DECLARADO NO BRASIL</span></h4>
    <p class="c21 c8"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">6.1. Abertura de Conta de Pagamento:</span><span class="c5">&nbsp;Ap&oacute;s a realiza&ccedil;&atilde;o da primeira venda, o Criador autoriza a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;a abertura de uma Conta de Pagamento na modalidade pr&eacute;-paga de titularidade do Criador, mantida junto &agrave;</span><span class="c30">&nbsp;</span>
        <span class="c5">&nbsp;IUGU INSTITUI&Ccedil;&Atilde;O DE PAGAMENTO S.A, inscrita no CNPJ 15.111.975/0001-64, banco: 401; Ag&ecirc;ncia: 0001; Conta Corrente 4129655-0. A efetiva abertura da conta ser&aacute; aprovada pela </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;quando cumpridos todos os requisitos de elegibilidade, cadastro e acesso previstos nos&nbsp;Termos Gerais de Uso da </span><span class="c1">PLATAFORMA ECOMPAY.</span>
        <span class="c5">&nbsp;</span>
    </p>
    <p class="c6">
        <span class="c1">6.2. Encerramento da Conta de Pagamento:</span>
        <span class="c5">
            &nbsp;A conta de titularidade do Criador poder&aacute; ser encerrada por sua pr&oacute;pria iniciativa, e a qualquer momento, condicionado &agrave; inexist&ecirc;ncia de pend&ecirc;ncias como, mas n&atilde;o limitado a: (a)
            inconsist&ecirc;ncias e irregularidades nas informa&ccedil;&otilde;es cadastrais fornecidas &agrave;
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            ; (b) quest&otilde;es relativas &agrave; idoneidade do Criador; (c) reclama&ccedil;&otilde;es pendentes; (d) Transa&ccedil;&otilde;es pendentes, em processamento ou ainda n&atilde;o liquidadas, (v) eventuais
            obriga&ccedil;&otilde;es de pagamento vinculadas &agrave; Conta de Pagamento que estejam pendentes, tais como, mas n&atilde;o se limitando a d&eacute;bitos perante a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;ou qualquer empresa do grupo.</span>
    </p>
    <p class="c6">
        <span class="c1">6.3. Uso da Conta de Pagamento:</span><span class="c5">&nbsp;O Criador est&aacute; ciente e autoriza a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;a debitar de sua Conta de Pagamento as Taxas </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;que se aplicam nas movimenta&ccedil;&atilde;o da Conta, conforme informado na Cl&aacute;usula 5 (&quot;Taxas do uso do ECOMPAY&quot;) acima.</span>
    </p>
    <p class="c6">
        <span class="c1">6.4. Aporte de Valores na Conta de Pagamento:</span>
        <span class="c5">&nbsp;A &uacute;nica maneira de aportar valores dentro da Conta de Pagamento do Criador &eacute; por meio da realiza&ccedil;&atilde;o de vendas de Produtos dentro da plataforma.</span>
    </p>
    <p class="c6">
        <span class="c1">6.5. Intransferibilidade da Conta de Pagamento:</span>
        <span class="c5">
            &nbsp;A Conta de Pagamento do Criador &eacute; pessoal e intransfer&iacute;vel, sendo atribu&iacute;da a um &uacute;nico Criador. O Criador n&atilde;o poder&aacute; permitir o uso de sua Conta por quaisquer terceiros.
        </span>
    </p>
    <p class="c6">
        <span class="c1">6.6. Taxa de Administra&ccedil;&atilde;o por Inatividade:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode, a seu exclusivo crit&eacute;rio, cobrar uma taxa mensal a t&iacute;tulo de administra&ccedil;&atilde;o de conta inativa, equivalente a R$ 10,00 ou 5% do saldo em conta de pagamento do Usu&aacute;rio, prevalecendo o
            que for maior. Essa taxa pode incidir sobre o saldo total da conta inativa ao fim de cada m&ecirc;s, n&atilde;o sendo cobrada em casos de conta sem saldo ou saldo negativo. Para os fins desta taxa, considera-se uma conta inativa
            se, num per&iacute;odo superior a doze meses, o Usu&aacute;rio, cumulativamente: (a) n&atilde;o realizar nenhum saque, desde que haja valores dispon&iacute;veis; (b) n&atilde;o criar nenhum Produto;
        </span>
    </p>
    <p class="c6">
        <span class="c1">6.7. Prazos de disponibilidade e liquida&ccedil;&atilde;o das transa&ccedil;&otilde;es:</span>
        <span class="c5">
            &nbsp;Os pagamentos creditados na Conta de Pagamento do Criador estar&atilde;o dispon&iacute;veis assim que realizados os pagamentos pelos consumidores finais. Em compra parceladas, ser&aacute; disponibilizado o valor referente
            a cada parcela que for paga pelo consumidor final. Tais prazos poder&atilde;o ser alterados.
        </span>
    </p>
    <p class="c6">
        <span class="c1">6.8. Taxa de saque dos valores existentes na Conta de Pagamento:</span>
        <span class="c5">
            &nbsp;O saque de valores acumulados das vendas nas Contas de Pagamento est&aacute; sujeito (a) ao pagamento da taxa de saque, aplic&aacute;vel a cada opera&ccedil;&atilde;o de saque solicitada pelo Usu&aacute;rio; e (b) ao saque
            de um valor m&iacute;nimo por cada opera&ccedil;&atilde;o. O Usu&aacute;rio n&atilde;o pode solicitar o saque de valores acumulados das vendas realizadas se o seu saldo for inferior ao montante necess&aacute;rio para o pagamento
            da taxa de saque e o saque do valor m&iacute;nimo. Os valores da taxa de saque s&atilde;o de R$ 3,00 (tr&ecirc;s reais) por transfer&ecirc;ncia banc&aacute;ria e R$ 3,50 (Tr&ecirc;s reais e cinquenta centavos) por PIX,
            al&eacute;m de cobran&ccedil;a fixa de R$1,00 (Um real) por saque, e o valor m&iacute;nimo de saque &eacute; de R$ 10,00 (Dez reais) podem ser modificados a qualquer tempo pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, mediante aviso pr&eacute;vio.</span>
    </p>
    <p class="c6">
        <span class="c1">6.9. Suspeita de Fraude e Medidas de Preven&ccedil;&atilde;o na Conta de Pagamento:</span>
        <span class="c5">&nbsp;Os pagamentos efetuados e os saldos mantidos em Conta de Pagamento poder&atilde;o ser bloqueados total ou parcialmente, a crit&eacute;rio da </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, conforme descrito na Cl&aacute;usula 12 (&quot;Medidas de preven&ccedil;&atilde;o a fraudes e suspens&atilde;o de pagamentos&quot;) deste Termo.</span>
    </p>
    <p class="c6">
        <span class="c1">6.10. Bloqueio de Valores na Conta de Pagamento:</span>
        <span class="c5">&nbsp;Os valores eventualmente bloqueados em decorr&ecirc;ncia de pr&aacute;ticas irregulares ou das raz&otilde;es acima poder&atilde;o ser retirados da Conta de Pagamento e utilizados pela </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;para o cumprimento de ordem judicial, para o ressarcimento de eventuais custos, despesas ou danos causados &agrave; </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;ou a terceiros.</span>
    </p>
    <p class="c6">
        <span class="c1">6.11. Cancelamento ou estorno de transa&ccedil;&otilde;es na Conta de Pagamento:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;poder&aacute; cancelar e/ou estornar uma Transa&ccedil;&atilde;o de retirada de valores da Conta de um Usu&aacute;rio para Conta(s) Banc&aacute;ria(s) indicadas por este, em caso de suspeita de qualquer irregularidade,
            fraude ou qualquer outro ato do Usu&aacute;rio contr&aacute;rio &agrave;s disposi&ccedil;&otilde;es dos presentes Termos e Condi&ccedil;&otilde;es. A
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;tamb&eacute;m poder&aacute; suspender, limitar o acesso ou cancelar permanentemente o cadastro e a Conta de Pagamento do Criador.</span>
    </p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h4 class="c16"><span class="c12">7. PARCELAMENTO E FUNCIONALIDADES ADICIONAIS</span></h4>
    <p class="c21 c8"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">7.1. Funcionalidades adicionais:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;pode oferecer aos Usu&aacute;rios funcionalidades adicionais &agrave; facilita&ccedil;&atilde;o das transa&ccedil;&otilde;es de pagamento entre os Usu&aacute;rios mediante o </span>
        <span class="c1">ECOMPAY,</span>
        <span class="c5">
            &nbsp;como, entre outros: (a) a possibilidade de oferecer o parcelamento do pre&ccedil;o de oferta pelo Produto; (b) a possibilidade de o Usu&aacute;rio receber antecipadamente o valor de venda parcelada de determinado Produto,
            ou (c) a possibilidade de o Usu&aacute;rio antecipar o direito de sacar valores acumulados em sua conta de pagamento. O uso dessas funcionalidades adicionais pode estar sujeito ao pagamento de taxas adicionais pelo
            Usu&aacute;rio, o que ser&aacute; sempre informado antes da contrata&ccedil;&atilde;o da funcionalidade espec&iacute;fica.
        </span>
    </p>
    <h4 class="c16"><span class="c12">8. PAGAMENTOS RECORRENTES</span></h4>
    <p class="c6">
        <span class="c1">8.1. Cobran&ccedil;a recorrente:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;oferece aos Usu&aacute;rios a funcionalidade da cobran&ccedil;a recorrente, modalidade de cobran&ccedil;a programada e processada pelo </span><span class="c1">ECOMPAY</span>
        <span class="c5">
            &nbsp;que permite o acesso do Comprador ao Produto por tempo determinado, conforme definido pelo Produtor, mediante pagamentos peri&oacute;dicos. Essa modalidade &eacute; &uacute;til para Produtores que comercializam Produtos
            cuja disponibiliza&ccedil;&atilde;o &eacute; cont&iacute;nua. A cobran&ccedil;a recorrente pode tamb&eacute;m ser designada como &ldquo;assinatura&rdquo; no site e nas comunica&ccedil;&otilde;es da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">. Por meio da cobran&ccedil;a recorrente, o Comprador autoriza a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;previamente a efetuar a cobran&ccedil;a pela disponibilidade do Produto durante os per&iacute;odos pr&eacute;-definidos pelo Produtor. Uma vez que o Comprador escolhe essa forma de cobran&ccedil;a, ela n&atilde;o pode mais
            ser alterada para o mesmo Produto.
        </span>
    </p>
    <p class="c6">
        <span class="c1">8.2. Recursos para a cobran&ccedil;a recorrente:</span>
        <span class="c5">&nbsp;Ao optar pela cobran&ccedil;a recorrente, o Comprador deve manter os dados sobre o meio de pagamento escolhido para a assinatura atualizados em sua conta na </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">. Por isso, se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;ficar impossibilitada de cobrar os valores de assinatura em raz&atilde;o de o Comprador cancelar o meio de pagamento utilizado para acessar o Produto, ou por deixar de manter dados atualizados a respeito do meio de
            pagamento, a disponibilidade de acesso ao Produto pode ser suspensa at&eacute; a regulariza&ccedil;&atilde;o da cobran&ccedil;a recorrente. Assim, ao contratar uma assinatura, voc&ecirc; compreende e aceita que
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;n&atilde;o se obriga a indenizar voc&ecirc; por quaisquer danos, patrimoniais ou extrapatrimoniais, decorrentes da interrup&ccedil;&atilde;o do acesso ao Produto.</span>
    </p>
    <h4 class="c16"><span class="c12">9. PAGAMENTO UTILIZANDO O SALDO DA CONTA ECOMPAY</span></h4>
    <p class="c6">
        <span class="c1">9.1. Compras com saldo da conta na PLATAFORMA ECOMPAY:</span><span class="c5">&nbsp;Um Usu&aacute;rio que &eacute; Produtor ou Afiliado pode usar o saldo da sua conta de pagamento na </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;para comprar um Produto. Se voc&ecirc; usar o seu saldo na </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            , os valores correspondentes &agrave; compra efetuada ser&atilde;o deduzidos dos valores dispon&iacute;veis para resgate resultantes das suas vendas. Se o saldo na sua conta n&atilde;o for suficiente para completar a compra do
            Produto desejado, a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;n&atilde;o aprovar&aacute; a transa&ccedil;&atilde;o. O saldo da conta na </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;somente pode ser utilizado para transa&ccedil;&otilde;es realizadas em BRL entre Usu&aacute;rios que, em suas contas na </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, declarem ambos estarem domiciliados no Brasil.</span>
    </p>
    <p class="c6">
        <span class="c1">9.2. Limite di&aacute;rio:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;pode, a seu crit&eacute;rio, estabelecer limites di&aacute;rios para o uso do saldo da conta do Usu&aacute;rio na </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;para pagamento de Produtos. Se o valor do Produto desejado for superior ao limite aplic&aacute;vel, ou se voc&ecirc; n&atilde;o tiver saldo suficiente para efetuar uma compra espec&iacute;fica, voc&ecirc; pode pagar o
            valor restante usando cart&atilde;o de cr&eacute;dito como meio de pagamento.
        </span>
    </p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h4 class="c16"><span class="c12">10. CART&Atilde;O DE CR&Eacute;DITO E ANTECIPA&Ccedil;&Atilde;O DE RECEB&Iacute;VEIS PARA TRANSA&Ccedil;&Otilde;ES EM REAIS ENTRE USU&Aacute;RIOS COM DOMIC&Iacute;LIO DECLARADO NO BRASIL</span></h4>
    <p class="c21 c8"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">10.1. Antecipa&ccedil;&atilde;o de receb&iacute;veis:</span><span class="c5">&nbsp;Ao cadastrar um Produto na </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, voc&ecirc; escolher&aacute;, dentre as op&ccedil;&otilde;es oferecidas a voc&ecirc; pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, aquela que melhor se adequar &agrave;s suas necessidades. Os pagamentos ser&atilde;o realizados conforme prazos contratados por voc&ecirc;.</span>
    </p>
    <p class="c6">
        <span class="c1">10.2. Solicita&ccedil;&atilde;o da antecipa&ccedil;&atilde;o de receb&iacute;veis:</span>
        <span class="c5">&nbsp;Caso voc&ecirc;: (a) contrate qualquer plano de pagamento estabelecido dentre as op&ccedil;&otilde;es disponibilizadas pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;que preveja o recebimento dos pagamentos antes do prazo previstos nas regras do arranjo de pagamento (&quot;Plano Antecipado&rdquo;), ou (b) solicite, ap&oacute;s a realiza&ccedil;&atilde;o da transa&ccedil;&atilde;o, de
            pagamento dos receb&iacute;veis antes dos respectivos prazos de pagamento acordados, voc&ecirc; reconhece que tal fato configura a solicita&ccedil;&atilde;o de antecipa&ccedil;&atilde;o de seus receb&iacute;veis relativos
            &agrave;s transa&ccedil;&otilde;es aplic&aacute;veis. Para formalizar essas opera&ccedil;&otilde;es, voc&ecirc; concorda em atender eventuais questionamentos da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;para valida&ccedil;&atilde;o das transa&ccedil;&otilde;es comerciais. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode solicitar o envio de documentos ao Usu&aacute;rio, podendo adotar os procedimentos que entender necess&aacute;rios para registrar, confirmar e formalizar as opera&ccedil;&otilde;es. O Usu&aacute;rio autoriza a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;a efetuar tais procedimentos.</span>
    </p>
    <p class="c6">
        <span class="c1">10.2.1.</span>
        <span class="c5">
            &nbsp;Ao contratar um Plano Antecipado, voc&ecirc; poder&aacute;, a seu crit&eacute;rio e com base no artigo 1&ordm; da Lei 13.455, de 26 de junho de 2017, optar por aumentar o pre&ccedil;o do Produto a ser cobrado do Comprador
            no caso de compras parceladas, de forma que a taxa de cess&atilde;o aplicada na aquisi&ccedil;&atilde;o dos receb&iacute;veis pelo terceiro que a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;venha a determinar seja inclu&iacute;da no pre&ccedil;o do Produto. Nesta hip&oacute;tese, voc&ecirc; reconhece que o pre&ccedil;o do Produto, para todos os fins legais e tribut&aacute;rios, ser&aacute; o pre&ccedil;o
            final cobrado do Comprador, conforme informado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;a voc&ecirc;.</span>
    </p>
    <p class="c6">
        <span class="c1">10.3. Forma da antecipa&ccedil;&atilde;o de receb&iacute;veis:</span>
        <span class="c5">&nbsp;A antecipa&ccedil;&atilde;o de receb&iacute;veis pode ocorrer mediante diferentes estruturas jur&iacute;dicas, a exclusivo crit&eacute;rio da </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, como, por exemplo, (a) sob a forma de pr&eacute;-pagamento a ser realizado pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;ou seus parceiros, ou (b) sob a forma de opera&ccedil;&otilde;es de cess&atilde;o de direitos credit&oacute;rios a terceiros que a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;venha a determinar (inclusive fundos de investimento), no caso de transa&ccedil;&otilde;es em moeda corrente nacional (isto &eacute;, em Reais). A opera&ccedil;&atilde;o de cess&atilde;o de receb&iacute;veis &eacute; um
            meio de viabilizar a antecipa&ccedil;&atilde;o dos seus pagamentos e n&atilde;o resultar&aacute; em qualquer obriga&ccedil;&atilde;o ou taxa adicional a voc&ecirc; al&eacute;m daquelas j&aacute; previstas na Pol&iacute;tica de
            Pagamentos vigente.
        </span>
    </p>
    <p class="c6">
        <span class="c1">10.4. Antecipa&ccedil;&atilde;o de receb&iacute;veis mediante cess&atilde;o de direitos credit&oacute;rios:</span>
        <span class="c5">&nbsp;Se a opera&ccedil;&atilde;o for estruturada sob a forma de cess&atilde;o de direitos credit&oacute;rios, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode nomear o terceiro cession&aacute;rio, a seu exclusivo crit&eacute;rio da, a partir da solicita&ccedil;&atilde;o da antecipa&ccedil;&atilde;o de receb&iacute;veis pelo Usu&aacute;rio (podendo o terceiro, inclusive,
            consistir em fundo de investimento). Essa cess&atilde;o implicar&aacute; a transfer&ecirc;ncia definitiva da propriedade dos direitos credit&oacute;rios ao terceiro determinado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            , de modo que esses direitos deixar&atilde;o de fazer parte do patrim&ocirc;nio do Usu&aacute;rio a partir da confirma&ccedil;&atilde;o do pedido de antecipa&ccedil;&atilde;o de receb&iacute;veis pelo Usu&aacute;rio, o que, no
            caso da op&ccedil;&atilde;o pela contrata&ccedil;&atilde;o de um Plano Antecipado, j&aacute; ocorre no momento da confirma&ccedil;&atilde;o da transa&ccedil;&atilde;o de venda. Assim, ao solicitar a antecipa&ccedil;&atilde;o de
            receb&iacute;veis, voc&ecirc; declara compreender e aceitar que a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;poder&aacute; promover a cess&atilde;o dos receb&iacute;veis oriundos das suas vendas de Produtos mediante cart&atilde;o de cr&eacute;dito para as institui&ccedil;&otilde;es parceiras da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;que possibilitam o oferecimento dessa opera&ccedil;&atilde;o de antecipa&ccedil;&atilde;o, sem coobriga&ccedil;&atilde;o da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">.</span>
    </p>
    <p class="c6"><span class="c1">10.4.1.</span><span class="c5">&nbsp;Para a cess&atilde;o de receb&iacute;veis, nos termos da Cl&aacute;usula 9.4 acima, devem ser observadas as seguintes condi&ccedil;&otilde;es:</span></p>
    <p class="c6">
        <span class="c1">10.4.1.1. Cl&aacute;usula Mandato:</span>
        <span class="c5">
            &nbsp;O Produtor, exclusivamente para fins das opera&ccedil;&otilde;es de antecipa&ccedil;&atilde;o, conforme sua solicita&ccedil;&atilde;o de antecipa&ccedil;&atilde;o de receb&iacute;veis, constitui, neste ato, de forma
            irrevog&aacute;vel e irretrat&aacute;vel, a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;sua bastante procuradora para, em seu nome e por sua conta, ceder, transferir definitivamente e negociar os termos do referido repasse antecipado junto ao terceiro determinado pela </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;podendo, inclusive, assinar em seu nome todo e qualquer documento necess&aacute;rio para o pleno exerc&iacute;cio dos poderes ora outorgados, incluindo, sem limita&ccedil;&atilde;o, assinar termos de cess&atilde;o e aceite
            e/ou qualquer outro documento, bem como dar quita&ccedil;&atilde;o do pagamento do pre&ccedil;o de aquisi&ccedil;&atilde;o pela cess&atilde;o de receb&iacute;veis junto ao terceiro determinado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">;</span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.2. Cess&atilde;o de Receb&iacute;veis:</span>
        <span class="c5">&nbsp;A opera&ccedil;&atilde;o obrigatoriamente ocorrer&aacute; por meio de cess&atilde;o dos receb&iacute;veis j&aacute; existentes pelo Produtor, representados pela </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, nos termos do mandato outorgado no item (a) acima, ao terceiro determinado pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;(inclusive fundos de investimento), o que implicar&aacute; na transfer&ecirc;ncia definitiva da propriedade dos receb&iacute;veis ao terceiro determinado pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, deixando os referidos receb&iacute;veis cedidos de fazer parte do seu patrim&ocirc;nio ou ativo;</span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.3. Presta&ccedil;&atilde;o de Informa&ccedil;&otilde;es aos adquirentes dos Receb&iacute;veis:</span>
        <span class="c5">
            &nbsp;Ao optar pela antecipa&ccedil;&atilde;o de receb&iacute;veis, o Usu&aacute;rio se obriga a prestar todas as informa&ccedil;&otilde;es que forem solicitadas pelos cession&aacute;rios dos receb&iacute;veis, seja diretamente
            ou por interm&eacute;dio da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY.</span>
        <span class="c5">
            &nbsp;Essas informa&ccedil;&otilde;es podem envolver dados pessoais do Usu&aacute;rio, dados das transa&ccedil;&otilde;es comerciais efetuadas pelo Usu&aacute;rio e das opera&ccedil;&otilde;es executadas pelo Usu&aacute;rio na
            Plataforma.
        </span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.4. Ades&atilde;o a Planos Antecipados:</span><span class="c5">&nbsp;Caso o Produtor opte pela contrata&ccedil;&atilde;o de um Plano Antecipado, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;analisar&aacute;, sempre que necess&aacute;rio, os receb&iacute;veis que dever&atilde;o ser antecipados e informar&aacute; se a opera&ccedil;&atilde;o poder&aacute; ser realizada, conforme seus crit&eacute;rios de
            avalia&ccedil;&atilde;o, e creditar&aacute; para o Produtor o valor no prazo acordado com o mesmo, j&aacute; deduzido o pre&ccedil;o da cess&atilde;o e demais valores devidos em raz&atilde;o desta Pol&iacute;tica e dos Termos de
            Uso da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            , ainda que autorize a cess&atilde;o de receb&iacute;veis, poder&aacute; realizar a opera&ccedil;&atilde;o somente para parte dos receb&iacute;veis, conforme seus crit&eacute;rios de avalia&ccedil;&atilde;o de risco, sendo que
            os receb&iacute;veis n&atilde;o cedidos poder&atilde;o ser repassados ao Produtor por meio de antecipa&ccedil;&atilde;o direta pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">;</span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.5. Notifica&ccedil;&atilde;o:</span><span class="c5">&nbsp;Desde j&aacute;, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;reconhece, para todos os fins de fato e de direito, que, caso escolha realizar o adiantamento dos receb&iacute;veis ao Produtor por meio de cess&atilde;o dos receb&iacute;veis, ser&aacute; considerada notificada nos termos
            e para os efeitos do artigo 290 do C&oacute;digo Civil;
        </span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.6. Pre&ccedil;o da Cess&atilde;o:</span><span class="c5">&nbsp;No momento em que o Produtor optar pela antecipa&ccedil;&atilde;o de receb&iacute;veis, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;informar&aacute;, conforme o disposto na Cl&aacute;usula 11.2 acima, o pre&ccedil;o da cess&atilde;o, que levar&aacute; em conta crit&eacute;rios diversos, tais como valor a ser cedido, prazo de repasse dos
            receb&iacute;veis cedidos, entre outros;
        </span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.7. Valida&ccedil;&atilde;o da Ades&atilde;o ao Plano Antecipado:</span>
        <span class="c5">
            &nbsp;Para a formaliza&ccedil;&atilde;o da contrata&ccedil;&atilde;o do Plano Antecipado ou da realiza&ccedil;&atilde;o de antecipa&ccedil;&atilde;o de receb&iacute;veis mediante solicita&ccedil;&atilde;o do Produtor, o Produtor
            concorda em atender aos Termos e a Pol&iacute;tica de Privacidade da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;poder&aacute; realizar a grava&ccedil;&atilde;o dos telefonemas, assim como solicitar o envio de documentos de Produtores, podendo adotar os procedimentos que entender necess&aacute;rios para registrar, confirmar e
            formalizar o Plano Antecipado, sendo certo que o Produtor desde j&aacute; autoriza a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;a efetuar tais procedimentos;</span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.8. Altera&ccedil;&atilde;o de Plano:</span><span class="c5">&nbsp;Quando o Produtor optar por n&atilde;o continuar com o Plano Antecipado, dever&aacute; comunicar &agrave; central de atendimento da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;de tal decis&atilde;o, responsabilizando-se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;por efetuar a mudan&ccedil;a no seu cadastro dentro do prazo por ela estipulado;</span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.9. Responsabilidade pelos Receb&iacute;veis Cedidos:</span>
        <span class="c5">
            &nbsp;Nas opera&ccedil;&otilde;es de cess&atilde;o aqui tratadas, o Produtor desde j&aacute; reconhece e aceita que &eacute; respons&aacute;vel pela boa formaliza&ccedil;&atilde;o, correta constitui&ccedil;&atilde;o,
            exist&ecirc;ncia, liquidez e certeza dos receb&iacute;veis cedidos, bem como pelos estornos, d&eacute;bitos e cancelamentos ocorridos com rela&ccedil;&atilde;o a tais receb&iacute;veis, devendo reembolsar a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;e/ou o terceiro cession&aacute;rio em caso de estorno, d&eacute;bito, contesta&ccedil;&atilde;o ou cancelamento dos receb&iacute;veis cedidos, declarando que seus receb&iacute;veis est&atilde;o livres e desimpedidos para
            realiza&ccedil;&atilde;o das opera&ccedil;&otilde;es de cess&atilde;o aqui dispostas;
        </span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.10. Veda&ccedil;&atilde;o de Negocia&ccedil;&atilde;o de Receb&iacute;veis com Terceiros:</span>
        <span class="c5">
            &nbsp;Ao solicitar a antecipa&ccedil;&atilde;o de receb&iacute;veis (inclusive por meio da contrata&ccedil;&atilde;o do Plano Antecipado), o Usu&aacute;rio declara estar ciente e concorda que n&atilde;o pode mais
            negoci&aacute;-los a terceiros em geral, ficando impedido de ced&ecirc;-los, alien&aacute;-los ou d&aacute;-los em garantia, fato esse que poder&aacute; ser refletido nas entidades registradoras pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            . Assim, ao solicitar a antecipa&ccedil;&atilde;o de receb&iacute;veis, voc&ecirc; reconhece e concorda que n&atilde;o poder&aacute;, em qualquer hip&oacute;tese, contratar opera&ccedil;&otilde;es de cr&eacute;dito perante
            institui&ccedil;&otilde;es financeiras concedendo como garantia os receb&iacute;veis constitu&iacute;dos ou a constituir decorrentes das transa&ccedil;&otilde;es realizadas pela Plataforma. Se voc&ecirc; negociar os
            receb&iacute;veis cedidos, podem ser aplicadas medidas como (a) o bloqueio dos valores transferidos ou a transferir para sua conta de pagamento, at&eacute; o limite da opera&ccedil;&atilde;o de cr&eacute;dito contratada pelo
            Usu&aacute;rio e (b) o bloqueio do Usu&aacute;rio da Plataforma.
        </span>
    </p>
    <p class="c6">
        <span class="c1">10.4.1.11. Descontinuidade:</span>
        <span class="c5">
            &nbsp;As opera&ccedil;&otilde;es de cess&atilde;o aqui estipuladas referentes a determinadas transa&ccedil;&otilde;es somente poder&atilde;o ser descontinuadas pelo Produtor caso este opte pela substitui&ccedil;&atilde;o ou
            cancelamento do Plano Antecipado na mesma data da realiza&ccedil;&atilde;o da respectiva transa&ccedil;&atilde;o e at&eacute; o hor&aacute;rio a ser divulgado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, sendo certo que, em qualquer hip&oacute;tese, referida solicita&ccedil;&atilde;o de descontinuidade n&atilde;o afetar&aacute; eventuais cess&otilde;es j&aacute; efetuadas.</span>
    </p>
    <p class="c6">
        <span class="c1">10.5. Resolu&ccedil;&atilde;o de Cess&atilde;o:</span><span class="c5">&nbsp;Caso o terceiro adquirente dos receb&iacute;veis cedidos pelo Produtor, conforme indicado pela </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            , nos termos dispostos nesta cl&aacute;usula, n&atilde;o receba a totalidade ou parte do valor integral dos receb&iacute;veis cedidos nas respectivas datas de vencimento, exclusivamente em raz&atilde;o de hip&oacute;teses de
            resolu&ccedil;&atilde;o de cess&atilde;o previstas no instrumento de Condi&ccedil;&otilde;es Gerais de Promessa de Cess&atilde;o e Aquisi&ccedil;&atilde;o de Direitos Credit&oacute;rios e Outras Aven&ccedil;as, o Produtor
            pagar&aacute; ao terceiro adquirente o pre&ccedil;o de resolu&ccedil;&atilde;o da cess&atilde;o. Caso o terceiro adquirente dos receb&iacute;veis cedidos pelo Produtor, conforme indicado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            , nos termos dispostos nesta cl&aacute;usula, n&atilde;o receba a totalidade ou parte do valor integral dos receb&iacute;veis cedidos nas respectivas datas de vencimento, exclusivamente em raz&atilde;o da exist&ecirc;ncia de
            quaisquer &ocirc;nus, gravames ou encargos sobre os respectivos receb&iacute;veis cedidos do Produtor constitu&iacute;dos posteriormente &agrave; cess&atilde;o ao terceiro cession&aacute;rio, o Produtor dever&aacute; indenizar o
            terceiro cession&aacute;rio e/ou &agrave;
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, conforme aplic&aacute;vel, podendo tal indeniza&ccedil;&atilde;o ocorrer por meio de (i) opera&ccedil;&otilde;es de compensa&ccedil;&atilde;o com valores eventualmente devidos pela </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;ao Produtor oriundos ou n&atilde;o de transa&ccedil;&otilde;es; (ii) ajuste a d&eacute;bito pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;na agenda financeira do Produtor; (iii) opera&ccedil;&otilde;es de estorno ou lan&ccedil;amento a d&eacute;bito pela </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;na agenda financeira ou no domic&iacute;lio banc&aacute;rio do Produtor; (iv) qualquer outro ato ou formalidade legal ou documental, utilizando-se, para tanto, inclusive de limites de cr&eacute;dito concedidos em referida
            conta banc&aacute;ria, arcando o Produtor, ainda, com os custos e despesas decorrentes da cobran&ccedil;a. O Produtor, desde j&aacute;, concorda e autoriza que a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;realize tais opera&ccedil;&otilde;es para pagamento da indeniza&ccedil;&atilde;o, nos termos desta Cl&aacute;usula. O Produtor concorda que a cess&atilde;o dos receb&iacute;veis ser&aacute; registrada em sistema de
            registro, observado que tal registro ser&aacute; operacionalizado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, conforme procedimentos operacionais da entidade registradora para o referido registro.</span>
    </p>
    <p class="c6">
        <span class="c1">10.7. Encerramento da antecipa&ccedil;&atilde;o de receb&iacute;veis:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;reserva-se o direito de cessar o oferecimento dos servi&ccedil;os de antecipa&ccedil;&atilde;o de receb&iacute;veis a qualquer momento.</span>
    </p>
    <h4 class="c16"><span class="c12">10. SAQUE DOS VALORES ACUMULADOS NAS CONTAS DE PAGAMENTO</span></h4>
    <p class="c6">
        <span class="c1">11.1. Prazos para saque:</span>
        <span class="c5">
            &nbsp;Os Produtores cadastrados e qualificados na Plataforma podem solicitar o resgate dos valores acumulados das vendas realizadas: (a) por padr&atilde;o, ap&oacute;s o prazo de 30 dias contados da data em que a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;receber a confirma&ccedil;&atilde;o de que a transa&ccedil;&atilde;o foi aprovada pelos participantes do arranjo de pagamento; ou (b) caso o Produtor acorde outro prazo de pagamento com a </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, ap&oacute;s o decorrer do prazo acordado com a </span><span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">, contado da data em que a </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;receber a confirma&ccedil;&atilde;o de que a transa&ccedil;&atilde;o foi aprovada pelos participantes do arranjo de pagamento. A </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;pode deixar de contabilizar valores de vendas que sejam afetadas por chargebacks, reclama&ccedil;&otilde;es ou contesta&ccedil;&otilde;es referentes &agrave;s transa&ccedil;&otilde;es de pagamento.</span>
    </p>
    <p class="c6">
        <span class="c1">11.1.1.</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;n&atilde;o est&aacute; obrigada, em hip&oacute;tese alguma, a contestar os pedidos de chargeback apresentados pelos Compradores e deduzir&aacute; os valores daqueles devidos aos Produtores e Afiliados. Eventuais
            contesta&ccedil;&otilde;es realizadas por mera liberalidade, n&atilde;o obrigam a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;em casos futuros.</span>
    </p>
    <p class="c6">
        <span class="c1">11.2. Taxa de saque e valor m&iacute;nimo:</span>
        <span class="c5">
            &nbsp;Os requisitos m&iacute;nimos para saque dos valores existentes nas contas de Pagamento est&atilde;o mencionados na Cl&aacute;usula 6 (&quot;Conta de Pagamento para transa&ccedil;&otilde;es em reais entre usu&aacute;rios
            com domic&iacute;lio declarado no Brasil&quot;) desta Pol&iacute;tica.
        </span>
    </p>
    <p class="c6">
        <span class="c1">11.3. Pagamentos efetuados ap&oacute;s a mudan&ccedil;a de domic&iacute;lio do Usu&aacute;rio:</span>
        <span class="c5">
            &nbsp;Todas as solicita&ccedil;&otilde;es de compra e consequentes transa&ccedil;&otilde;es de pagamento ser&atilde;o feitas de acordo com o domic&iacute;lio do Usu&aacute;rio, a moeda e a empresa contratante. Em caso de
            altera&ccedil;&atilde;o do domic&iacute;lio, o Usu&aacute;rio deve atualizar imediatamente o seu cadastro e informar conta banc&aacute;ria mantida no novo pa&iacute;s de domic&iacute;lio. O saldo at&eacute; ent&atilde;o
            existente na conta de pagamento do Usu&aacute;rio, antes da altera&ccedil;&atilde;o, somente pode ser sacado, no todo ou em parte, mediante a conta banc&aacute;ria mantida previamente informada pelo Usu&aacute;rio.
        </span>
    </p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h4 class="c16"><span class="c12">12. MEDIDAS DE PREVEN&Ccedil;&Atilde;O A FRAUDES E SUSPENS&Atilde;O DE PAGAMENTOS</span></h4>
    <p class="c21 c8"><span class="c14"></span></p>
    <p class="c6">
        <span class="c1">12.1. Irregularidades na conta do Usu&aacute;rio:</span><span class="c5">&nbsp;Por for&ccedil;a da regulamenta&ccedil;&atilde;o aplic&aacute;vel, a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;pode precisar efetuar verifica&ccedil;&otilde;es sobre dados fornecidos pelos Usu&aacute;rios antes de autorizar o uso do </span><span class="c1">ECOMPAY</span>
        <span class="c5">&nbsp;para procedimentos como, entre outros, resgate de saldo, uso do saldo para compras ou antecipa&ccedil;&atilde;o de receb&iacute;veis. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;informar&aacute; os Usu&aacute;rios que estiverem com cadastro irregular ou incompleto. A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode bloquear qualquer opera&ccedil;&atilde;o de saque e suspender o acesso a links de vendas do Usu&aacute;rio se o Usu&aacute;rio n&atilde;o providenciar os ajustes necess&aacute;rios em 30 dias contados da
            notifica&ccedil;&atilde;o da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;a respeito.</span>
    </p>
    <p class="c6">
        <span class="c1">12.2. Suspeitas de fraude:</span>
        <span class="c5">
            &nbsp;Todas as transa&ccedil;&otilde;es de pagamento efetuadas mediante o ECOMPAY s&atilde;o verificadas pelos mecanismos de gest&atilde;o de risco integrados &agrave; Plataforma, e podem ser recusadas sempre que a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;entender que h&aacute; ind&iacute;cios de fraude aos meios de pagamento, de infra&ccedil;&atilde;o &agrave; lei ou aos&nbsp;Termos da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">.</span>
    </p>
    <p class="c6">
        <span class="c1">12.3. Medidas preventivas relativas a transa&ccedil;&otilde;es de pagamento:</span><span class="c5">&nbsp;A </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;pode recusar a aprova&ccedil;&atilde;o de uma transa&ccedil;&atilde;o, bloquear a realiza&ccedil;&atilde;o de saques ou bloquear contas de pagamento, a qualquer tempo, mesmo ap&oacute;s a confirma&ccedil;&atilde;o de
            pagamento de uma transa&ccedil;&atilde;o, e independentemente de ter sido ou n&atilde;o aberta uma disputa pelos Usu&aacute;rios, nas seguintes hip&oacute;teses, sem preju&iacute;zo de outras: (a) se a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;entender que h&aacute; ind&iacute;cios de que o Usu&aacute;rio, o Produto ou a transa&ccedil;&atilde;o est&atilde;o em desacordo com os Termos da </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">; (b) se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;constatar que as informa&ccedil;&otilde;es relativas &agrave; transa&ccedil;&atilde;o s&atilde;o incompletas, inver&iacute;dicas ou imprecisas; (c) se o Produtor n&atilde;o prestar informa&ccedil;&otilde;es adequadas
            quando solicitado pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">; (d) se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;constatar que a transa&ccedil;&atilde;o exp&otilde;e a risco excessivo os Usu&aacute;rios ou a pr&oacute;pria </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">, em raz&atilde;o das caracter&iacute;sticas da transa&ccedil;&atilde;o, do Produto, dos volumes ou dos prazos de entrega; (e) se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;observar que a ado&ccedil;&atilde;o de quaisquer dessas medidas &eacute; necess&aacute;ria para proteger a seguran&ccedil;a da pr&oacute;pria </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;ou de seus empregados, colaboradores, representantes, agentes, s&oacute;cios ou parceiros; (f) se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;for inclu&iacute;da em qualquer processo judicial ou administrativo a respeito de uma transa&ccedil;&atilde;o de pagamento e o Usu&aacute;rio n&atilde;o tomar as medidas necess&aacute;rias para excluir a
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;do respectivo processo, isentando-a de qualquer responsabilidade, conforme descrito nos&nbsp;Termos da </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;e nesta Pol&iacute;tica; (g) se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;tomar conhecimento de que o Usu&aacute;rio deixou de atender a ordem de &oacute;rg&atilde;os p&uacute;blicos; (h) em cumprimento a qualquer exig&ecirc;ncia legal, decis&atilde;o judicial ou determina&ccedil;&atilde;o de
            autoridade p&uacute;blica competente dirigida &agrave;
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">; (i) se a </span><span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">&nbsp;verificar a exist&ecirc;ncia de valores vencidos e n&atilde;o pagos pelo Usu&aacute;rio.</span>
    </p>
    <p class="c6">
        <span class="c1">12.4. Bloqueios de saques ou de contas de pagamento na PLATAFORMA ECOMPAY:</span>
        <span class="c5">
            &nbsp;Em qualquer das hip&oacute;teses previstas no par&aacute;grafo anterior, se voc&ecirc; n&atilde;o sanar as informa&ccedil;&otilde;es relativas &agrave;s transa&ccedil;&otilde;es, n&atilde;o prestar esclarecimentos
            satisfat&oacute;rios ou n&atilde;o oferecer as garantias necess&aacute;rias exigidas pela
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            , esta poder&aacute; recusar em definitivo a aprova&ccedil;&atilde;o da transa&ccedil;&atilde;o de pagamento, recusar um pedido espec&iacute;fico de saque, bloquear a realiza&ccedil;&atilde;o de qualquer saque ou at&eacute;
            mesmo bloquear a sua conta de pagamento na Plataforma. A
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span>
        <span class="c5">
            &nbsp;empregar&aacute; os seus melhores esfor&ccedil;os para cancelar os correspondentes pagamentos ou movimenta&ccedil;&otilde;es, bem como para estornar os respectivos valores aos Compradores, sem preju&iacute;zo da
            extin&ccedil;&atilde;o dos Servi&ccedil;os da
        </span>
        <span class="c1">PLATAFORMA ECOMPAY</span><span class="c5">&nbsp;e da aplica&ccedil;&atilde;o de outras medidas cab&iacute;veis conforme os Termos da </span><span class="c1">PLATAFORMA ECOMPAY.</span><span class="c5">&nbsp;</span>
    </p>
    <p class="c8 c35"><span class="c5"></span></p>
</body>
<html/>`

export default policies;