import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthService from "../services/Authentication.service";
import TeamMemberService from "../services/TeamMember.service";

const PrivateRoute = (props = {}) => {
    const authenticated = new AuthService().isAuthenticated()
    let hasRole = true

    if(props.role){
        hasRole = new TeamMemberService().evaluateRole(props.role)
    }
    
    if(!authenticated)
        sessionStorage.setItem('next_url', window.location.href)
    return <Route
                path={props.path}
                exact={props.exact}
                component={() => authenticated ?  (hasRole ? props.children : <Redirect to={{pathname: '/404'}} />) : <Redirect to={{pathname: '/login'}} />}
            />
}

export default PrivateRoute;