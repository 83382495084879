import React, { useState } from 'react';
import { Row, Avatar, Dropdown, Icon } from 'rsuite';

import parseDateTime from '../../../utils/parseDateTime';

const Comment = ({ comment, index, setReplyComment }) => {

    const [hoveredComment, setHoveredComment] = useState(null);
    const [activeCommentIndex, setActiveCommentIndex] = useState(null);
    //const [replyComment, setReplyComment] = useState(null)
    const [expandedCommentIndex, setExpandedCommentIndex] = useState(false);
    const [showAllReplies, setShowAllReplies] = useState(false);

    const getUserColor = (userId) => {
        const hash = Array.from(userId).reduce((acc, char) => {
            const code = char.charCodeAt(0);
            return (acc << 5) - acc + code;
        }, 0);
        
        // Converta o hash para um valor hexadecimal e limita a 6 dígitos
        const color = `#${((hash >> 24) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 16) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 8) & 0xFF).toString(16).padStart(2, '0')}`;
        
        // Retorna a cor gerada
        return color;
    };

    const getReplyComment = (comment, userColor) => {
        setReplyComment({comment, userColor})
        setActiveCommentIndex(null);
        setHoveredComment(null)
    }

    const toggleReplies = (index) =>{
        if(expandedCommentIndex === index){
            setExpandedCommentIndex(null); 
        }else{
            setExpandedCommentIndex(index);
        }
    }

    const toggleRepliesRemaining = (index) =>{
        if(showAllReplies === index){
            setShowAllReplies(null); 
        }else{
            setShowAllReplies(index);
        }
    }

    const handleMenuToggle = (index) => {
        setActiveCommentIndex(activeCommentIndex === index ? null : index);
    };

    const userColor = getUserColor(comment?.owner?._id);

    const isHovered = hoveredComment === index;

    return <>
        <Row 
            key={index} 
            style={{display: "flex", alignItems: "flex-start", marginBottom: "10px", maxWidth: "460px", }}
        >
            <Row>
                <Avatar circle size="md" style={{ margin: "10px", background: userColor, cursor: 'pointer' }}>
                    {comment?.owner?.name?.split(' ')[0]?.charAt(0)?.toUpperCase()}
                    {comment?.owner?.name?.split(' ')[1]?.charAt(0)?.toUpperCase()}
                </Avatar>
            </Row>
            <Row
                onMouseEnter={() => setHoveredComment(index)}
                onMouseLeave={() => setHoveredComment(null)}
                style={{ cursor: 'pointer' }}
            >
                <div style={{ 
                    position: "relative", 
                    left: "10px", 
                    width: "0", 
                    top: "10px", 
                    borderLeft: "10px solid transparent", 
                    borderRight: "10px solid transparent", 
                    borderTop: "10px solid #eaeaea",
                    zIndex: 2
                }}></div>
                <Row style={{ 
                    background: "#eaeaea", 
                    borderRadius: "10px", 
                    padding: "5px 15px 0 15px", 
                    position: "relative",
                    marginLeft: "20px",
                    marginTop: "0",
                    maxWidth: "400px",
                    zIndex: 1
                }}>
                    <Row style={{ fontWeight: "bold", color: userColor }}>
                        {comment?.owner?.name}
                    </Row>
                    <Row style={{ fontWeight: "500", color: "#34495e" }}>
                        <p>Aula: {comment?.module_children?.lesson?.name}</p>
                    </Row>
                    <Row style={{marginTop: "5px"}}>
                        {comment?.text}
                    </Row>
                    <Row style={{display: 'flex', justifyContent: 'flex-end', marginTop: "5px"}}>
                        {parseDateTime(comment?.createdAt)}
                    </Row>
                    {
                        isHovered || activeCommentIndex === index ? <>
                            <Row 
                                style={{ 
                                    position: 'absolute', 
                                    right: '0px', 
                                    top: '0px',
                                    marginRight: "5px", 
                                    cursor: 'pointer', 
                                }}
                                >
                                <Dropdown
                                    open={activeCommentIndex === index}
                                    onOpen={() => handleMenuToggle(index)}
                                    onClose={() => handleMenuToggle(index)}
                                    trigger="click"
                                    renderTitle={() => <Icon size="2x" icon="arrow-down-line" />}
                                    >
                                    <Dropdown.Item onClick={() => getReplyComment(comment, userColor) }>
                                        Responder
                                    </Dropdown.Item>
                                </Dropdown>
                            </Row>
                        </> : null
                    }
                </Row>
            </Row>
        </Row> 
        {    
            comment?.replies.length > 0 ? <>
                <Row 
                    style={{
                        marginBottom: '10px',
                        marginLeft: "60px", 
                        display: 'flex', 
                        justifyContent: 'flex-start', 
                        maxWidth: "360px", 
                        cursor: 'pointer',
                        alignItems: 'center'}}
                    onMouseEnter={(e) => { e.currentTarget.style.color = '#1a0dab' }}
                    onMouseLeave={(e) => { e.currentTarget.style.color = '#000' }}   
                    onClick={() =>{toggleReplies(index)}} 
                >
                    {
                        expandedCommentIndex === index ? <Icon icon="arrow-up-line" style={{marginRight: "5px"}}/> : <Icon icon="arrow-down-line" style={{marginRight: "5px"}}/>
                    }
                    {`${comment?.replies.length} respostas`}
                </Row>
                {
                    expandedCommentIndex === index ? <>
                        <Row 
                            style={{background: "#eaeaea",
                                    maxWidth: "300px",
                                    marginLeft: "60px",
                                    padding: "10px",
                                    borderRadius: "10px",
                                }}
                        >
                            {
                                comment?.replies?.slice(0, 9).map((replie, index) => {
                                    const userColor = getUserColor(replie?.owner?._id);
                                    
                                    return <>
                                        <Row 
                                            key={index} 
                                            style={{display: "flex", 
                                                    justifyContent: 'flex-start', 
                                                    marginBottom: "10px", 
                                                }}
                                        >
                                            <Row>
                                                <Avatar circle size="sm" style={{ margin: "10px", background: userColor, cursor: 'pointer' }}>
                                                    {replie?.owner?.name?.split(' ')[0]?.charAt(0)?.toUpperCase()}
                                                    {replie?.owner?.name?.split(' ')[1]?.charAt(0)?.toUpperCase()}
                                                </Avatar>
                                            </Row>
                                            <Row style={{  
                                                padding: "5px 15px 0 15px", 
                                                marginLeft: "0px",
                                                marginTop: "0",
                                                maxWidth: "300px"}}
                                            >
                                                <Row>
                                                    <span style={{ fontWeight: "bold", color: userColor }}>
                                                        {replie?.owner?.name}
                                                    </span>
                                                    <span style={{ marginLeft: "5px"}}>
                                                        {parseDateTime(replie?.createdAt)}
                                                    </span>
                                                </Row>
                                                <Row style={{marginTop: "5px"}}>
                                                    {replie?.text}
                                                </Row>
                                            </Row>
                                        </Row>
                                    </>
                                })
                            }
                            {
                                comment?.replies?.length > 10 && showAllReplies !== index ? <>
                                    <Row 
                                        style={{
                                            marginTop: '10px',
                                            marginLeft: '5px', 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            cursor: 'pointer',
                                        }}
                                        onMouseEnter={(e) => { e.currentTarget.style.color = '#1a0dab' }}
                                        onMouseLeave={(e) => { e.currentTarget.style.color = '#000' }} 
                                        onClick={() => toggleRepliesRemaining(index)}  
                                    >
                                        <Icon icon="arrow-down-line" style={{marginRight: "5px"}}/>
                                        Ver mais respostas
                                    </Row>
                                </>: null
                            }
                            {
                                showAllReplies === index && comment?.replies?.slice(9).map((replie, index) => {
                                    const userColor = getUserColor(replie?.owner?._id);

                                    return <>
                                        <Row 
                                            key={index} 
                                            style={{
                                                display: "flex", 
                                                justifyContent: 'flex-start', 
                                                marginBottom: "10px"
                                            }}
                                        >
                                            <Row>
                                                <Avatar circle size="sm" style={{ margin: "10px", background: userColor, cursor: 'pointer' }}>
                                                    {replie?.owner?.name?.split(' ')[0]?.charAt(0)?.toUpperCase()}
                                                    {replie?.owner?.name?.split(' ')[1]?.charAt(0)?.toUpperCase()}
                                                </Avatar>
                                            </Row>
                                            <Row style={{  
                                                padding: "5px 15px 0 15px", 
                                                marginLeft: "0px",
                                                marginTop: "0",
                                                maxWidth: "300px"
                                            }}>
                                                <Row>
                                                    <span style={{ fontWeight: "bold", color: userColor }}>
                                                        {replie?.owner?.name}
                                                    </span>
                                                    <span style={{ marginLeft: "5px"}}>
                                                        {parseDateTime(replie?.createdAt)}
                                                    </span>
                                                </Row>
                                                <Row style={{marginTop: "5px"}}>
                                                    {replie?.text}
                                                </Row>
                                            </Row>
                                        </Row>
                                    </>
                                })
                            }
                        </Row>
                    </>: null
                }
            </> : null                                                   
        }
    </>
}
export default Comment